import React from "react";
import { Button, Row, Col, Glyphicon, FormControl} from 'react-bootstrap';
import CommonTable from "../../../containers/Elements/Table/CommonTable";
import AscComponent from "../../../components/AscComponent";
import PredictiveCallInsertUpdateModal from './PredictiveCallInsertUpdateModal';
import PredictiveCallReadDeleteModal from './PredictiveCallReadDeleteModal';
import SetBreadCrumb from "../../Elements/AscElements/SetBreadCrumb";
import 'react-datepicker/dist/react-datepicker.css';
import * as GlobalConst from '../../../components/AscConstants';
import PredictvieCallStatisticsModal from "./PredictvieCallStatisticsModal";
import SetButtonWithSpeechBubble from "../../Elements/AscElements/SetButtonWithSpeechBubble";


export default class PredictiveCall extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            pageSize: null,
            show: false,
            loading: false,
            accordionFlag: false,
            validationMessage: null,
            containerPath: this.reactContainerPath.split("/")[1],
            extensionNumbers: [],
            inboundGroups: [],

            // select item
            autoStopHourSelect: this.props.langText.SelectOption.hourShowList,
            autoStopMinSelect: this.props.langText.SelectOption.minuteShowList,
            outboundRate: "1.0",
            connectedSoundSelect: [],
            connectedTimeoutSoundSelect: [],

            // data item
            id: "",
            task_name: "",
            auto_stop_time: "20:00:00",
            list_id: "",
            companyId: "",
            departmentId: "",
            floorId: "",
            connected_sound_id: "",
            connected_sound_url: "",
            connected_timeout_sound_id: "",
            connected_timeout_sound_url: "",
            mailAddress: "",
            errorMailAddress: "",
            externalMaxOutbound: "",
            externalMaxOutboundValue: "",
            report_events: [
                {
                    type: "start",
                    value: "N"
                },
                {
                    type: "stop",
                    value: "N"
                },
                {
                    type: "recall",
                    value: "N"
                },
                {
                    type: "every_hour",
                    value: "N"
                }
            ],
            error_events: [
                {
                    type: "error",
                    value: "N"
                },
                {
                    type: "connect_per",
                    value: 0
                },
                {
                    type: "disconnect_per",
                    value: 0
                }
            ],

            // button_disabled
            buttondisabledArr: {
                "department_id" : [],
                "floor_id" : [],
                "list_id" : [],
                "sound_id" : [],
                "sound_load" : [],
            },
        };

        this.table = React.createRef();
    }

    getColumnsData() {
        const columnsDataList = [
            {
                Header: this.props.langText.Body.Id,
                accessor: "id",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.id,
            },
            {
                Header: this.props.langText.Body.AutoCallTaskName,
                accessor: "task_name",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.smedium
            },
            {
                Header: this.props.langText.Body.AutoCallStatus,
                accessor: "status",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.small,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "status")} />,
                Cell: data => {
                    let status = this.getStatus(data.value);

                    return status;
                }
            },
            {
                Header: this.props.langText.Body.AutoCallCallStartTime,
                accessor: "execution_time",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Date
            },
            {
                Header: this.props.langText.Body.AutoCallAutoStopTime,
                accessor: "auto_stop_time",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.small,
                Cell: data => {
                    let auto_stop = data.value.substring(0, 5);

                    return auto_stop;
                }
            },
            {
                Header: this.props.langText.Body.List,
                accessor: "cm76_autocall_list.list_name",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.small
            },
            {
                Header: this.props.langText.Body.AutoCalledCount,
                accessor: "list_expense",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.medium
            },
            {
                Header: this.props.langText.Body.ExternalNumber,
                accessor: "cm61_external_numbers.memo",
                sortable: false,
                filterable: false,
                width: this.props.boardWidth.CallNumber
            },
            {
                Header: this.props.langText.Body.InboundGroupInfo,
                accessor: "inbound_group_infos",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.medium,
                Cell: data => {
                    let elemArr = [];
                    data.row._original.inbound_group_infos.forEach((row, key) => {
                        elemArr.push(
                            <p key={key}>
                                {row.memo}
                            </p>
                        );
                    });
                    return (elemArr);
                }
            },
            // {
            //     Header: this.props.langText.Body.AutoCallWaitExtensionNumber,
            //     accessor: "memory_avg",
            //     filterable: false,
            //     sortable: false,
            //     width: this.props.boardWidth.xsmall
            // },
            // {
            //     Header: this.props.langText.Body.AutoCallExtensionNumber,
            //     accessor: "memory_avg",
            //     filterable: false,
            //     sortable: false,
            //     width: this.props.boardWidth.xsmall
            // },
            {
                Header: this.props.langText.Body.AutoCallConnectCount,
                accessor: "recent_connect_count",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.small
            },
            {
                Header: this.props.langText.Body.AutoCallGiveUp,
                accessor: "recent_disconnect_count",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.small
            },
            {
                Header: this.props.langText.Body.Modified,
                accessor: "modified",
                width: this.props.boardWidth.Date,
                Cell: ({
                    value
                }) => value ? this.getMomentTime({
                    format: this.props.langText.Body.DataHourFormat,
                    date: value
                }) : "",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "modified")} />,
                filterable: false,
            },
            {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.xlarge,
                Cell: data => {
                    let rowData = [];
                    let id = data.row._original.id;
                    let status = data.row._original.status
                    let cm61_info = data.row._original.cm61_external_numbers;
                    let cm76_info = data.row._original.cm76_autocall_list;
                    let inbound_group_infos = data.row._original.inbound_group_infos;
                    let call_count = data.row._original && data.row._original.ct77_predictive_call_history 
                                    ? parseInt(data.row._original.ct77_predictive_call_history.call_count)
                                    : null;
                    let list_count = cm76_info && data.row._original.cm76_autocall_list.list_count;
                    // TODO: ステータスによってステータスアイコンを変更
                    if (status === "call") {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={0}
                                bsSize="xsmall"
                                onClick={() => {
                                    this.stopPredictiveCall({id, status});
                                }}
                                className="control-button"
                                DisplayIcon="phone-alt"
                                disabled={data.row._original.disabled === "Y" ? true : false}
                                speechBubble={this.props.langText.Body.CallStop}
                            />
                        );
                    } else {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={1}
                                bsSize="xsmall"
                                onClick={() => {
                                    this.startPredictiveCall({id, status, cm61_info, cm76_info, inbound_group_infos});
                                }}
                                className="control-button"
                                DisplayIcon="earphone"
                                disabled={
                                    (data.row._original.disabled === "Y" ? true : false) || 
                                    (call_count === list_count)
                                }
                                speechBubble={this.props.langText.Body.Call}
                            />
                        );
                    }
                    rowData.push(
                        <SetButtonWithSpeechBubble
                            key={2}
                            bsSize="xsmall"
                            onClick={() => {
                                this.props.historyPush({
                                    pathname: "PredictiveCall/Histories",
                                    state: { displayData: data.row._original}
                                });
                            }}
                            className="control-button"
                            DisplayIcon="list"
                            speechBubble={this.props.langText.Body.PredictiveCallHistory}
                        />
                    );
                    rowData.push(
                        <SetButtonWithSpeechBubble
                            key={3}
                            bsSize="xsmall"
                            onClick={this.modalShow("statistics", data.row._original)}
                            className="control-button"
                            DisplayIcon="stats"
                            speechBubble={this.props.langText.Body.StatisticsTitle}
                        />
                    );
                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={4}
                                bsSize="xsmall"
                                onClick={this.modalShow("update", data.row._original)}
                                className="control-button"
                                DisplayIcon="pencil"
                                speechBubble={this.props.langText.Body.Edit}
                            />
                        );
                    } else {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={5}
                                bsSize="xsmall"
                                onClick={this.modalShow("read", data.row._original)}
                                className="control-button"
                                DisplayIcon="eye-open"
                                speechBubble={this.props.langText.Body.Read}
                            />
                        );
                    }
                    if (this.props.currentPermission.create) {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={6}
                                bsSize="xsmall"
                                onClick={this.modalShow("copy", data.row._original)}
                                className="control-button"
                                DisplayIcon="copy"
                                speechBubble={this.props.langText.Body.Copy}
                            />
                        );
                    }
                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={7}
                                bsSize="xsmall"
                                onClick={this.modalShow("delete", data.row._original)}
                                className="control-button"
                                DisplayIcon="minus"
                                speechBubble={this.props.langText.Body.Delete}
                            />
                        );
                    }
                    return (
                        <Row className='text-center'>
                            {rowData}
                        </Row>
                    )
                }
            }
        ];

        return columnsDataList;
    }

    getStatus = (status = null) => {
        let {
            PredictiveStatusCall,
            PredictiveStatusStop,
            PredictiveStatusUncall,
        } = this.props.langText.Body;
        
        let statusListObj = {
            call: {label: PredictiveStatusCall, class: "status-calling"},
            stop: {label: PredictiveStatusStop, class: "status-end"},
            uncall: {label: PredictiveStatusUncall, class: "status-null"},
        };

        let statusData = status ? statusListObj[status] : statusListObj.uncall;

        return [
            <div className={statusData.class + " status-common"} key = {0}>
                {statusData.label}
            </div>
        ];
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault()
        this.blockUI();
        this.setState({
            buttondisabledArr: {
            "department_id" : [],
            "floor_id" : [],
            "list_id" : [],
            "sound_id" : [],
            "sound_load" : []
            },
            departmentSelect: [],
            floorSelect: [],
            externalNumbers: [],
            inboundGroups:[],
            accordionFlag: false
        });

        let setData = {
            id: param && param.id
                ? param.id
                : '',
            status: param && param.status
                ? param.status
                : '',
            task_name: param && param.task_name && modalType !== "copy"
                ? param.task_name
                : '',
            auto_stop_time: param && param.auto_stop_time
                ? param.auto_stop_time
                : "20:00:00",
            list_id: param && param.cm76_id && param.cm76_autocall_list
                ? param.cm76_id
                : '',
            list_name: param && param.cm76_autocall_list && param.cm76_autocall_list.list_name
                ? param.cm76_autocall_list.list_name
                : '',
            companyId: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : '',
            companyName: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : '',
            departmentId: param && param.cm13_departments 
                ? param.cm13_departments.map(row => {
                    return row.id;
                })
                : '',
            departmentName: param && param.cm13_departments 
                ? param.cm13_departments.map(row => {
                    return row.department_name;
                })
                : '',
            departmentPrefix: param && param.cm13_departments 
                ? param.cm13_departments.map(row => {
                    return row.department_prefix;
                })
                : '',
            floor_flg: param && param.cm12_companies && param.cm12_companies.floor_flg
                ? param.cm12_companies.floor_flg === "Y"
                : null,
            floorId: param && param.cm13_departments && param.cm12_companies.floor_flg === "Y"
                ? param.cm13_departments.map(row => {
                    return row.id;
                })
                : '',
            floorName: param && param.cm13_departments && param.cm12_companies.floor_flg === "Y"
                ? param.cm13_departments.map(row => {
                    return row.floor_name;
                })
                : '',
            externalNumberSelected: param && param.cm61_id && param.cm61_external_numbers
                ? param.cm61_id
                : '',
            externalNumberName: param && param.cm61_external_numbers && param.cm61_external_numbers.memo
                ? param.cm61_external_numbers.memo
                : '',
            non_notification_flag: param && param.cm61_options && param.cm61_options.withheld
                ? param.cm61_options.withheld
                : false,
            externalMaxOutbound: param && param.channel_limit
                ? param.channel_limit
                : '',
            inboundGroupsSelected: param && param.inbound_group_infos
                ? param.inbound_group_infos.map(row => {
                    return row.id;
                })
                : '',
            inboundGroupsName: param && param.inbound_group_infos
                ? param.inbound_group_infos.map(row => {
                    return row.memo;
                })
                : '',
            outboundRate: param && param.call_leverage
                ? param.call_leverage
                : "1.0",
            timeout: param && param.ringing_timeout_sec
                ? param.ringing_timeout_sec
                : 30,
            retry: param && param.recall_count
                ? param.recall_count
                : 0,
            retryInterval: param && param.recall_interval_hour
                ? param.recall_interval_hour
                : 0,
            connected_sound_id: param && param.connected_cm35_id
                ? param.connected_cm35_id
                : '',
            connected_timeout: param && param.connected_timeout_sec
                ? param.connected_timeout_sec
                : 30,
            connected_timeout_sound_id: param && param.connected_timeout_cm35_id
                ? param.connected_timeout_cm35_id
                : '',  
            mailAddress: param && param.report_mail_json && param.report_mail_json.to
                ? param.report_mail_json.to.join(",")
                : null,
            mailCc: param && param.report_mail_json && param.report_mail_json.cc
                ? param.report_mail_json.cc.join(",")
                : null,
            mailBcc: param && param.report_mail_json && param.report_mail_json.bcc
                ? param.report_mail_json.bcc.join(",")
                : null,
            report_events: param && param.report_mail_json && param.report_mail_json.events
                ? param.report_mail_json.events
                : this.state.report_events,
            start_flag: param && param.report_mail_json && param.report_mail_json.events
                ? param.report_mail_json.events.find(row => row.type === "start").value === "Y" ? true : false
                : false,
            stop_flag: param && param.report_mail_json && param.report_mail_json.events
                ? param.report_mail_json.events.find(row => row.type === "stop").value === "Y" ? true : false
                : false,
            loop_flag: param && param.report_mail_json && param.report_mail_json.events
                ? param.report_mail_json.events.find(row => row.type === "recall").value === "Y" ? true : false
                : false,
            hour_summary_flag: param && param.report_mail_json && param.report_mail_json.events
                ? param.report_mail_json.events.find(row => row.type === "every_hour").value === "Y" ? true : false
                : false,
            errorMailAddress: param && param.error_mail_json && param.error_mail_json.to
                ? param.error_mail_json.to.join(",")
                : null,
            errorMailCc: param && param.error_mail_json && param.error_mail_json.cc
                ? param.error_mail_json.cc.join(",")
                : null,
            errorMailBcc: param && param.error_mail_json && param.error_mail_json.bcc
                ? param.error_mail_json.bcc.join(",")
                : null, 
            error_events: param && param.error_mail_json && param.error_mail_json.events
                ? param.error_mail_json.events
                : this.state.error_events,
            error_stop_flag: param && param.error_mail_json && param.error_mail_json.events
                ? param.error_mail_json.events.find(row => row.type === "error").value === "Y" ? true : false
                : false,
            connect_error_flag: param && param.error_mail_json && param.error_mail_json.events
                ? param.error_mail_json.events.find(row => row.type === "connect_per").value ? true : false
                : false,
            connect_error: param && param.error_mail_json && param.error_mail_json.events
                ? param.error_mail_json.events.find(row => row.type === "connect_per").value
                : null,
            giveup_error_flag: param && param.error_mail_json && param.error_mail_json.events
                ? param.error_mail_json.events.find(row => row.type === "disconnect_per").value ? true : false
                : false,
            giveup_error: param && param.error_mail_json && param.error_mail_json.events
                ? param.error_mail_json.events.find(row => row.type === "disconnect_per").value
                : null,
            recent_call_count: param && param.recent_call_count
                ? param.recent_call_count
                : 0,
            recent_connect_count: param && param.recent_connect_count
                ? param.recent_connect_count
                : 0,
            recent_disconnect_count: param && param.recent_disconnect_count
                ? param.recent_disconnect_count
                : 0,
            call_count: param && param.ct77_predictive_call_history && param.ct77_predictive_call_history.call_count
                ? param.ct77_predictive_call_history.call_count
                : "",
            connect_count: param && param.ct77_predictive_call_history && param.ct77_predictive_call_history.connect_count
                ? param.ct77_predictive_call_history.connect_count
                : "",
            disconnect_count: param && param.ct77_predictive_call_history && param.ct77_predictive_call_history.disconnect_count
                ? param.ct77_predictive_call_history.disconnect_count
                : "",
            call_time: param && param.ct77_predictive_call_history && param.ct77_predictive_call_history.total_time
                ? this.secToTime(param.ct77_predictive_call_history.total_time)
                : "00:00:00",
            avg_call_time: param && param.ct77_predictive_call_history && param.ct77_predictive_call_history.avg_call_time
                ? this.secToTime(param.ct77_predictive_call_history.avg_call_time)
                : "00:00:00",
            avg_connect_time: param && param.ct77_predictive_call_history && param.ct77_predictive_call_history.avg_connect_time
                ? this.secToTime(param.ct77_predictive_call_history.avg_connect_time)
                : "00:00:00",
            avg_disconnect_time: param && param.ct77_predictive_call_history && param.ct77_predictive_call_history.avg_disconnect_time
                ? this.secToTime(param.ct77_predictive_call_history.avg_disconnect_time)
                : "00:00:00",
            avg_hook_time: param && param.ct77_predictive_call_history && param.ct77_predictive_call_history.avg_hook_time
                ? this.secToTime(param.ct77_predictive_call_history.avg_hook_time)
                : "00:00:00",
            connect_rate: param && param.connect_rate
                ? param.connect_rate
                : "",
            disconnect_rate: param && param.disconnect_rate
                ? param.disconnect_rate
                : "",
            connected_sound_url: await this.getSoundURL('PREDICTIVE_WAIT_SOUND'),
            connected_timeout_sound_url: await this.getSoundURL('PREDICTIVE_TIMEOUT_SOUND'),
            modalType,
            show: true
        }
        
        // 自動停止時間hour, min分け
        if (setData.auto_stop_time) {
            let auto_stop_time_arr = setData.auto_stop_time.split(":");
            setData.auto_stop_hour = auto_stop_time_arr[0];
            setData.auto_stop_min = auto_stop_time_arr[1];
        }

        // フロア有りの場合、拠点を変更
        if (setData.floor_flg) {
            setData.departmentId = param && param.parent_departments 
                ? param.parent_departments.map(row => {
                    return row.id;
                })
                : '';
            setData.departmentName = param && param.parent_departments 
                ? param.parent_departments.map(row => {
                    return row.department_name;
                })
                : ''; 
            setData.departmentPrefix = param && param.parent_departments 
                ? param.parent_departments.map(row => {
                    return row.department_prefix;
                })
                : ''; 
        }

        // 新規作成時にcompany以下なら会社固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            this.state.buttondisabledArr.department_id.push("dataset");
            this.state.buttondisabledArr.sound_id.push("dataset");
            setData.companyId = this.props.userInfo.company_id;
            setData.companyName = this.props.userInfo.company_name;
            setData.floor_flg = this.props.userInfo.floor_flg;
        }
        // 新規作成時にdepartment以下なら拠点固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)) {
            if (this.props.userInfo.floor_flg) {
                this.state.buttondisabledArr.floor_id.push("dataset");
                setData.departmentId = [this.props.userInfo.parent_department_id];
                setData.departmentName = this.props.userInfo.parent_department_name;
                setData.departmentPrefix = this.props.userInfo.department_prefix;
            } else {
                setData.departmentId = [this.props.userInfo.department_id];
                setData.departmentName = this.props.userInfo.department_name;
            }
        }
        // 新規作成時にfloor以下ならフロア固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
            setData.floorId = [this.props.userInfo.department_id];
            setData.floorName = this.props.userInfo.floor_name;
        }

        let department_id = null;
        
        try {
            await this.getCompanySelect();

            if (setData && setData.companyId) {
                this.state.buttondisabledArr.department_id.push("dataset");
                this.state.buttondisabledArr.list_id.push("dataset");
                this.state.buttondisabledArr.sound_id.push("dataset");
                await this.getDepartmentSelect(setData);
                await this.getSoundSelect({company_id: setData.companyId});
                await this.getListSelect(setData);
                department_id = await this.getDepartmentId(setData);
            }
            
            if (setData.departmentId) {
                department_id = setData.departmentId;
                await this.getInboundGroup(department_id);
                await this.getExternalNumber(department_id);

                if (setData.floor_flg) await this.getFloorSelect(setData);
            } else if (department_id) {
                if (!Array.isArray(department_id)) department_id = [department_id];
                if (setData && setData.floorId) this.state.buttondisabledArr.floor_id.push("dataset");
                
                await this.getInboundGroup(department_id);
                await this.getExternalNumber(department_id);
            }

            // 最大同時発信数確認
            if (setData.externalNumberSelected) {
                let line_type = this.state.externalNumbers.find(row => {
                    return row.value === setData.externalNumberSelected;
                });

                await this.getLineChannel({
                    company_id: setData.companyId,
                    line_type: line_type.line_type
                });
            } else {
                setData.externalMaxOutbound = "";
            }
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }

        this.setState(setData);
    }

    startPredictiveCall = async ({id, status, cm61_info, cm76_info, inbound_group_infos}) => {
        try {
            if (!cm61_info || !cm76_info || inbound_group_infos.length === 0) {
                throw "FindDataError";
            }
            if (window.confirm(this.props.langText.Message.PredictiveCall_Start)) {
                const start = await this.ascAxios("post", `${this.reactContainerPath}/startCall`, {
                    id,
                    status
                });
                this.reactTableRefresh();
                this.setState({ show: false });
            }
        } catch (err) {
            console.log(err);
            this.showErrorObjectMesssage(err);
        }
    }

    stopPredictiveCall = async ({id, status}) => {
        try {
            if (window.confirm(this.props.langText.Message.PredictiveCall_Stop)) {
                const stop = await this.ascAxios("post", `${this.reactContainerPath}/stopCall`, {
                    id,
                    status
                });
                this.reactTableRefresh();
                this.setState({ show: false });
            }
        } catch (err) {
            console.log(err);
            this.showErrorObjectMesssage(err);
        }
    }

    onClickHandle = modalType => async event => {
        let {
            id,
            task_name,
            auto_stop_time,
            auto_stop_hour,
            auto_stop_min,
            list_id,
            companyId,
            departmentId,
            floorId,
            externalNumberSelected,
            non_notification_flag,
            externalMaxOutbound,
            externalMaxOutboundValue,
            inboundGroupsSelected,
            outboundRate,
            timeout,
            retry,
            retryInterval,
            connected_sound_id,
            connected_timeout,
            connected_timeout_sound_id,
            mailAddress,
            mailCc,
            mailBcc,
            report_events,
            errorMailAddress,
            errorMailCc,
            errorMailBcc,
            error_events,
        } = this.state;

        // json作成
        const report_mail_json = {
            to: this.getEmailsByString(mailAddress),
            cc: this.getEmailsByString(mailCc),
            bcc: this.getEmailsByString(mailBcc),
            events: this.getEventsByState(report_events)
        };
        const error_mail_json = {
            to: this.getEmailsByString(errorMailAddress),
            cc: this.getEmailsByString(errorMailCc),
            bcc: this.getEmailsByString(errorMailBcc),
            events: this.getEventsByState(error_events)
        };
        non_notification_flag = {
            withheld: non_notification_flag
        };

        // 自動停止時間調整
        auto_stop_time = `${auto_stop_hour}:${auto_stop_min}:00`;
        
        // 最大同時発信数
        externalMaxOutbound = externalMaxOutbound ? externalMaxOutbound : externalMaxOutboundValue;

        // 拠点とフロア
        departmentId = departmentId && departmentId.length > 0 ? departmentId : [];
        floorId = floorId && floorId.length > 0 ? floorId : [];
        let department_id = departmentId.concat(floorId);

        try {
            switch (modalType) {
                case "insert":
                case "copy":
                    this.blockUI();
                    const insert_result = await this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                        company_id: companyId,
                        task_name: task_name,
                        auto_stop_time: auto_stop_time,
                        list_id: list_id,
                        external_id: externalNumberSelected,
                        external_options: non_notification_flag,
                        departments: department_id,
                        inbound_groups: inboundGroupsSelected,
                        call_leverage: outboundRate,
                        ringing_timeout_sec: timeout,
                        recall_count: retry,
                        recall_interval_hour: retryInterval,
                        connected_voice_id: connected_sound_id,
                        connected_timeout_sec: connected_timeout,
                        connected_timeout_voice_id: connected_timeout_sound_id,
                        report_mail_json: report_mail_json,
                        error_mail_json: error_mail_json,
                        channel_limit: externalMaxOutbound,
                    });
                    
                    if (insert_result) {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    }
                    break;
                case "update":
                    this.blockUI();
                    const update_result = await this.ascAxios("post", `${this.reactContainerPath}/update`, {
                        id: id,
                        company_id: companyId,
                        task_name: task_name,
                        auto_stop_time: auto_stop_time,
                        list_id: list_id,
                        external_id: externalNumberSelected,
                        external_options: non_notification_flag,
                        departments: department_id,
                        inbound_groups: inboundGroupsSelected,
                        call_leverage: outboundRate,
                        ringing_timeout_sec: timeout,
                        recall_count: retry,
                        recall_interval_hour: retryInterval,
                        connected_voice_id: connected_sound_id,
                        connected_timeout_sec: connected_timeout,
                        connected_timeout_voice_id: connected_timeout_sound_id,
                        report_mail_json: report_mail_json,
                        error_mail_json: error_mail_json,
                        channel_limit: externalMaxOutbound,
                    });
                    
                    if (update_result) {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    }
                    break;
                case "delete":
                    this.blockUI();
                    const delete_result = await this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                        id: id,
                        company_id: companyId
                    });

                    if (delete_result) {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    }
                    break;
                default:
                    alert(this.getErrorString({ code: "modal" }));
                    break;
            }
            
        } catch (err) {
            console.log(err);
            this.showErrorObjectMesssage(err);
        }
    }

    validationHandle = param => {
        let nowState = null;

        let {
            id,
            status,
            task_name,
            list_id,
            companyId,
            externalNumberSelected,
            externalMaxOutbound,
            externalMaxOutboundValue,
            inboundGroupsSelected,
            outboundRate,
            timeout,
            retry,
            retryInterval,
            connected_timeout,
            mailAddress,
            mailCc,
            mailBcc,
            errorMailAddress,
            errorMailCc,
            errorMailBcc,
        } = this.state;

        switch (param) {
            case "task_name":
            case "list_id":
            case "companyId":
            case "externalNumberSelected":
                nowState = this.state[param]
                ? null
                : "error"
                break;
            case "inboundGroupsSelected":
                nowState = (
                    this.state[param] && this.state[param].length > 0 ? true : false
                );
                break;
            case "externalMaxOutbound":
                nowState = (
                    this.state[param] > 0
                    && this.state[param] <= externalMaxOutboundValue
                );
                break;
            case "outboundRate":
                nowState = (
                    !this.state[param] 
                    || (this.state[param] 
                        && Number(this.state[param]) <= 5
                        && Number(this.state[param]) >= 0.1)
                );  
                break;
            case "timeout":
                nowState = (
                    !this.state[param]
                    || (this.state[param]
                        && this.state[param] <= 60
                        && this.state[param] >= 1)
                );
                break;
            case "retry":
                nowState = (
                    !this.state[param]
                    || (this.state[param]
                        && this.state[param] <= 10
                        && this.state[param] >= 0)
                );
                break;
            case "retryInterval":
                nowState = (
                    !this.state[param]
                    || (this.state[param]
                        && this.state[param] <= 24
                        && this.state[param] >= 0)
                );
                break;
            case "connected_timeout": 
                nowState = (
                    !this.state[param]
                    || (this.state[param]
                        && this.state[param] <= 999
                        && this.state[param] >= 1)
                );
                break;
            case "mailAddress":
            case "mailCc":
            case "mailBcc":
            case "errorMailAddress":
            case "errorMailCc":
            case "errorMailBcc":
                nowState = (
                    !this.state[param]
                    || (this.state[param] && this.validationEmailsByString(this.state[param]))
                );
                break;
            case "insert":
            case "update":
            case "copy":
                nowState = (
                    task_name 
                    && list_id 
                    && companyId 
                    && externalNumberSelected
                    && (inboundGroupsSelected && inboundGroupsSelected.length > 0)
                    && (externalMaxOutbound > 0 && externalMaxOutbound <= externalMaxOutboundValue)
                    && (!outboundRate || (Number(outboundRate) <= 5 && Number(outboundRate) >= 0.1))
                    && (!timeout || (timeout <= 60 && timeout >= 1))
                    && (!retry || (retry <= 10 && retry >= 0))
                    && (!retryInterval || (retryInterval <= 24 && retryInterval >= 0))
                    && (!mailAddress || (mailAddress && this.validationEmailsByString(mailAddress)))
                    && (!mailCc || (mailCc && this.validationEmailsByString(mailCc)))
                    && (!mailBcc || (mailBcc && this.validationEmailsByString(mailBcc)))
                    && (!errorMailAddress || (errorMailAddress && this.validationEmailsByString(errorMailAddress)))
                    && (!errorMailCc || (errorMailCc && this.validationEmailsByString(errorMailCc)))
                    && (!errorMailBcc || (errorMailBcc && this.validationEmailsByString(errorMailBcc)))
                    && (!connected_timeout || (connected_timeout <= 999 && connected_timeout >= 1))
                );
                break;
            case "delete":
                nowState = (
                    id
                    && status !== "call"
                );
                break;
            default:
                nowState = null;
                break;
        }

        return nowState;
    }

    getEventsByState = (events) => {
        let {
            start_flag,
            stop_flag,
            loop_flag,
            hour_summary_flag,
            error_stop_flag,
            connect_error,
            giveup_error
        } = this.state;

        events.forEach(row => {
            switch (row.type) {
                case "start":
                    row.value = start_flag ? "Y" : "N";
                    break;
                case "stop":
                    row.value = stop_flag ? "Y" : "N";
                    break;
                case "recall":
                    row.value = loop_flag ? "Y" : "N";
                    break;
                case "every_hour":
                    row.value = hour_summary_flag ? "Y" : "N";
                    break;
                case "error":
                    row.value = error_stop_flag ? "Y" : "N";
                    break;
                case "connect_per":
                    row.value = connect_error ? connect_error : null;
                    break;
                case "disconnect_per":
                    row.value = giveup_error ? giveup_error : null;
                    break;
                default:
                    break;
            }
        });

        return events;
    }

    validationEmailsByString = (email) => {
        const emailCheck = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
        let arrEmail = [];
        let validation = true;

        if (email) arrEmail = email.split(",");
        else validation = false;

        arrEmail.map(row => {
            if (!emailCheck.test(row)) validation = false;
        });

        // 重複メールがないか検知
        if (validation) validation = new Set(arrEmail).size === arrEmail.length;

        return validation;
    }    

    getEmailsByString = (email) => {
        let arrEmail = [];

        if (email) arrEmail = email.split(",");

        return arrEmail.length ? arrEmail : null; 
    }    

    getSoundURL = async (param) => {
        try {
            const sound_url = await this.ascAxios("post", `${this.state.containerPath}/getSoundURL`, {
                parameter_id: param
            });
            if (sound_url.data && sound_url.data.parameter_value) {
                return sound_url.data.parameter_value;
            } else {
                return null;
            }
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    getListSelect = async ({companyId}) => {
        try {
            const res = await this.ascAxios("post", `${this.state.containerPath}/getListSelect`, {
                cm12_id: companyId
            });

            this.setState({
                listSelect: res.data
            });
        } catch (err) {
            throw err;
        }
    }

    /**
     * モーダル表示時に会社名をselectBoxに選択肢をセット
     * @param {object}
     */
    getCompanySelect = async () => {
        try{
            const res = await this.ascAxios("post", `Common/companySelect`, {
                container: this.state.containerPath
            });

            this.setState({
                companySelect: res.data
            });
        } catch (err) {
            throw err;
        }
    }

    getDepartmentSelect = async ({companyId, departmentId, departmentPrefix, floor_flg}, type) => {
        // 共通関数へ渡すことが可能な形式に変換
        try{
            let res = await this.ascAxios("post", `Common/departmentSelect`, {
                container: this.state.containerPath,
                company_id: companyId,
                floor_flg
            });

            this.setState({
                departmentSelect: res.data
            })
        } catch(err) {
            throw err;
        }
    }

    getFloorSelect = async ({companyId, departmentId, departmentPrefix, floor_flg}) => {
        if (!floor_flg) {
            return;
        }

        // 共通関数へ渡すことが可能な形式に変換
        let company_id = companyId;
        let department_prefix = departmentPrefix;
        try{
            let res = await this.ascAxios("post", `${this.state.containerPath}/floorSelect`, {
                container: this.state.containerPath,
                company_id,
                department_prefix
            });

            await this.getExternalNumber(res.data.map(row => {
                return row.value;
            }));
            await this.getInboundGroup(res.data.map(row => {
                return row.value;
            }));
            this.state.buttondisabledArr.floor_id.push("dataset");
            this.setState({
                floorSelect: res.data
            })
        } catch(err) {
            throw err;
        }
    }

    getSoundSelect = async ({company_id}) => {
        let langtext_update = this.props.langText.Body.VoiceListUpdate;
        let langtext_create = this.props.langText.Body.VoiceListCreate;

        try {
            const res = await this.ascAxios("post", `${this.state.containerPath}/soundSelect`, {
                company_id, langtext_update, langtext_create
            });

            this.setState({
                connectedSoundSelect: res.data,
                connectedTimeoutSoundSelect: res.data
            })
        } catch (err) {
            throw err;
        }
    }

    getLineChannel = async ({company_id, line_type}) => {
        try {
            const res = await this.ascAxios("post", `${this.state.containerPath}/getLineChannel`, {
                company_id,
                line_type
            });

            this.setState({
                externalMaxOutboundValue: res.data.channel
            });
        } catch (err) {
            throw err;
        }
    }

    async onSelectChange (event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        let floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : "";
        let department_id = null; 

        try {
            switch (param) {
                case "companyId":
                    this.setState({
                        departmentId: "",
                        floorId: "",
                        list_id: "",
                        externalMaxOutboundValue: "",
                        externalNumberSelected: "",
                        inboundGroupsSelected: "",
                        accordionFlag: false,
                        floor_flg
                    });
                    this.state.buttondisabledArr.department_id.push("dataset");
                    this.state.buttondisabledArr.list_id.push("dataset");
                    this.state.buttondisabledArr.sound_id.push("dataset");
                    department_id = await this.getDepartmentId({companyId: value});
                    await this.getDepartmentSelect({companyId: value, floor_flg});
                    await this.getListSelect({companyId: value});
                    await this.getSoundSelect({company_id: value});
                    await this.getInboundGroup(department_id);
                    await this.getExternalNumber(department_id);
                    break;
                case "list_id":
                    this.setState({list_count: event.count});
                    break;
                case "externalNumberSelected":
                    await this.getLineChannel({
                        company_id: event.cm12_id,
                        line_type: event.line_type
                    });
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    async onMultiSelectChange (event, param) {
        super.onMultiSelectChange(event, param);
        let departmentPrefix = [];
        let departmentId = [];

        if (event && event.length > 0) {
            event.forEach(row => {
                departmentPrefix.push(row.department_prefix);
                departmentId.push(row.value);
            });
        }

        try {
            switch (param) {
                case "departmentId":
                    if (this.state.floor_flg) {
                        this.setState({
                            departmentId: departmentId,
                            floorId: "",
                            externalNumberSelected: "",
                            inboundGroupsSelected: ""
                        }, async () => {
                            await this.getFloorSelect({
                                companyId: this.state.companyId,
                                departmentId: this.state.departmentId,
                                departmentPrefix,
                                floor_flg: this.state.floor_flg
                            });
                        });
                        this.state.buttondisabledArr.floor_id.push("dataset");
                    } else {
                        this.setState({
                            departmentId: departmentId,
                            externalNumberSelected: "",
                            inboundGroupsSelected: ""
                        }, async () => {
                            await this.getExternalNumber(this.state.departmentId);
                            await this.getInboundGroup(this.state.departmentId);
                        });
                    }

                    break;
                case "floorId":
                    this.setState({
                        floorId: departmentId,
                        inboundGroupsSelected: "",
                        externalNumberSelected: ""
                    }, async () => {
                        await this.getInboundGroup(this.state.floorId);
                        await this.getExternalNumber(this.state.floorId);
                    });
                    
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    onCheckBoxChange(event, param){
        super.onCheckBoxChange(event, param);
        switch (param) {
            case 'connect_error_flag':
                this.setState({
                    connect_error: null
                });
                break;
            case 'giveup_error_flag':
                this.setState({
                    giveup_error: null
                });
                break;
            default:
                break;
        }
    }

    onClickAccordion = () => {
        this.setState({
            accordionFlag: !this.state.accordionFlag
        });
    }

    getDepartmentId = async ({companyId}) => {
        let company_id = companyId;

        try {
            const result = await this.ascAxios(
                "post",
                `${this.state.containerPath}/getDepartentId`,
                {company_id}
            );

            return result.data;
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    getExternalNumber = async (department_id) => {
        try {
            const externalNumbers = await this.ascAxios(
                "post",
                `${this.state.containerPath}/externalNumSelect`,
                { department_id }
            );
            this.setState({
                externalNumbers: externalNumbers.data
            });

        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    getInboundGroup = async (department_id) => {
        try {
            const inboundGroups = await this.ascAxios(
                "post",
                `${this.state.containerPath}/getInboundGroups`,
                { department_id, inbound_type: "all"}
            );
            this.setState({
                inboundGroups: inboundGroups.data
            });

        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "insert":
            case "copy":
            case "update":
                return (
                    <PredictiveCallInsertUpdateModal
                        getScopeGreaterEqual = {this.getScopeGreaterEqual}
                        floor_flg = {this.state.floor_flg}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onIntChange = {this.onIntChange}
                        onSelectChange = {this.onSelectChange}
                        onMultiSelectChange = {this.onMultiSelectChange}
                        onCheckBoxChange = {this.onCheckBoxChange}
                        validationHandle = {this.validationHandle}
                        getExternalNumber = {this.getExternalNumber}
                        onClickAccordion = {this.onClickAccordion}
                    />
                )
            case "read":
            case "delete":
                return (
                    <PredictiveCallReadDeleteModal
                        floor_flg = {this.state.floor_flg}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}
                />
                )
            case "statistics": 
                return (
                    <PredictvieCallStatisticsModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    onClick = {this.onClickHandle}
                    validationHandle = {this.validationHandle}
                    secToTime = {this.secToTime}
                />
                )
            default:
                break;
        }
    };

    secToTime = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = secs % 60;

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }

    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/AutoCall", name: this.props.langText.Body.AutoCall },
                        { name: this.props.langText.Body.PredictiveCall },
                    ]}
                />
                <Row>
                    <Col xs={12} md={12}>
                        <Button
                            className="table-button margin-top-05"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={this.modalShow("insert")}
                        >
                            {this.props.langText.Body.InsertTitle}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <CommonTable
                            className="predictive-call-table"
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            defaultSorted={[{id: "id", desc: true}]}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </React.Fragment>
        );
    }
}
