import React, { Component } from "react";
import { Panel, Glyphicon } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import { CSVLink } from "react-csv";
import { CSVReader } from 'react-papaparse';
import * as GlobalConst from '../../components/AscConstants';

/**
 * ユーザー情報CSVインポートコンポーネント
 */
export default class UserCsvInsertModal extends Component {

    /**
     * 会社、拠点、フロア設定箇所
     */
    getUserCommonInfoItem = () => {
        let {
            getScopeGreaterEqual,
            floor_flg,
            state,
            langText,
            onSelectChange,
            validationHandle,
        } = this.props;
        let itemArr = [];

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))
        {
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.company_id}
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company))
        {
            itemArr.push(
                <InlineForm
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}
                    type = "select"
                    value = {state.department_id}
                    onChange = {e => onSelectChange(e, "department_id")}
                    options = {state.departmentSelect}
                    validationState = {validationHandle("department_id")}
                    placeholder = {state.buttondisabledArr.department_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.department_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}>
                    {state.department_name}
                </InlineForm.static>
            );
        }

        if (floor_flg) {
            if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
            {
                itemArr.push(
                    <InlineForm
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}
                        type = "select"
                        value = {state.floor_id}
                        onChange = {e => onSelectChange(e, "floor_id")}
                        options = {state.floorSelect}
                        validationState = {validationHandle("floor_id")}
                        placeholder = {state.buttondisabledArr.floor_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.floor_id[0]}
                        />
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}>
                        {state.floor_name}
                    </InlineForm.static>
                );
            }
        }

        return itemArr;
    }

    /**
     * CSV投入箇所
     */
    csvInsert = () => {
        let {
            handleOnDrop,
            removeCsvFile,
            langText,
            headers,
            sprintf,
            csvRowCountMaxLimit,
            charaLimit,
            state
        } = this.props;
        let itemArr = [];

        // CSVアップロード箇所
        itemArr.push(
            <CSVReader
                onDrop={handleOnDrop}
                onError={removeCsvFile}
                addRemoveButton={!state.csvInsertLoadingFlag}
                noClick={state.csvInsertLoadingFlag}
                removeButtonColor="#659cef"
                onRemoveFile={removeCsvFile}
                isReset={true}>
                <span>{langText.Message.CsvUpload}</span>
            </CSVReader>
        )

        // テンプレートDL箇所
        itemArr.push(
            <CSVLink
                className="csv-format-download-link"
                headers={headers}
                data={[]}
                filename={langText.Body.UserCsvTemplateFileName}
            >
                <b>{langText.Message.FormatDownloadLink}</b>
            </CSVLink>
        );

        let requiredFieldStr = headers.map((item, index) => {
            if (item.required === true) {
                if (index != headers.length-1) {
                    return `${item.label}, `
                } else {
                    return item.label
                }
            }
        });

        // 注意書き
        itemArr.push(
            <>
                <p className="csvImportMsgCount">
                    {sprintf(langText.Message.CsvNoteCount, csvRowCountMaxLimit)}
                </p>
                <p className="csvImportMsgRequiredField">
                    {sprintf(langText.Message.CsvNoteRequiredField)}
                </p>
                <p className="csvImportMsgRequiredField">
                    {requiredFieldStr}
                </p>
                <p className="csvImportMsgRules">
                    {sprintf(langText.Message.CsvNoteRules)}
                </p>
                <ul>
                    <li>
                        {sprintf(
                            langText.Message.UserCsvNoteMailAddressRule,
                            langText.Body.EmailAddress,
                            charaLimit.Customer_mailAddress
                        )}
                    </li>

                    <li>
                        {sprintf(
                            langText.Message.CsvNoteCharaLimit,
                            langText.Body.FirstNameCc + 
                            "(" + langText.Body.ChineseCharacter + ")",
                            charaLimit.Customer_firstNameCc
                        )}
                    </li>
                    <li>
                        {sprintf(
                            langText.Message.CsvNoteCharaLimit,
                            langText.Body.LastNameCc + 
                            "(" + langText.Body.ChineseCharacter + ")",
                            charaLimit.Customer_lastNameCc
                        )}
                    </li>
                    <li>
                        {sprintf(
                            langText.Message.CsvNoteCharaLimit,
                            langText.Body.FirstNameKana + 
                            "(" + langText.Body.Katakana + ")",
                            charaLimit.Customer_firstNameKana
                        )}
                    </li>
                    <li>
                        {sprintf(
                            langText.Message.CsvNoteCharaLimit,
                            langText.Body.LastNameKana + 
                            "(" + langText.Body.Katakana + ")",
                            charaLimit.Customer_lastNameKana
                        )}
                    </li>

                    <li>
                        {sprintf(
                            langText.Message.CsvNotePassword,
                            langText.Body.Password,
                            charaLimit.User_password
                        )}
                    </li>
                </ul>
            </>
        )

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>{this.props.langText.Body.UserCsvBelongInfo}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getUserCommonInfoItem()}
                    </Panel.Body>
                </Panel>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>{this.props.langText.Body.CsvUpload}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.csvInsert()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
