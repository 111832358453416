import React, {Component} from "react"
import {Panel, Col, Glyphicon, Checkbox} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from '../../components/AscConstants';

export default class UserModal extends Component {
    getUserInfoItem = () => {
        let {
            getScopeGreaterEqual,
            floor_flg,
            loginUserId,
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onSelectChange,
            validationHandle,
            onCheckBoxChange,
            passwordValidationHandle
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" || state.modalType === "copy") {
            itemArr.push(
                <InlineForm
                    key = "mailAddress"
                    controlId = "mailAddress"
                    label = {langText.Body.EmailAddress}
                    type = "email"
                    value = {state.mailAddress}
                    onChange = {e => onTextChange_Limit(e, "mailAddress",charaLimit.User_mailAddress)}
                    validationState = {validationHandle("mailAddress")}
                    placeholder ={langText.Message.Email_Format}/>
            );
        } else if (state.modalType === "read" || state.modalType === "delete" || state.modalType === "update") {
            itemArr.push(
                <InlineForm.static
                    key = "mailAddress"
                    controlId = "mailAddress"
                    label = {langText.Body.EmailAddress}>
                    {state.mailAddress}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "copy" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "userName"
                    controlId = "userName"
                    label = {langText.Body.UserNameCc}>
                    <Col sm={5} className="firstName-form">
                        <InlineForm
                            key = "firstNameCc"
                            controlId = "firstNameCc"
                            label = {langText.Body.FirstNameCc}
                            type = "text"
                            value = {state.firstNameCc}
                            onChange = {e => onTextChange_Limit(e, "firstNameCc",charaLimit.User_firstNameCc)}
                            validationState = {validationHandle("firstNameCc")}/>
                    </Col>

                    <Col sm={5} className="lastName-form">
                        <InlineForm
                            key = "lastNameCc"
                            controlId = "lastNameCc"
                            label = {langText.Body.LastNameCc}
                            type = "text"
                            value = {state.lastNameCc}
                            onChange = {e => onTextChange_Limit(e, "lastNameCc",charaLimit.User_lastNameCc)}
                            validationState = {validationHandle("lastNameCc")}/>
                    </Col>
                </InlineForm>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm
                    key = "userName"
                    controlId = "userName"
                    label = {langText.Body.UserNameCc}>
                    <Col sm={5} className="firstName-form">
                        <InlineForm.static
                            key = "firstNameCc"
                            controlId = "firstNameCc"
                            label = {langText.Body.FirstNameCc}>
                            {state.firstNameCc}
                        </InlineForm.static>
                    </Col>

                    <Col sm={5} className="lastName-form">
                        <InlineForm.static
                            key = "lastNameCc"
                            controlId = "lastNameCc"
                            label = {langText.Body.LastNameCc}>
                            {state.lastNameCc}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
            );
        }
        if (state.modalType === "insert" || state.modalType === "copy" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "userNameKana"
                    controlId = "userNameKana"
                    label = {langText.Body.UserNameKana}>
                    <Col sm={5} className="firstName-form">
                        <InlineForm
                            key = "firstNameKana"
                            controlId = "firstNameKana"
                            label = {langText.Body.FirstNameKana}
                            type = "text"
                            value = {state.firstNameKana}
                            onChange = {e => onTextChange_Limit(e, "firstNameKana",charaLimit.User_firstNameKana)}
                            validationState = {validationHandle("firstNameKana")}/>
                    </Col>

                    <Col sm={5} className="lastName-form">
                        <InlineForm
                            key = "lastNameKana"
                            controlId = "lastNameKana"
                            label = {langText.Body.LastNameKana}
                            type = "text"
                            value = {state.lastNameKana}
                            onChange = {e => onTextChange_Limit(e, "lastNameKana",charaLimit.User_lastNameKana)}
                            validationState = {validationHandle("lastNameKana")}/>
                    </Col>
                </InlineForm>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm
                    key = "userNameKana"
                    controlId = "userNameKana"
                    label = {langText.Body.UserNameKana}>
                    <Col sm={5} className="firstName-form">
                        <InlineForm.static
                            key = "firstNameKana"
                            controlId = "firstNameKana"
                            label = {langText.Body.FirstNameKana}>
                            {state.firstNameKana}
                        </InlineForm.static>
                    </Col>

                    <Col sm={5} className="lastName-form">
                        <InlineForm.static
                            key = "lastNameKana"
                            controlId = "lastNameKana"
                            label = {langText.Body.LastNameKana}>
                            {state.lastNameKana}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
            );
        }

        if (state.modalType === "insert" || state.modalType === "copy") {
            itemArr.push(
                <InlineForm
                    key = "password"
                    controlId = "password"
                    label = {langText.Body.Password}
                    type = "password"
                    value = {state.password}
                    onChange = {e => onTextChange_Limit(e, "password",charaLimit.User_password)}
                    validationState = {passwordValidationHandle("password", state)}
                    placeholder = {langText.Message.Password_Validation}
                    autoComplete="new-password"/>
            );
        }

        if (state.modalType === "insert" || state.modalType === "copy") {
            itemArr.push(
                <InlineForm
                    key = "passwordRe"
                    controlId = "passwordRe"
                    label = {langText.Body.PasswordRe}
                    type = "password"
                    value = {state.passwordRe}
                    onChange = {e => onTextChange_Limit(e, "passwordRe",charaLimit.User_passwordRe)}
                    validationState = {passwordValidationHandle("passwordRe", state)}
                    placeholder = {langText.Message.Password_Validation}
                    autoComplete="new-password"/>
            );

            if((state.password !== state.passwordRe) && (state.password.length > 0 && state.passwordRe.length > 0)){
                itemArr.push(
                    <p key = "passwordMismatch" className="text-danger">
                        {langText.Body.PasswordMismatch}
                    </p>
                );
            }
        }

        if (state.modalType === "update" && (state.mailAddress === loginUserId 
            || getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)))
        {
            if(state.mailAddress !== loginUserId){
                itemArr.push(
                    <Checkbox
                        key = "password-reset-flag"
                        className = "password-reset-flag"
                        checked = {state.passwordResetFlag}
                        onChange = {e => onCheckBoxChange(e, "passwordResetFlag")}
                    >
                        {langText.Body.ResetPassword}
                    </Checkbox>
                )
            } else{
                itemArr.push(
                    <InlineForm
                        key = "oldPassword"
                        controlId = "oldPassword"
                        label = {langText.Body.PasswordOld}
                        type = "password"
                        value = {state.oldPassword}
                        onChange = {e => onTextChange_Limit(e, "oldPassword",charaLimit.User_oldPassword)}
                        validationState = {passwordValidationHandle("oldPassword", state)}
                        placeholder = {langText.Message.Password_Validation}
                        autoComplete="new-password"/>
                );
            }
            if(state.mailAddress === loginUserId || state.passwordResetFlag){
                itemArr.push(
                    <InlineForm
                        key = "newPassword"
                        controlId = "newPassword"
                        label = {langText.Body.PasswordNew}
                        type = "password"
                        value = {state.newPassword}
                        onChange = {e => onTextChange_Limit(e, "newPassword",charaLimit.User_newPassword)}
                        validationState = {passwordValidationHandle("newPassword", state)}
                        placeholder = {langText.Message.Password_Validation}
                        autoComplete="new-password"/>
                );

                itemArr.push(
                    <InlineForm
                        key = "newPasswordRe"
                        controlId = "newPasswordRe"
                        label = {langText.Body.PasswordNewRe}
                        type = "password"
                        value = {state.newPasswordRe}
                        onChange = {e => onTextChange_Limit(e, "newPasswordRe",charaLimit.User_newPasswordRe)}
                        validationState = {passwordValidationHandle("newPasswordRe", state)}
                        placeholder = {langText.Message.Password_Validation}
                        autoComplete="new-password"/>
                );

                if((state.newPassword !== state.newPasswordRe) && (state.newPassword.length > 0 && state.newPasswordRe.length > 0)){
                    itemArr.push(
                        <p key = "passwordMismatch" className="text-danger">
                            {langText.Body.PasswordMismatch}
                        </p>
                    );
                }
            }
        }

        if ((state.modalType === "insert" || state.modalType === "copy" || state.modalType === "update")
            && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))
        {
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.company_id}
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    isClearable = {true}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}/>
            );
        } else if (state.modalType === "delete" || state.modalType === "read" || state.modalType === "insert"
            || state.modalType === "copy" || state.modalType === "update")
        {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if ((state.modalType === "insert" || state.modalType === "copy" || state.modalType === "update")
            && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company))
        {
            itemArr.push(
                <InlineForm
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}
                    type = "select"
                    value = {state.department_id}
                    onChange = {e => onSelectChange(e, "department_id")}
                    options = {state.departmentSelect}
                    isClearable = {true}
                    validationState = {validationHandle("department_id")}
                    placeholder = {state.buttondisabledArr.department_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.department_id[0]}/>
            );
        } else if (state.modalType === "delete" || state.modalType === "read" || state.modalType === "insert"
            || state.modalType === "copy" || state.modalType === "update")
        {
            itemArr.push(
                <InlineForm.static
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}>
                    {state.department_name}
                </InlineForm.static>
            );
        }

        if (floor_flg) {
            if ((state.modalType === "insert" || state.modalType === "copy" || state.modalType === "update")
                && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
            {
                itemArr.push(
                    <InlineForm
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}
                        type = "select"
                        value = {state.floor_id}
                        onChange = {e => onSelectChange(e, "floor_id")}
                        options = {state.floorSelect}
                        isClearable = {true}
                        validationState = {validationHandle("floor_id")}
                        placeholder = {state.buttondisabledArr.floor_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.floor_id[0]}/>
                );
            } else if (state.modalType === "delete" || state.modalType === "read" || state.modalType === "insert"
                || state.modalType === "copy" || state.modalType === "update")
            {
                itemArr.push(
                    <InlineForm.static
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}>
                        {state.floor_name}
                    </InlineForm.static>
                );
            }
        }

        // 権限選択
        if (state.modalType === "insert" || state.modalType === "copy"
            || (state.modalType === "update" && (!state.permission_id
                || state.permissionSelect.some(row => row.value === state.permission_id)))) {
            itemArr.push(
                <InlineForm
                    key = "permission_id"
                    controlId = "permission_id"
                    label = {langText.Body.Auth}
                    type = "select"
                    value = {state.permission_id}
                    onChange = {e => onSelectChange(e, "permission_id")}
                    options ={state.permissionSelect}
                    isClearable = {true}
                    validationState = {validationHandle("permission_id")}
                    placeholder = {state.permissionSelectLoad ?
                        <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {state.company_id === ''}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "permission_id"
                    controlId = "permission_id"
                    label = {langText.Body.Auth}>
                    {state.permission_name}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}
                onClickCancelLockingBtn = {this.props.onClickCancelLockingBtn}
            >
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.UserInsert}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getUserInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}