import React from "react";
import Component from "../../../components/AscComponent";
import {Link} from "react-router-dom";
import {Nav, Navbar, NavItem, Button, Glyphicon, ButtonToolbar, OverlayTrigger, Popover} from "react-bootstrap";
import "./Header.css";
import UserChangeSelfPasswordModal from "../../User/UserChangeSelfPasswordModal"
// Reactで画像表示する場合css上でのbackgroundimage使用不可(画像が呼べない)
// headerとAppのみ特例でStyle直書きしてます

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    handleMouseOver = event => {
        this.setState({disabled: false});
    };

    handleMouseOut = event => {
        this.setState({disabled: true});
    };

    handleLogout = async event => {
        if (window.confirm("ログアウトしますか?")) {
            await this.ascAxios("post", "/Sign/logout");
            this
                .props
                .childProps
                .userHasAuthenticated(false);
            this
                .props
                .childProps
                .historyPush("/SignIn");
        }
    };

    handleChangePassword = () => {
        this.setState({
            show: true,
            modalType: "updateSelfPassword",
            oldPassword:    '',
            newPassword:    '',
            newPasswordRe:  ''
        });
    }

    render() {
        let navbarHeaderElem = <Navbar.Header>
            <Navbar.Brand>
                {/*<Link to="/" className="hover-black margin-left-05 margin-top-03">{this.props.childProps.langText.Header.MainLink}</Link>*/}
                <a className="hover-black margin-left-05 margin-bottom-10"><img src = {this.props.childProps.systemParameters["HEADERLOGO"]} onClick={e => this.props.childProps.historyPush("/")}></img></a>
            </Navbar.Brand>
            <Navbar.Toggle/>
        </Navbar.Header>;

        let navbarCollapseElem = null;
        let user_info = (
            <Popover id="popover-User-Info" title={this.props.childProps.langText.Body.UserInfo}>
                <strong>{this.props.childProps.langText.Body.EmailAddress}:</strong> &nbsp; {this.props.childProps.userInfo.user_id}<br/>
                <strong>{this.props.childProps.langText.Body.UserName}:</strong> &nbsp; {this.props.childProps.userInfo.user_name_sei + this.props.childProps.userInfo.user_name_mei}<br/>
                <strong>{this.props.childProps.langText.Body.Auth}:</strong> &nbsp; {this.props.childProps.userInfo.permission_name}<hr/>
                <div class="user-info-footer">
                    <span className="change-password-button"><Button onClick={() => this.handleChangePassword()}>{this.props.childProps.langText.Body.ChangePassword}</Button></span>
                    <span className="logout-button"><Button onClick={this.handleLogout}>{this.props.childProps.langText.Body.LogOut}</Button></span>
                </div>
            </Popover>
        );

        if (this.props.childProps.isAuthenticated) {
            navbarCollapseElem = <Navbar.Collapse>
                <Nav pullRight={true} className="header-nav">
                    <NavItem className="Header-User-Icon">
                        <ButtonToolbar>
                            <OverlayTrigger trigger="focus" placement="left" overlay={user_info}>
                            <Button><Glyphicon glyph="user"></Glyphicon></Button>
                            </OverlayTrigger>
                        </ButtonToolbar>
                    </NavItem>
                    {this.props.children || null}
                </Nav>
            </Navbar.Collapse>
        }

        return (
            <>
                <Navbar fluid={true} collapseOnSelect={true} className="Header">
                    {navbarHeaderElem}
                    {navbarCollapseElem}
                </Navbar>

                <UserChangeSelfPasswordModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    userId = {this.props.childProps.userInfo.cm15_id}
                    mailAddress = {this.props.childProps.userInfo.user_id}
                    firstName = {this.props.childProps.userInfo.user_name_sei}
                    lastName = {this.props.childProps.userInfo.user_name_mei}
                    langText = {this.props.childProps.langText}
                    charaLimit = {this.props.childProps.charaLimit}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    passwordValidationHandle = {this.passwordValidationHandle}
                />
            </>
        );
    }
}
