import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";

export default class CallTypeBarChart extends AscComponent {
    constructor(props) {
        super(props);
    
        this.state = {
            indexKey: 1,
            outbound_series: [{
                data: [0]                
            }],
            outbound_options: {
                chart: {
                    id: "outbound",
                    toolbar: {
                        show: false,
                    },
                    background: '#fff'
                },
                tooltip: {
                    enabled: false
                },
                annotations: {
                    xaxis: [
                        {
                            x: 0, 
                            label: {
                                text: this.props.langText.Body.Call,
                                textAnchor: 'middle',
                                orientation: 'horizontal',
                                style: {
                                    color: '#000', 
                                    background: 'transparent',
                                    fontSize: '15px',
                                    fontWeight: 800
                                },
                                borderWidth: 0
                            },
                            borderWidth: 0
                        },
                        {
                            x: 17, 
                            label: {
                                text: '0回',
                                textAnchor: 'middle',
                                orientation: 'horizontal',
                                style: {
                                    color: '#848484', 
                                    background: 'transparent',
                                    fontSize: '13px'
                                },
                                borderWidth: 0
                            },
                            borderWidth: 0
                        },
                        {
                            x: 77, 
                            label: {
                                text: this.props.langText.Body.Absent,
                                textAnchor: 'middle',
                                orientation: 'horizontal',
                                style: {
                                    color: '#000', 
                                    background: 'transparent',
                                    fontSize: '15px',
                                    fontWeight: 800
                                },
                                borderWidth: 0
                            },
                            borderWidth: 0
                        },
                        {
                            x: 94, 
                            label: {
                                text: '0回',
                                textAnchor: 'middle',
                                orientation: 'horizontal',
                                style: {
                                    color: '#848484', 
                                    background: 'transparent',
                                    fontSize: '13px'
                                },
                                borderWidth: 0
                            },
                            borderWidth: 0
                        }
                    ]
                },
                xaxis: {
                    categories: ["0%"],
                    labels: {
                        show: false
                    },
                    tickAmount: 0,
                    axisBorder: {
                        show: false
                    }
                },
                yaxis: {
                    tickAmount: 0,
                    max: 100,
                    axisBorder: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '20%',
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                color: '#A5DF00'
                            }],
                            backgroundBarColors: ['#A5DF00'],
                            backgroundBarOpacity: 0.3
                        },
                    },
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: 'transparent'
                }
            },
            inbound_series: [{
                data: [0]                
            }],
            inbound_options: {
                chart: {
                    id: "inbound",
                    toolbar: {
                        show: false,
                    },
                    background: '#fff'
                },
                tooltip: {
                    enabled: false
                },
                annotations: {
                    xaxis: [
                        {
                            x: 0, 
                            label: {
                                text: this.props.langText.Body.Inbound,
                                textAnchor: 'middle',
                                orientation: 'horizontal',
                                style: {
                                    color: '#000', 
                                    background: 'transparent',
                                    fontSize: '15px',
                                    fontWeight: 800
                                },
                                borderWidth: 0
                            },
                            borderWidth: 0
                        },
                        {
                            x: 17, 
                            label: {
                                text: '0回',
                                textAnchor: 'middle',
                                orientation: 'horizontal',
                                style: {
                                    color: '#848484', 
                                    background: 'transparent',
                                    fontSize: '13px'
                                },
                                borderWidth: 0
                            },
                            borderWidth: 0
                        },
                        {
                            x: 77, 
                            label: {
                                text: this.props.langText.Body.Absent,
                                textAnchor: 'middle',
                                orientation: 'horizontal',
                                style: {
                                    color: '#000', 
                                    background: 'transparent',
                                    fontSize: '15px',
                                    fontWeight: 800
                                },
                                borderWidth: 0
                            },
                            borderWidth: 0
                        },
                        {
                            x: 94, 
                            label: {
                                text: '0回',
                                textAnchor: 'middle',
                                orientation: 'horizontal',
                                style: {
                                    color: '#848484', 
                                    background: 'transparent',
                                    fontSize: '13px'
                                },
                                borderWidth: 0
                            },
                            borderWidth: 0
                        }
                    ]
                },
                xaxis: {
                    categories: ["0%"],
                    labels: {
                        show: false
                    },
                    tickAmount: 0,
                    axisBorder: {
                        show: false
                    }
                },
                yaxis: {
                    tickAmount: 0,
                    max: 100,
                    axisBorder: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '20%',
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                color: '#A5DF00'
                            }],
                            backgroundBarColors: ['#A5DF00'],
                            backgroundBarOpacity: 0.3
                        },
                    },
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: 'transparent'
                }
            }
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.data !== this.props.data    
        ) {
            this.onChartDataChange();
        }
    }

    onChartDataChange = () => {
        const {
            data,
            langText
        } = this.props;
        let outbound_options = {...this.state.outbound_options},
            inbound_options = {...this.state.inbound_options};
        
        let total_outbound_count = data.total_outbound_count,
            total_inbound_count = data.total_inbound_count,
            total_answered_outbound = data.answered_outbound_count,
            total_answered_inbound = data.answered_inbound_count,
            total_absent_outbound = data.total_outbound_count - data.answered_outbound_count,
            total_absent_inbound = data.total_inbound_count - data.answered_inbound_count,
            outbound_per = total_outbound_count !== 0 ? Math.round((total_answered_outbound / total_outbound_count) * 100, 1) : 0,
            inbound_per = total_inbound_count !== 0 ?  Math.round((total_answered_inbound / total_inbound_count) * 100, 1) : 0;

        outbound_options.plotOptions.bar.colors.ranges[0].to = outbound_per;
        inbound_options.plotOptions.bar.colors.ranges[0].to = inbound_per;

        outbound_options.annotations.xaxis[1].label.text = `${total_answered_outbound.toLocaleString()}${langText.Body.TimeCount}`;
        outbound_options.annotations.xaxis[3].label.text = `${total_absent_outbound.toLocaleString()}${langText.Body.TimeCount}`;
        inbound_options.annotations.xaxis[1].label.text = `${total_answered_inbound.toLocaleString()}${langText.Body.TimeCount}`;
        inbound_options.annotations.xaxis[3].label.text = `${total_absent_inbound.toLocaleString()}${langText.Body.TimeCount}`;

        outbound_options.xaxis.categories = [`${outbound_per}%`];
        inbound_options.xaxis.categories = [`${inbound_per}%`];

        this.setState({
            outbound_options: {
                ...outbound_options,
                xaxis: {
                    categories: outbound_options.xaxis.categories,
                    labels: {
                        show: false
                    },
                    tickAmount: 0,
                    axisBorder: {
                        show: false
                    }
                }
            },
            outbound_series: [{
                ...this.state.outbound_series,
                data: [outbound_per]
            }],
            inbound_options: {
                ...inbound_options,
                xaxis: {
                    categories: inbound_options.xaxis.categories,
                    labels: {
                        show: false
                    },
                    tickAmount: 0,
                    axisBorder: {
                        show: false
                    }
                }
            },
            inbound_series: [{
                ...this.state.inbound_series,
                data: [inbound_per]
            }],
            indexKey: this.state.indexKey + 1
        });
    }

    render() {
        return (
            <div className="call-type-bar-chart">
                <Col sm={12} md={12} className="chart-title padding-left-0">
                    <span>{this.props.langText.Body.CallType}</span>
                </Col>
                <div>
                    <Chart 
                        options={this.state.outbound_options} 
                        series={this.state.outbound_series} 
                        type="bar" 
                        height={170} 
                        key={this.state.indexKey}/>
                </div>
                <div className="padding-top-16">
                    <Chart 
                        options={this.state.inbound_options}
                        series={this.state.inbound_series} 
                        type="bar" 
                        height={170} 
                        key={this.state.indexKey} />
                </div>
            </div>
        );
    }
}
