// item
import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";

// controller
import Gateway from "./containers/Gateway/Gateway";
import Pbx from "./containers/Pbx/Pbx";
import SignIn from "./containers/SignIn/SignIn";
import ExternalNumber from "./containers/ExternalNumber/ExternalNumber";
import ExtensionNumber from "./containers/ExtensionNumber/ExtensionNumber";
import BusinessCalendar from "./containers/BusinessCalendarV2/BusinessCalendar";
import InboundGroup from "./containers/InboundGroup/InboundGroup";
import GroupExtensionNumber from "./containers/GroupExtensionNumber/GroupExtensionNumber";
import OutboundReject from "./containers/OutboundReject/OutboundReject";
import InboundReject from "./containers/InboundReject/InboundReject";
import Company from "./containers/Company/Company";
import Department from "./containers/Department/Department";
import Floor from "./containers/Floor/Floor";
import User from "./containers/User/User";
import VoiceFile from "./containers/VoiceFile/VoiceFile";
import Customer from "./containers/Customer/Customer";
import CallHistory from "./containers/CallHistory/CallHistory";
import CallHistoryAnalysis from "./containers/CallHistory/CallHistoryAnalysis";
import Permission from "./containers/Permission/Permission";
import AutoCallList from "./containers/AutoCallList/AutoCallList";
import AutoCallDetailList from "./containers/AutoCallList/AutoCallDetailList";
import Script from "./containers/Script/Script";
import Forbidden from "./containers/Forbidden/Forbidden";
import SeatView from "./containers/SeatView/SeatView";
import Monitoring from "./containers/Monitoring/Monitoring";
import Summary from "./containers/Summary/Summary";
import MonitoringSummary from "./containers/Summary/MonitoringSummary";
import ChUsedSummary from "./containers/Summary/ChUsedSummary";
import CallCountSummary from "./containers/Summary/CallCountSummary";
import WaitCallSummary from "./containers/Summary/WaitCallSummary";
import CallAnalysis from "./containers/CallAnalysis/CallAnalysis";
import BusinessManagement from "./containers/BusinessManagement/BusinessManagement";
import SkillManagement from "./containers/BusinessManagement/SkillManagement";
import CallMonitor from "./containers/CallMonitor/CallMonitor";
import Operator from "./containers/Operator/Operator";
import CallMonitoring from "./containers/CallMonitoring/CallMonitoring";
import WaitStatuses from "./containers/CallMonitoring/WaitStatuses";
import AutoCall from "./containers/AutoCall/AutoCall";
import PredictiveCall from "./containers/AutoCall/PredictiveCall/PredictiveCall";
import Histories from "./containers/AutoCall/PredictiveCall/Histories";
import CallAnalysisSummary from "./containers/Summary/CallAnalysisSummary";

/**
 * コントローラー追加したらsrc/components/AscConstants.jsにも記載すること
 * @param childProps
 * @returns {JSX.Element}
 */
export default ({ childProps }) => (
    <Switch>
        <AppliedRoute path="/Gateway" exact component={Gateway} props={childProps} />
        <AppliedRoute path="/Pbx" exact component={Pbx} props={childProps} />
        <AppliedRoute path="/SignIn" exact component={SignIn} props={childProps} />
        <AppliedRoute path="/ExternalNumber" exact component={ExternalNumber} props={childProps} />
        <AppliedRoute
            path="/ExtensionNumber"
            exact
            component={ExtensionNumber}
            props={childProps}
        />
        <AppliedRoute
            path="/BusinessCalendar"
            exact
            component={BusinessCalendar}
            props={childProps}
        />
        <AppliedRoute path="/InboundGroup" exact component={InboundGroup} props={childProps} />
        <AppliedRoute
            path="/GroupExtensionNumber"
            exact
            component={GroupExtensionNumber}
            props={childProps}
        />
        <AppliedRoute path="/OutboundReject" exact component={OutboundReject} props={childProps} />
        <AppliedRoute path="/Company" exact component={Company} props={childProps} />
        <AppliedRoute path="/Department" exact component={Department} props={childProps} />
        <AppliedRoute path="/Floor" exact component={Floor} props={childProps} />
        <AppliedRoute path="/InboundReject" exact component={InboundReject} props={childProps} />
        <AppliedRoute path="/User" exact component={User} props={childProps} />
        <AppliedRoute path="/VoiceFile" exact component={VoiceFile} props={childProps} />
        <AppliedRoute path="/Customer" exact component={Customer} props={childProps} />
        <AppliedRoute path="/CallHistory" exact component={CallHistory} props={childProps} />
        <AppliedRoute
            path="/CallHistory/CallHistoryAnalysis"
            exact
            component={CallHistoryAnalysis}
            props={childProps}
        />
        <AppliedRoute path="/Permission" exact component={Permission} props={childProps} />
        <AppliedRoute path="/Script" exact component={Script} props={childProps} />
        <AppliedRoute path="/AutoCallList" exact component={AutoCallList} props={childProps} />
        <AppliedRoute
            path="/AutoCallList/AutoCallDetailList"
            exact
            component={AutoCallDetailList}
            props={childProps}
        />
        <AppliedRoute path="/Monitoring" exact component={Monitoring} props={childProps} />
        <AppliedRoute path="/Summary" exact component={Summary} props={childProps} />
        <AppliedRoute
            path="/Summary/MonitoringSummary"
            exact
            component={MonitoringSummary}
            props={childProps}
        />
        <AppliedRoute
            path="/Summary/ChUsedSummary"
            exact
            component={ChUsedSummary}
            props={childProps}
        />
        <AppliedRoute
            path="/Summary/CallCountSummary"
            exact
            component={CallCountSummary}
            props={childProps}
        />
        <AppliedRoute
            path="/Summary/CallAnalysisSummary"
            exact
            component={CallAnalysisSummary}
            props={childProps}
        />
        <AppliedRoute
            path="/Summary/WaitCallSummary"
            exact
            component={WaitCallSummary}
            props={childProps}
        />
        <AppliedRoute path="/CallAnalysis" exact component={CallAnalysis} props={childProps} />
        <AppliedRoute
            path="/BusinessManagement"
            exact
            component={BusinessManagement}
            props={childProps}
        />
        <AppliedRoute
            path="/BusinessManagement/SkillManagement"
            exact
            component={SkillManagement}
            props={childProps}
        />
        <AppliedRoute path="/CallMonitor" exact component={CallMonitor} props={childProps} />
        <AppliedRoute path="/Operator" exact component={Operator} props={childProps} />
        <AppliedRoute path="/CallMonitoring" exact component={CallMonitoring} props={childProps} />
        <AppliedRoute
            path="/CallMonitoring/WaitStatuses"
            exact
            component={WaitStatuses}
            props={childProps}
        />
        <AppliedRoute
            path="/CallMonitoring/SeatView"
            exact
            component={SeatView}
            props={childProps}
        />
        <AppliedRoute path="/AutoCall" exact component={AutoCall} props={childProps} />
        <AppliedRoute
            path="/AutoCall/PredictiveCall"
            exact
            component={PredictiveCall}
            props={childProps}
        />
        <AppliedRoute
            path="/AutoCall/PredictiveCall/Histories"
            exact
            component={Histories}
            props={childProps}
        />
        <Route component={Forbidden} />
    </Switch>
);
