import React from 'react'
import {Button, Row, Col, Glyphicon, FormControl} from 'react-bootstrap'
import AscComponent from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import OperatorInsertModal from './OperatorInsertModal'
import OperatorUpdateModal from './OperatorUpdateModal'
import OperatorDeleteModal from './OperatorDeleteModal'
import OperatorReadModal from './OperatorReadModal'
import OperatorCsvInsertModal from "./OperatorCsvInsertModal";
import ErrorMessageModal from "../Elements/AscElements/ErrorMessageModal";
import * as GlobalConst from '../../components/AscConstants';

export default class Operator extends AscComponent {
    constructor(props) {
        super(props)
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            companySelect: [],
            departmentSelect: [],
            floorSelect: [],

            // data item
            id:             '',
            operatorId:         '',
            companyId:     '',
            companyName:    '',
            departmentId:  '',
            departmentName: '',
            departmentPrefix: '',
            floorId:       '',
            floorName:     '',
            password:       '',
            passwordRe:     '',
            newPassword:    '',
            newPasswordRe:  '',
            downloadflag:   '',
            operatorIdDuplicateFlag: false,
            passwordResetFlag: false,
            // button_disabled
            buttondisabledArr: {
                "companyId" : [],
                "departmentId" : [],
                "floorId" : [],
                "departmentLoad": [],
                "floorLoad" : []
            },
            csvFile: false,
            errorMessageModalShow: false
        }

        this.floorLoadFlag = false;

        // csvヘッダ定義
        this.headerJson = [
            {
                label: this.props.langText.Body.OperatorId,
                key: "operatorId"
            },
            {
                label: this.props.langText.Body.OperatorName,
                key: "operatorName"
            },
            {
                label: this.props.langText.Body.Password,
                key: "password"
            }
        ];
    }

    getColumnsData() {
        let itemArr = [];

        // oem管理者以上は会社カラム表示
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: 'cm12_companies.company_name',
                Filter: () => <FormControl
                                placeholder={this.props.langText.Body.ForwardMatch}
                                onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")}
                            />
            });
        }

        // 会社管理者以上は拠点カラム表示
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            itemArr.push({
                Header: this.props.langText.Body.DepartmentName,
                accessor: 'cm13_departments.department_name',
                width: this.props.boardWidth.xxlarge,
                Filter: () => <FormControl
                                placeholder={this.props.langText.Body.ForwardMatch}
                                onChange={e => this.onFilterChange(e.target.value, "cm13_departments.department_name")}
                            />
            });
        }

        // フロア名
        if (
            (this.props.systemParameters.SYSTEM_FLOOR_FLG === "Y" &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) ||
            (this.props.userInfo.floor_flg &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
        ) {
            itemArr.push({
                Header: this.props.langText.Body.FloorName,
                accessor: 'cm13_departments.floor_name',
                width: this.props.boardWidth.large,
                Filter: () => <FormControl
                                placeholder={this.props.langText.Body.ForwardMatch}
                                onChange={e => this.onFilterChange(e.target.value, "cm13_departments.floor_name")} 
                            />
            });
        }

        // オペレーターID
        itemArr.push({
            Header: this.props.langText.Body.OperatorId,
            accessor: 'operator_id',
            Cell: data => {
                return `${data.row._original.operator_id}`
            },
            Filter: () => <FormControl
                            placeholder={this.props.langText.Body.PartialMatch}
                            onChange={e => this.onFilterChange(e.target.value, "operator_id")}
                        />
        });

        // オペレーター名
        itemArr.push({
            Header: this.props.langText.Body.OperatorName,
            accessor: 'operator_name',
            width: this.props.boardWidth.xxlarge,
            Cell: data => {
                return `${data.row._original.operator_name}`
            },
            Filter: () => <FormControl
                            placeholder={this.props.langText.Body.PartialMatch}
                            onChange={e => this.onFilterChange(e.target.value, "operator_name")}
                        />
        });

        // 操作
        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.LargeControl,
            Cell: data => {
                // 権限に応じて各アイコン表示
                let rowData = [];
                // 編集権限あれば編集ボタン、編集権限無ければ閲覧ボタン
                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("update", data.row._original)}
                        >
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    );
                } else {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("read", data.row._original)}
                        >
                            <Glyphicon glyph="eye-open"/>
                        </Button>
                    );
                }
                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={2}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("delete", data.row._original)}
                        >
                            <Glyphicon glyph="minus"/>
                        </Button>
                    );
                }

                return (
                    <Row className="text-center">
                        {rowData}
                    </Row>
                );
            }
        });

        return itemArr;
    }

    checkDuplicateOperatorId = async (event, param = null) => {
        if (this.state.operatorId === "") {
            //未入力の場合はチェックしない
            this.setState({operatorIdDuplicateFlag: false});
            return;
        }
        try{
            let res = await this.ascAxios(
                "post",
                `${this.reactContainerPath}/checkDuplicateOperatorId`,
                {
                    operatorId: this.state.operatorId,
                    companyId: this.state.companyId
                }
            );
            this.setState({operatorIdDuplicateFlag: res.data.isDuplicate})
        } catch (err) {
            this.setState({operatorId: ""})
            this.showErrorObjectMesssage(err);
        }
    }

    // 画面表示タイミング共通処理_変数初期化
    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "companyId" : [],
                "departmentId" : [],
                "floorId" : [],
                "departmentLoad": [],
                "floorLoad" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param, switchData) => {
        switch (switchData)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0){
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.error("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault()

        this.floorLoadFlag = false;
        // 条件配列の初期化
        this.modalshow_commonInit();

        let setData = {
            id: param && param.id
                ? param.id
                : '',
            operatorId: param && param.operator_id
                ? param.operator_id
                : '',
            operatorName: param && param.operator_name
                ? param.operator_name
                : '',
            companyId: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : '',
            companyName: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : '',
            floor_flg: param && param.cm12_companies && param.cm12_companies.floor_flg
                ? param.cm12_companies.floor_flg === "Y"
                : null,
            departmentId: param && param.cm13_departments && param.cm13_departments.id
                ? param.cm13_departments.id
                : '',
            departmentName: param && param.cm13_departments && param.cm13_departments.department_name
                ? param.cm13_departments.department_name
                : '',
            departmentPrefix: param && param.cm13_departments && param.cm13_departments.department_prefix
                ? param.cm13_departments.department_prefix
                : '',
            floorId: param && param.cm13_departments && param.cm13_departments.id
                ? param.cm13_departments.id
                : '',
            floorName: param && param.cm13_departments && param.cm13_departments.floor_name
                ? param.cm13_departments.floor_name
                : '',
            password: '',
            passwordRe: '',
            newPassword: '',
            newPasswordRe: '',
            passwordResetFlag: false,
            operatorIdDuplicateFlag: false
        }

        // 新規作成時にcompany以下なら会社固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            setData.companyId = this.props.userInfo.company_id
            setData.companyName = this.props.userInfo.company_name
            setData.floor_flg = this.props.userInfo.floor_flg
        }
        // 新規作成時にdepartment以下なら拠点固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)) {
            if (this.props.userInfo.floor_flg) {
                setData.departmentId = this.props.userInfo.parent_department_id
                setData.departmentName = this.props.userInfo.parent_department_name
                setData.departmentPrefix = this.props.userInfo.department_prefix
            } else {
                setData.departmentId = this.props.userInfo.department_id
                setData.departmentName = this.props.userInfo.department_name
            }
        }
        // 新規作成時にfloor以下ならフロア固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
            setData.floorId = this.props.userInfo.department_id
            setData.floorName = this.props.userInfo.floor_name
        }

        this.setState(setData)

        try {
            await this.getCompanySelect()
            await this.getDepartmentSelect(setData, "update")
            await this.getFloorSelect(setData)
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }

        this.setState({show: true, modalType})
    }

    onClickHandle = modalType => async event => {
        let {
            id,
            companyId,
            departmentId,
            floorId,
            operatorId,
            operatorName,
            password,
            newPassword,
            csvFile
        } = this.state
        try{
            switch (modalType) {
                case 'insert':
                        this.blockUI();
                        let res = await this.ascAxios('post', `${this.reactContainerPath}/insert`, {
                            companyId,
                            departmentId: floorId || departmentId,
                            operatorId,
                            operatorName,
                            password,
                            newPassword
                        })
                        alert(res.data.operatorId + this.props.langText.Message.User_DataInsertSuccess);
                        this.reactTableRefresh();
                    break;
                case 'update':
                    this.blockUI();
                        await this.ascAxios('post', `${this.reactContainerPath}/update`, {
                            id,
                            operatorId,
                            operatorName,
                            newPassword,
                            companyId,
                            departmentId: floorId || departmentId
                        })
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    break;
                case 'delete':
                    if (window.confirm(`${this.props.langText.Message.User_Delete_Check}`)) {
                        this.blockUI();
                        await this.ascAxios('post', `${this.reactContainerPath}/delete`, {
                            id,
                            operatorId,
                            companyId
                        })
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    }
                    break;
                case "csvInsert":
                    this.blockUI();
                    let result = await this.getCsvData(csvFile);
                    let insertRow = result.insertArray;
                    let errData = result.errorData;
                    let errMessage = [];

                    if (errData && errData.length > 0) {
                        errMessage.push(this.props.langText.Message[errData[0]]);
                        // エラーがある場合のメッセージ作成処理
                        switch (errData[0]) {
                            case "CsvTooManyRows":
                                errMessage[0] = this.sprintf(
                                    this.props.langText.Message.CsvTooManyRows,
                                    this.props.systemParameters.OPERATOR_CSV_ROW_COUNT_MAX_LIMIT
                                );
                                break;
                            case "OperatorCsvHeaderError":
                                // ヘッダーエラー
                                break;
                            case "CsvNotAvailable":
                                // バリデーションエラー
                                errMessage.push(
                                    this.sprintf(
                                        this.props.langText.Message.FailedDataCount,
                                        errData[1].length
                                    )
                                );
                                errData[1].forEach((err) => {
                                    errMessage.push(
                                        this.sprintf(this.props.langText.Message.Line, err.row + 2) +
                                            "　" +
                                            this.sprintf(
                                                this.props.langText.Message[err.errCode],
                                                err.errWord
                                            )
                                    );
                                });
                                break;
                            default:
                                throw new Error("ProcessingFailedError");
                        }
                        this.setState({
                            errorMessageModalShow: true,
                            errText: errMessage.join("\r\n"),
                        });
                        this.reactTableRefresh();
                        return;
                    } else {
                        // DBとCognitoへの登録処理
                        let result = await this.ascAxios("post", `${this.reactContainerPath}/csvInsert`, {
                            companyId,
                            departmentId: floorId || departmentId,
                            insertRow
                        });
                        if (result.data.cognitoErrOperatorIds.length === 0) {
                            // エラーが1件もない場合、全オペレーターID登録成功メッセージを表示
                            alert(this.sprintf(this.props.langText.Message.OperatorCsvRegisterSuccess, insertRow.length));
                        } else {
                            // Cognitoへの登録が失敗したオペレーターIDをメッセージで表示
                            let operatorCsvRegisterErrorMsg = this.sprintf(
                                this.props.langText.Message.OperatorCsvRegisterError,
                                insertRow.length,
                                result.data.cognitoErrOperatorIds.length
                            );
                            for ( let errId of result.data.cognitoErrOperatorIds ) {
                                operatorCsvRegisterErrorMsg += "\r\n" + "・" + errId;
                            }
                            alert( operatorCsvRegisterErrorMsg );
                        }
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    }
                    break;
                default:
                    throw "ModalTypeError";
            }
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
        this.setState({show: false});
    };

    async onSelectChange (event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        let departmentPrefix = (event && event.department_prefix) ? event.department_prefix : "";
        let floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : "";

        try {
            if (param === "companyId") {
                this.setState({
                    departmentId: '',
                    floorId: '',
                    floor_flg
                });
                await this.getDepartmentSelect({companyId: value, floor_flg});
                await this.getFloorSelect({
                    companyId: this.state.companyId,
                    departmentId: '',
                    floor_flg,
                    departmentPrefix
                });
            } else if (param === "departmentId") {
                this.floorLoadFlag = true;
                this.setState({floorId: ''});
                await this.getFloorSelect({
                    companyId: this.state.companyId,
                    departmentId: this.state.departmentId,
                    departmentPrefix,
                    floor_flg: this.state.floor_flg
                });
            }
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    getCompanySelect = async() => {
        try{
            let res = await this.ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            this.common_buttondisabledLengthCheck(`companyId`,`push`);
            this.setState({
                companySelect: res.data
            })
        } catch (err) {
            throw err;
        }
    }

    getDepartmentSelect = async ({companyId, departmentId, departmentPrefix, floor_flg}, type) => {
        if (companyId) {
            this.common_buttondisabledLengthCheck(`departmentLoad`,`push`);
        } else {
            this.common_buttondisabledLengthCheck(`departmentLoad`,`pop`);
        }
        // 共通関数へ渡すことが可能な形式に変換
        let company_id = companyId;
        try{
            let res = await this.ascAxios('post', `Common/departmentSelect`, {container: this.reactContainerPath, company_id, floor_flg})
            if (type === "update" && floor_flg && departmentId && departmentPrefix) {
                let my_prefix = departmentPrefix.slice(0, 2);
                res.data.forEach(row => {
                    if (row.department_prefix && my_prefix === row.department_prefix) {
                        this.setState({departmentId: row.value});
                    }
                });
            }
            if (companyId) {
                this.common_buttondisabledLengthCheck(`departmentId`,`push`);
                this.common_buttondisabledLengthCheck(`departmentLoad`,`pop`);
            } else {
                this.common_buttondisabledLengthCheck(`departmentId`,`pop`);
            }
            this.setState({
                departmentSelect: res.data
            })
        } catch(err) {
            throw err;
        }
    }

    getFloorSelect = async ({companyId, departmentId, departmentPrefix, floor_flg}) => {
        if (!floor_flg) {
            return;
        }
        if (this.floorLoadFlag === true && departmentId && companyId) {
            this.common_buttondisabledLengthCheck(`floorLoad`,`push`);
        } else {
            this.common_buttondisabledLengthCheck(`floorLoad`,`pop`);
        }
        // 共通関数へ渡すことが可能な形式に変換
        let company_id = companyId;
        let department_prefix = departmentPrefix;
        try{
            let res = await this.ascAxios('post', `Common/floorSelect`, {container: this.reactContainerPath, company_id, department_prefix});
            if (this.state.departmentId) {
                this.common_buttondisabledLengthCheck(`floorId`,`push`);
                this.common_buttondisabledLengthCheck(`floorLoad`,`pop`);
                this.floorLoadFlag = false;
            } else {
                this.common_buttondisabledLengthCheck(`floorId`,`pop`);
            }
            this.setState({
                floorSelect: res.data
            })
        } catch(err) {
            throw err;
        }
    }

    validationHandle = param => {
        let validationFlag = true;

        let {
            operatorId,
            operatorName,
            companyId,
            departmentId,
            floorId,
            floor_flg,
            operatorIdDuplicateFlag,
            csvFile
        } = this.state

        // オペレーターIDのチェック(全角文字禁止)
        let operatorIdCheck = /^[\x01-\x7E]+$/

        switch (param) {
            case 'operatorName':
            case 'companyId':
            case 'departmentId':
            case 'floorId':
                validationFlag = !!this.state[param];
                break;

            case 'operatorId':
                validationFlag = operatorIdCheck.test(this.state[param]) && !operatorIdDuplicateFlag;
                break;

            case 'insert':
                validationFlag = (
                    operatorId
                    && operatorIdCheck.test(operatorId)
                    && !operatorIdDuplicateFlag
                    && operatorName
                    && this.passwordValidationHandle(param, this.state)
                    && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
                        || (companyId !== null && companyId !== ''))
                    && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)
                        || (departmentId !== null && departmentId !== '')))
                    && (!floor_flg || (floor_flg && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor))
                        || (floorId !== null && floorId !== ''))
                );
                break;

            case 'update':
                validationFlag = (
                    operatorId
                    && operatorIdCheck.test(operatorId)
                    && operatorName
                    && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
                        || (companyId !== null && companyId !== ''))
                    && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)
                        || (departmentId !== null && departmentId !== ''))
                    && (!floor_flg || (floor_flg && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)
                        || (floorId !== null && floorId !== ''))))
                    && this.passwordValidationHandle(param, this.state)
                );
                break;

            case 'delete':
                validationFlag = (
                    operatorId
                    && operatorName
                    && (companyId !== null && companyId !== '')
                    && (departmentId !== null && departmentId !== '')
                    && !floor_flg || (floor_flg && (floorId !== null && floorId !== ''))
                );
                break;

            case "csvInsert":
                validationFlag =
                    (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company) ||
                        (companyId !== null && companyId !== "")) &&
                    (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department) ||
                        (departmentId !== null && departmentId !== "")) &&
                    (!floor_flg ||
                        (((floor_flg &&
                            this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) ||
                            (floorId !== null && floorId !== "")))) &&
                    csvFile
                break;

            default:
                validationFlag = true;
                break;
        }
        return validationFlag;
    };

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "insert":
                return (
                    <OperatorInsertModal
                        getScopeGreaterEqual = {this.getScopeGreaterEqual}
                        floor_flg = {this.state.floor_flg}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        validationHandle = {this.validationHandle}
                        checkDuplicateOperatorId = {this.checkDuplicateOperatorId}
                        passwordValidationHandle={this.passwordValidationHandle}
                    />
                )
            case "update":
                return (
                    <OperatorUpdateModal
                        getScopeGreaterEqual = {this.getScopeGreaterEqual}
                        floor_flg = {this.state.floor_flg}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        onCheckBoxChange = {this.onCheckBoxChange}
                        validationHandle = {this.validationHandle}
                        passwordValidationHandle={this.passwordValidationHandle}
                    />
                )
            case "delete":
                return (
                    <OperatorDeleteModal
                        floor_flg = {this.state.floor_flg}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}
                />
                )
            case "read":
                return (
                    <OperatorReadModal
                        floor_flg = {this.state.floor_flg}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}
                    />
                )
            case "csvInsert":
                return (
                    <OperatorCsvInsertModal
                        floor_flg={this.state.floor_flg}
                        getScopeGreaterEqual={this.getScopeGreaterEqual}
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        onSelectChange={this.onSelectChange}
                        onClick={this.onClickHandle}
                        handleOnDrop={this.handleOnDrop}
                        validationHandle={this.validationHandle}
                        headers={this.headerJson}
                        charaLimit={this.props.charaLimit}
                        sprintf={this.sprintf}
                        getSelectOption={this.getSelectOption}
                        removeCsvFile={this.removeCsvFile}
                        csvRowCountMaxLimit={this.props.systemParameters.OPERATOR_CSV_ROW_COUNT_MAX_LIMIT}
                    />
                );
            default:
                break;
        }
    };

    /**
     * CSVファイルをアップロードまたはドラッグした際の読み込み処理
     * @param {} data
     * @param {} file
     */
    handleOnDrop = (data, file) => {
        let jsonData = data;

        // ファイル名チェック
        if (!file.name.match(/.csv$/i)) {
            alert(this.props.langText.Message.NotCsvFile);
            this.setState({ csvFile: false });
        } else {
            this.setState({ csvFile: jsonData });
        }
    };

    /**
     * アップロード済みのCSVファイルの削除処理
     */
    removeCsvFile = () => {
        this.setState({ csvFile: false });
    };

    /**
     * CSVデータをjsonカラムへ変換
     * @param {file} importFile
     * @return {array}
     *  insertArray DB(cm16)・cognitoへ登録する情報
     *  errorData [0]:エラーコード, [1]:エラー内容 errorDataに1件以上データがある場合、CSV登録は行われない
     */
    async getCsvData(importFile) {
        try {
            let insertArray = [];
            let errorData = [];
            let errorValidateOperatorId = [];

            if(importFile.slice(-1)[0].data.length === 1 && importFile.slice(-1)[0].data[0] === ""){
                // 最終行に改行が入っている場合、消去
                importFile.pop();
            }

            const csvHeaders = importFile[0].data.map((header) => header.trim());
            const list = importFile.filter((row, index) => {
                if (index !== 0) return row.data;
            });

            // CSV件数チェック
            if (list.length > this.props.systemParameters.OPERATOR_CSV_ROW_COUNT_MAX_LIMIT) {
                errorData[0] = "CsvTooManyRows";
                return { errorData };
            }

            // ヘッダ数チェック
            if (csvHeaders.length !== this.headerJson.length) {
                errorData[0] = "CsvHeaderError";
                return { errorData };
            }

            // ヘッダ設定値チェック
            csvHeaders.forEach((row, index) => {
                if (row !== this.headerJson[index].label) {
                    errorData[0] = "CsvHeaderError";
                    return { errorData };
                }
            });

            // オペレーターIDの既存重複チェック用データ取得・作成
            const allOperatorIds = await this.ascAxios(
                                        "post",
                                        "/Operator/getAllOperatorIdsByCompanyId",
                                        {companyId: this.state.companyId}
                                    );
            const checkOperatorIds = allOperatorIds.data.map((row) => row.operator_id);

            // オペレーターIDのCSV内重複チェック用配列
            const csvOperatorIdList = [];

            // オペレーターIDのチェック(全角文字禁止)
            const operatorIdCheck = /^[\x01-\x7E]+$/;
            // パスワードのチェック(小文字の英数字が1文字以上必要・全角禁止・8文字以上)
            const passwordCheck = /^(?=.*?[a-z])(?=.*?[0-9])[\x01-\x7E]{8,}$/;

            // CSVデータ部を1行ずつループ
            list.forEach((detailRow, rowIndex) => {
                let detail = {};
                let tmpDetail = detailRow.data;

                // CSV内重複チェックのため、配列に追加
                csvOperatorIdList.push(tmpDetail[0]);

                // データ部のカラム数チェック
                if (tmpDetail.length !== this.headerJson.length) {
                    errorValidateOperatorId.push({
                        row: rowIndex,
                        errCode: "CsvDataCountError",
                        errWord: this.headerJson.length
                    });
                    return;
                }

                // 1カラムずつバリデーションチェックを行う。
                // エラーがあればエラー情報配列に行数、エラー内容をpush
                tmpDetail.forEach((item, columnIndex) => {
                    let value = "";
                    let errorFlg = false;
                    // 全項目の必須入力チェック
                    if (item === "" || item === undefined) {
                        errorValidateOperatorId.push({
                            row: rowIndex,
                            errCode: "CsvRequiredError",
                            errWord: this.headerJson[columnIndex].label
                        });
                        return;
                    }
                    switch (this.headerJson[columnIndex].key) {
                        case "operatorId":
                            // オペレーターIDの桁数チェック
                            if (item.length > this.props.charaLimit.Operator_Id) {
                                errorValidateOperatorId.push({
                                    row: rowIndex,
                                    errCode: "OperatorCsvIdSizeError",
                                    errWord: this.props.charaLimit.Operator_Id
                                });
                                errorFlg = true;
                            }
                            // オペレーターIDのフォーマットチェック
                            if (!operatorIdCheck.test(item)) {
                                errorValidateOperatorId.push({
                                    row: rowIndex,
                                    errCode: "OperatorCsvIdFormatError"
                                });
                                errorFlg = true;
                            }
                            // オペレーターIDの重複チェック(既存)
                            if (checkOperatorIds.indexOf(item) >= 0) {
                                errorValidateOperatorId.push({
                                    row: rowIndex,
                                    errCode: "OperatorCsvDuplicateError",
                                    errWord: item
                                });
                                errorFlg = true;
                            }
                            // CSV内のオペレーターID重複チェック
                            let countIdInCsv = csvOperatorIdList.filter((id) => id === item).length;
                            if (countIdInCsv > 1) {
                                errorValidateOperatorId.push({
                                    row: rowIndex,
                                    errCode: "OperatorCsvDuplicateIdInCsv",
                                    errWord: item
                                });
                                errorFlg = true;
                            }
                            if (errorFlg) {
                                break;
                            }
                            value = item;
                            break;
                        case "operatorName":
                            // オペレーター名の桁数チェック
                            if (item.length > this.props.charaLimit.Operator_Name) {
                                errorValidateOperatorId.push({
                                    row: rowIndex,
                                    errCode: "OperatorCsvNameSizeError",
                                    errWord: this.props.charaLimit.Operator_Name
                                });
                                break;
                            }
                            value = item;
                            break;
                        case "password":
                            // パスワードの桁数チェック
                            if (item.length > this.props.charaLimit.Operator_Password) {
                                errorValidateOperatorId.push({
                                    row: rowIndex,
                                    errCode: "OperatorCsvPassWordSizeError",
                                    errWord: this.props.charaLimit.Operator_Password
                                });
                                errorFlg = true;
                            }
                            // パスワードのフォーマットチェック
                            if (!passwordCheck.test(item)) {
                                errorValidateOperatorId.push({
                                    row: rowIndex,
                                    errCode: "OperatorCsvPassWordFormatError"
                                });
                                errorFlg = true;
                            }
                            if (errorFlg) {
                                break;
                            }
                            value = item;
                            break;
                        default:
                            break;
                    }
                    detail[this.headerJson[columnIndex].key] = value;
                });

                insertArray.push(detail);
            });

            if (errorValidateOperatorId.length > 0) {
                errorData[0] = "CsvNotAvailable";
                errorData[1] = errorValidateOperatorId;
            }
            return { insertArray, errorData };
        } catch (err) {
            console.error(err);
            throw new Error(err);
        }
    }

    /**
     * エラーモーダル（CSV投入処理）のクローズ処理
     */
    errorModalClose = () => {
        this.setState({ errorMessageModalShow: false });
    };

    render() {
        return (
            <div className='Operator'>
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id = "user-insert"
                                className = 'table-button'
                                bsStyle = 'default'
                                bsSize = 'sm'
                                onClick = {this.modalShow('insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                            <Button
                                id="autoCallDetailList-csvInsert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("csvInsert")}>
                                {this.props.langText.Body.CsvImport}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}/>
                    </Col>
                    {this.state.errorMessageModalShow && (
                        <ErrorMessageModal
                            errorModalClose={this.errorModalClose}
                            state={this.state}
                            langText={this.props.langText}
                            errText={this.state.errText}
                        />
                    )}
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        );
    }
}
