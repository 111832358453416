import React, {Component} from "react"
import {Panel} from "react-bootstrap"
import AscModal from "../../Elements/Modal/Modal";
import InlineForm from "../../Elements/FromGroup/InlineForm"
import "../../Elements/FromGroup/form_select.css"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export default class PredictiveCallReadDeleteModal extends Component {
    getPredictiveCallInfoItem = () => {
        let {
            floor_flg,
            state,
            langText
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm.static
                key = "AutoCallTaskName"
                controlId = "AutoCallTaskName"
                label = {langText.Body.AutoCallTaskName}>
                {state.task_name}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm.static
                key = "AutoCallAutoStopTime"
                controlId = "AutoCallAutoStopTime"
                label = {langText.Body.AutoCallAutoStopTime}>
                {state.auto_stop_time}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm.static
                key = "companyId"
                controlId = "companyId"
                label = {langText.Body.CompanyName}>
                {state.companyName}
            </InlineForm.static>
        );

        let department_names = "";
        for(let i = 0; i < state.departmentName.length; i++){
            if (i === state.departmentName.length - 1) department_names = department_names + state.departmentName[i];
            else department_names = department_names + state.departmentName[i] + ", ";
        }
        itemArr.push(
            <InlineForm.static
                key = "DepartmentName"
                controlId = "DepartmentName"
                label = {langText.Body.DepartmentName}>
                {department_names ? department_names : langText.SelectOption.Unset}
            </InlineForm.static>
        );

        if (floor_flg) {
            let floor_names = "";
            for(let i = 0; i < state.floorName.length; i++){
                if (i === state.floorName.length - 1) floor_names = floor_names + state.floorName[i];
                else floor_names = floor_names + state.floorName[i] + ", ";
            }
            itemArr.push(
                <InlineForm.static
                    key = "floorId"
                    controlId = "floorId"
                    label = {langText.Body.FloorName}>
                    {floor_names ? floor_names : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        itemArr.push(
            <InlineForm.static
                key = "List"
                controlId = "List"
                label = {langText.Body.List}>
                {state.list_name ? state.list_name : langText.SelectOption.Unset}
            </InlineForm.static>
        );

        let inbound_names = "";
        for(let i = 0; i < state.inboundGroupsName.length; i++){
            if (i === state.inboundGroupsName.length - 1) inbound_names = inbound_names + state.inboundGroupsName[i];
            else inbound_names = inbound_names + state.inboundGroupsName[i] + ", ";
        }
        itemArr.push(
            <React.Fragment>
                <InlineForm.static
                    key = "ExternalNumber"
                    controlId = "ExternalNumber"
                    label = {langText.Body.ExternalNumber}>
                    {state.externalNumberName ? state.externalNumberName : langText.SelectOption.Unset}
                </InlineForm.static>
                <InlineForm.static
                    key = "externalMaxOutbound"
                    controlId = "externalMaxOutbound"
                    label = {langText.Body.AutoCallExternalLimitOutbound}
                >
                    {state.externalMaxOutbound ? state.externalMaxOutbound : langText.SelectOption.Unset}
                </InlineForm.static>
                <InlineForm.static
                    key = "InboundGroupName"
                    controlId = "InboundGroupName"
                    label = {langText.Body.InboundGroupName}>
                    {inbound_names ? inbound_names : langText.SelectOption.Unset}
                </InlineForm.static>
            </React.Fragment>
        );

        let connected_sound = state.connectedSoundSelect.find(row => row.value === state.connected_sound_id);
        let connected_timeout_sound = state.connectedTimeoutSoundSelect.find(row => row.value === state.connected_timeout_sound_id);
        itemArr.push(
            <Accordion preExpanded={["detailSettings"]}>
                <AccordionItem key="detailSettings" uuid="detailSettings">
                    <AccordionItemHeading>
                        <AccordionItemButton
                            className = "margin-bottom-05"
                        >
                            <div className = "fa-11">
                                <span>{langText.Body.DownArrow}</span>
                                <b>{langText.Body.DetailSettings}</b>
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel
                        className = "padding-left-30"
                    >
                        <InlineForm.static
                            key = "AutoCallMagnification"
                            controlId = "AutoCallMagnification"
                            label = {langText.Body.AutoCallMagnification}>
                            {state.outboundRate}
                        </InlineForm.static>
                        <InlineForm.static
                            key = "AutoCallTimeOut"
                            controlId = "AutoCallTimeOut"
                            label = {langText.Body.AutoCallTimeOut}>
                            {state.timeout}
                        </InlineForm.static>
                        {/* <InlineForm.static
                            className="padding-left-30"
                            key = "AutoCallRetrySetting"
                            controlId = "AutoCallRetrySetting"
                            label = {langText.Body.AutoCallRetrySetting}>
                            <InlineForm.static
                                key = "AutoCallRetry"
                                controlId = "AutoCallRetry"
                                label = {langText.Body.AutoCallRetry}>
                                {state.retry}
                            </InlineForm.static>
                            <InlineForm.static
                                key = "AutoCallRetryInterval"
                                controlId = "AutoCallRetryInterval"
                                label = {langText.Body.AutoCallRetryInterval}>
                                {state.retryInterval}
                            </InlineForm.static>
                        </InlineForm.static> */}
                        <InlineForm.static
                            className="padding-left-30"
                            key = "AutoCallConnectedSetting"
                            controlId = "AutoCallConnectedSetting"
                            label = {langText.Body.AutoCallConnectedSetting}>
                            <InlineForm.static
                                key = "AutoCallConnectedPlay"
                                controlId = "AutoCallConnectedPlay"
                                label = {langText.Body.AutoCallConnectedPlay}>
                                {connected_sound 
                                    ? connected_sound.label 
                                    : langText.SelectOption.Placeholder_defaultSound}
                            </InlineForm.static>
                            <InlineForm.static
                                key = "AutoCallConnectedTimeOut"
                                controlId = "AutoCallConnectedTimeOut"
                                label = {langText.Body.AutoCallConnectedTimeOut}>
                                {state.connected_timeout}
                            </InlineForm.static>
                            <InlineForm.static
                                key = "AutoCallConnectedTimeOutPlay"
                                controlId = "AutoCallConnectedTimeOutPlay"
                                label = {langText.Body.AutoCallConnectedTimeOutPlay}>
                                {connected_timeout_sound
                                    ? connected_timeout_sound.label
                                    : langText.SelectOption.Placeholder_defaultSound}
                            </InlineForm.static>
                        </InlineForm.static>
                        {/* <InlineForm.static
                            className="padding-left-30"
                            key = "AutoCallReportMail"
                            controlId = "AutoCallReportMail"
                            label = {langText.Body.AutoCallReportMail}>
                            {
                                state.mailAddress && (
                                    <>
                                        <InlineForm.static
                                            key = "mailAddress"
                                            controlId = "mailAddress"
                                            label = {langText.Body.AutoCallMailTo}>
                                            {state.mailAddress}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "mailCc"
                                            controlId = "mailCc"
                                            label = {langText.Body.AutoCallMailCc}>
                                            {state.mailCc ? state.mailCc : langText.SelectOption.Unset}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "mailBcc"
                                            controlId = "mailBcc"
                                            label = {langText.Body.AutoCallMailBcc}>
                                            {state.mailBcc ? state.mailBcc : langText.SelectOption.Unset}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "AutoCallStart"
                                            controlId = "AutoCallStart"
                                            label = {langText.Body.AutoCallStart}>
                                            {state.start_flag ? langText.Body.SendOut : langText.Body.NotSendOut}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "AutoCallStop"
                                            controlId = "AutoCallStop"
                                            label = {langText.Body.AutoCallStop}>
                                            {state.stop_flag ? langText.Body.SendOut : langText.Body.NotSendOut}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "AutoCallLoopList"
                                            controlId = "AutoCallLoopList"
                                            label = {langText.Body.AutoCallLoopList}>
                                            {state.loop_flag ? langText.Body.SendOut : langText.Body.NotSendOut}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "AutoCall1HourSummary"
                                            controlId = "AutoCall1HourSummary"
                                            label = {langText.Body.AutoCall1HourSummary}>
                                            {state.hour_summary_flag ? langText.Body.SendOut : langText.Body.NotSendOut}
                                        </InlineForm.static>
                                    </>
                                )
                            }
                            {!state.mailAddress && langText.SelectOption.Unset}
                        </InlineForm.static>
                        <InlineForm.static
                            className="padding-left-30"
                            key = "AutoCallErrorMail"
                            controlId = "AutoCallErrorMail"
                            label = {langText.Body.AutoCallErrorMail}>
                            {
                                state.errorMailAddress && (
                                    <>
                                        <InlineForm.static
                                            key = "errorMailAddress"
                                            controlId = "errorMailAddress"
                                            label = {langText.Body.AutoCallMailTo}>
                                            {state.errorMailAddress}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "errorMailCc"
                                            controlId = "errorMailCc"
                                            label = {langText.Body.AutoCallMailCc}>
                                            {state.errorMailCc ? state.errorMailCc : langText.SelectOption.Unset}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "errorMailBcc"
                                            controlId = "errorMailBcc"
                                            label = {langText.Body.AutoCallMailBcc}>
                                            {state.errorMailBcc ? state.errorMailBcc : langText.SelectOption.Unset}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "AutoCallErrorStop"
                                            controlId = "AutoCallErrorStop"
                                            label = {langText.Body.AutoCallErrorStop}>
                                            {state.error_stop_flag ? langText.Body.SendOut : langText.Body.NotSendOut}
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "AutoCallConnectError"
                                            controlId = "AutoCallConnectError"
                                            label = {langText.Body.AutoCallConnectError}>
                                            {state.connect_error 
                                                ? langText.Body.AutoCall1Hour + state.connect_error + langText.Body.AutoCallLess
                                                : langText.Body.NotSendOut
                                            }
                                        </InlineForm.static>
                                        <InlineForm.static
                                            key = "AutoCallGiveUpError"
                                            controlId = "AutoCallGiveUpError"
                                            label = {langText.Body.AutoCallGiveUpError}>
                                            {state.giveup_error
                                                ? langText.Body.AutoCall1Hour + state.giveup_error + langText.Body.AutoCallUp
                                                : langText.Body.NotSendOut   
                                            }
                                        </InlineForm.static>
                                    </>
                                )   
                            }
                            {!state.errorMailAddress && langText.SelectOption.Unset}
                        </InlineForm.static> */}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        );

        return itemArr;
    }


    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {
                                this.props.state.modalType === "delete" 
                                ? this.props.langText.Body.DeleteTitle
                                : this.props.langText.Body.Read
                            }
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getPredictiveCallInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
