import React, { Component } from "react";
import AscComponent from "../../../components/AscComponent";
import { Row, Col, Modal, Form, Button, InputGroup, FormControl, ButtonGroup, Checkbox, Radio} from "react-bootstrap";
import "./Menu.css";
import InlineForm from "../../Elements/FromGroup/InlineForm";
import { Creatable } from "react-select";
import DatePicker from "react-datepicker";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import * as GlobalConst from "../../../components/AscConstants";

export default class Menu extends AscComponent {
    constructor(props) {
        super(props);
        
        this.state = {
        }
    }

    getSummaryBtn = () => {
        let {
            onSummary,
            langText,
            validationHandle
        } = this.props;

        return (
            <Button
                className="ok-execute-btn button-size"
                bsStyle="info"
                onClick={e => onSummary()}
                disabled={!validationHandle("summary")}>
                {langText.Body.Summary}
            </Button>
        );
    }

    getClearBtn = () => {
        let {
            onClear,
            langText
        } = this.props;

        return (
            <Button
                className="default-button button-size clear-button"
                onClick={e => onClear()}>
                {langText.Body.Clear}
            </Button>
        );
    }

    getCompanySelectInfo = () => {
        let {
            state,
            langText,
            getFilterData,
            getScopeGreaterEqual,
            onSelectChange,
            validationHandle
        } = this.props;

        let itemArr = [];

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
            itemArr.push(
                <InlineForm
                    key="company_id"
                    controlId="company_id"
                    className="padding-bottom-05"
                    label={langText.Body.CompanyName}
                    type="select"
                    onChange={e => onSelectChange(e, "cm12_id")}
                    options={state.company_select}
                    value={getFilterData("cm12_id") ? getFilterData("cm12_id").value : ""}
                    isClearable={true}
                    validationState={validationHandle("cm12_id")}
                    placeholder={langText.SelectOption.Placeholder_All_Select}
                />
            );
        }

        return itemArr;
    }

    getDepartmentSelectInfo = () => {
        let {
            state,
            langText,
            getFilterData,
            getScopeGreaterEqual,
            onSelectChange,
            validationHandle
        } = this.props;

        let itemArr = [];

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            itemArr.push(
                <InlineForm
                    key="department_id"
                    controlId="department_id"
                    className="padding-bottom-05"
                    label={langText.Body.DepartmentName}
                    type="select"
                    onChange={e => onSelectChange(e, "department_id")}
                    options={state.departmentSelect}
                    value={getFilterData("department_id") ? getFilterData("department_id").value : ""}
                    isClearable={true}
                    validationState={validationHandle("department_id")}
                    placeholder={langText.SelectOption.Placeholder_All_Select}
                />
            );
        }    
        if (this.props.state.floor_flg === "Y") {
            if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) {
                itemArr.push(
                    <InlineForm
                        key="floor_id"
                        controlId="floor_id"
                        className="padding-bottom-05"
                        label={langText.Body.FloorName}
                        type="select"
                        onChange={e => onSelectChange(e, "floor_id")}
                        options={state.floorSelect}
                        value={getFilterData("floor_id") ? getFilterData("floor_id").value : ""}
                        isClearable={true}
                        validationState={validationHandle("floor_id")}
                        placeholder={langText.SelectOption.Placeholder_All_Select}
                    />
                )
            }
        }
        return itemArr;
    }

    getConditionInfo = () => {
        let {
            state,
            onChange,
            getFilterData,
            langText,
            validationHandle,
            onKeyDownIntCheck,
            onSelectChange,
            onMultiSelectChange,
            onTimeChange,
            getScopeGreaterEqual
        } = this.props;
        let itemArr = [];

        // 集計期間
        itemArr.push(
            <InlineForm
                key="CountingPeriod"
                controlId="CountingPeriod"
                label={langText.Body.CountingPeriod}
            >
                <Col sm={12} md={12} className="padding-bottom-05 summary-period">
                    <InlineForm 
                        key="SummaryPeriod"
                        controlId="SummaryPeriod"
                        type="select"
                        value={state.summary_period_selected}
                        onChange={e => onSelectChange(e, "summary_period_selected")}
                        options={state.summary_period_select}
                        placeholder = {langText.SelectOption.Placeholder}
                        validationState={validationHandle("summary_period_selected")}
                    />                
                </Col>
                <Col sm={12} md={12} className="padding-bottom-05">
                    <Col sm={12} md={5} lg={5} className="padding-left-0 padding-right-0">
                        <DatePicker
                            onChange={e => onChange(e, "start_datetime", true)}
                            selected={getFilterData("start_datetime") ? getFilterData("start_datetime") : null}
                            dateFormat="YYYY-MM-DD"
                            className="form-control"
                            todayButton={false}
                            onKeyDown={e => {e.preventDefault()}}
                            placeholderText={langText.Body.StartDate}
                            maxDate={getFilterData("end_datetime") ? getFilterData("end_datetime") : null}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 swung">
                        <span>{langText.Body.FromTo}</span>
                    </Col>
                    <Col sm={12} md={5} lg={5} className="padding-left-0 padding-right-0">
                        <DatePicker
                            onChange={e => onChange(e, "end_datetime", true)}
                            selected={getFilterData("end_datetime") ? getFilterData("end_datetime") : null}
                            dateFormat="YYYY-MM-DD"
                            className="form-control"
                            todayButton={langText.Body.Today}
                            onKeyDown={e => {e.preventDefault()}}
                            placeholderText={langText.Body.EndDate}
                            minDate={getFilterData("start_datetime") ? getFilterData("start_datetime") : null}
                        />
                    </Col>
                </Col>
                <Col sm={12} md={12} className="padding-bottom-05 summary-period">
                    <InlineForm 
                        key="SummaryPeriodWeekday"
                        controlId="SummaryPeriodWeekday"
                        type="select"
                        value={getFilterData("weekday_selected")}
                        onChange={e => onMultiSelectChange(e, "weekday_selected")}
                        options={state.weekday_select}
                        isMulti={true}
                        placeholder={langText.SelectOption.Placeholder_Weekday_All_Select}
                        validationState={validationHandle("weekday_selected")}
                    />                
                </Col>
            </InlineForm>
        );

        // 通話時間帯
        itemArr.push(
            <InlineForm
                key="CallTimeZone"
                controlId="CallTimeZone"
                label={langText.Body.CallTimeZone}
                validationState={validationHandle("start_ji")}
            >
                <Col sm={12} md={12} className="call-time">
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="start_ji"
                            controlId="start_ji"
                            type="number"
                            onKeyDown={e => onKeyDownIntCheck(e)}
                            onChange={e => onTimeChange(e, "start_ji")}
                            value={getFilterData("start_ji")}
                            validationState={validationHandle("start_ji")}
                            placeholder={langText.Body.Hour}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>  
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="start_hun"
                            controlId="start_hun"
                            type="number"
                            onKeyDown={e => onKeyDownIntCheck(e)}
                            onChange={e => onTimeChange(e, "start_hun")}
                            value={getFilterData("start_hun")}
                            validationState={validationHandle("start_hun")}
                            placeholder={langText.Body.Minute}
                        />  
                    </Col>  
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 swung">
                        <span>{langText.Body.FromTo}</span>
                    </Col>  
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="end_ji"
                            controlId="end_ji"
                            type="number"
                            onKeyDown={e => onKeyDownIntCheck(e)}
                            onChange={e => onTimeChange(e, "end_ji")}
                            value={getFilterData("end_ji")}
                            validationState={validationHandle("end_ji")}
                            placeholder={langText.Body.Hour}
                        />
                    </Col>  
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>  
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="end_hun"
                            controlId="end_hun"
                            type="number"
                            onKeyDown={e => onKeyDownIntCheck(e)}
                            onChange={e => onTimeChange(e, "end_hun")}
                            value={getFilterData("end_hun")}
                            validationState={validationHandle("end_hun")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>    
                </Col>
            </InlineForm>
        );

        // オペレーター名
        itemArr.push(
            <InlineForm
                key="OperatorNameForm"
                controlId="OperatorNameForm"
                label={langText.Body.OperatorName}
            >
                <div className="condition">
                    <InlineForm 
                        key="OperatorName"
                        controlId="OperatorName"
                        type="select"
                        value={getFilterData("operator_selected_id")}
                        onChange={e => onMultiSelectChange(e, "operator_selected_id")}
                        options={state.operator_info_select}
                        isMulti={true}
                        placeholder={langText.SelectOption.Placeholder_All_Select}
                        validationState={validationHandle("operator_selected_id")}
                    />    
                </div>
            </InlineForm>
        );

        // 外線番号
        itemArr.push(
            <InlineForm
                key="ExternalNumberForm"
                controlId="ExternalNumberForm"
                label={langText.Body.ExternalNumber}
            >
                <div className="condition">
                    <InlineForm 
                        key="ExternalNumber"
                        controlId="ExternalNumber"
                        type="select"
                        value={getFilterData("external_number")}
                        onChange={e => onMultiSelectChange(e, "external_number")}
                        options={state.external_number_select}
                        isMulti={true}
                        placeholder={langText.SelectOption.Placeholder_All_Select}
                        validationState={validationHandle("external_number")}
                    />    
                </div>
            </InlineForm>
        );

        // 発着区分
        itemArr.push(
            <InlineForm
                key="InOutTypeForm"
                controlId="InOutTypeForm"
                label={langText.Body.InOutType}
            >
                <ButtonGroup className="analysis-btn padding-bottom-05 padding-left-15">
                    <Button
                        value="all"
                        onClick={e => onChange(e , "inout_type")}
                        className={state.filtered.map(row => row.id === "inout_type" && row.value === "all" ? "analysis-btn-focus":"")}
                    >
                        {langText.Body.All}
                    </Button>
                    <Button
                        value="in"
                        onClick={e => onChange(e , "inout_type")}
                        className={state.filtered.map(row => row.id === "inout_type" && row.value === "in" ? "analysis-btn-focus":"")}
                    >
                        {langText.Body.Inbound}
                    </Button>
                    <Button
                        value="out"
                        onClick={e => onChange(e , "inout_type")}
                        className={state.filtered.map(row => row.id === "inout_type" && row.value === "out" ? "analysis-btn-focus":"")}
                    >
                        {langText.Body.Call}
                    </Button>
                </ButtonGroup>
            </InlineForm>
        );

        // 通話結果
        itemArr.push(
            <InlineForm
                key="CallHistoryForm"
                controlId="CallHistoryForm"
                label={langText.Body.CallHistory}
            >
                <div className="condition">
                    <InlineForm 
                        key="CallHistory"
                        controlId="CallHistory"
                        type="select"
                        value={getFilterData("call_result")}
                        onChange={e => onMultiSelectChange(e, "call_result")}
                        options={state.call_result_select}
                        isMulti={true}
                        placeholder={langText.SelectOption.Placeholder_All_Select}
                        validationState={validationHandle("call_result")}
                    />    
                </div>
            </InlineForm>
        );

        // 応対メモ
        {/*
            第２フェーズ対応
            itemArr.push(
                <>
                    <InlineForm
                        key="SupportMemoForm"
                        controlId="SupportMemoForm"
                        label={langText.Body.SupportMemo}
                    >
                        <InlineForm 
                            key="SupportMemo"
                            controlId="SupportMemo"
                            type="select"
                            value={getFilterData("support_memo")}
                            onChange={e => onMultiSelectChange(e, "support_memo")}
                            options={state.support_memo_select}
                            isMulti={true}
                            placeholder={langText.SelectOption.Placeholder_All_Select}
                            validationState={validationHandle("support_memo")}
                        />    
                    </InlineForm>
                    <InlineForm
                        key="SupportCheck"
                        controlId="SupportCheck"
                    >
                        <Checkbox
                            key="AllSupport"
                            checked={getFilterData("all_support")}
                            onChange={e => onChange(e, "all_support")}
                            className="padding-left-15"
                        >
                            {langText.Body.AllInclude}
                        </Checkbox>
                    </InlineForm>
                </>
            );
        */}
        return itemArr;
    }
    
    // 詳細条件
    getDetailConditionInfo = () => {
        let {
            state,
            onChange,
            getFilterData,
            langText,
            onClickAccordion,
            validationHandle,
            onKeyDownIntCheck,
            onTimeChange,
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <Accordion allowZeroExpanded className="padding-left-10">
                <AccordionItem key="detailSettings" uuid="detailSettings">
                    <AccordionItemHeading>
                        <AccordionItemButton className="">
                            <div onClick={(e) => onClickAccordion(e)} className = "fa-11">
                                <span className="accordion-menu font-blue">{state.accordion_flag ? langText.Body.LessThan : langText.Body.Quotation}</span>
                                <b className="font-blue">{langText.Body.DetailCondition}</b>
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {/* 通話時間 */}
                        <InlineForm
                            key="TalkingTime"
                            controlId="TalkingTime"
                            label={langText.Body.TalkingTime}
                            validationState={validationHandle("call_start_hun")}
                        >
                            <div className="talk">
                                <Col sm={12} md={12} className="call-time">
                                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                                        <InlineForm
                                            key="call_start_hun"
                                            controlId="call_start_hun"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "call_start_hun")}
                                            value={getFilterData("call_start_hun")}
                                            validationState={validationHandle("call_start_hun")}
                                            placeholder={langText.Body.Minute}
                                        />
                                    </Col>
                                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 colon">
                                        <span>{langText.Body.Colon}</span>
                                    </Col>  
                                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                                        <InlineForm
                                            key="call_start_byo"
                                            controlId="call_start_byo"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "call_start_byo")}
                                            value={getFilterData("call_start_byo")}
                                            validationState={validationHandle("call_start_byo")}
                                            placeholder={langText.Body.Second}
                                        />  
                                    </Col>  
                                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 swung">
                                        <span>{langText.Body.FromTo}</span>
                                    </Col>  
                                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                                        <InlineForm
                                            key="call_end_hun"
                                            controlId="call_end_hun"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "call_end_hun")}
                                            value={getFilterData("call_end_hun")}
                                            validationState={validationHandle("call_end_hun")}
                                            placeholder={langText.Body.Minute}
                                        />
                                    </Col>  
                                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 colon">
                                        <span>{langText.Body.Colon}</span>
                                    </Col>  
                                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                                        <InlineForm
                                            key="call_end_byo"
                                            controlId="call_end_byo"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "call_end_byo")}
                                            value={getFilterData("call_end_byo")}
                                            validationState={validationHandle("call_end_byo")}
                                            placeholder={langText.Body.Second}
                                        />
                                    </Col>    
                                </Col>
                            </div>
                        </InlineForm>
                        {/* スコア */}
                        <InlineForm
                            key="Score"
                            controlId="Score"
                            label={langText.Body.Score}
                            validationState={validationHandle("min_score")}
                        >
                            <Col sm={12} md={12} className="call-time">
                                <Col sm={12} md={5} lg={5} className="padding-right-0">
                                    <InlineForm
                                        key="MinScore"
                                        controlId="MinScore"
                                        type="number"
                                        onKeyDown={e => onKeyDownIntCheck(e)}
                                        onChange={e => onTimeChange(e, "min_score")}
                                        value={getFilterData("min_score")}
                                        validationState={validationHandle("min_score")}
                                        placeholder={langText.Body.NoMinLimit}
                                    />  
                                </Col>  
                                <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 swung-2">
                                    <span>{langText.Body.FromTo}</span>
                                </Col>  
                                <Col sm={12} md={5} lg={5} className="padding-right-0">
                                    <InlineForm
                                        key="MaxScore"
                                        controlId="MaxScore"
                                        type="number"
                                        onKeyDown={e => onKeyDownIntCheck(e)}
                                        onChange={e => onTimeChange(e, "max_score")}
                                        value={getFilterData("max_score")}
                                        validationState={validationHandle("max_score")}
                                        placeholder={langText.Body.NoMaxLimit}
                                    />
                                </Col>
                            </Col>
                        </InlineForm>
                        {/* Talk 比率 */}
                        <div>
                            <InlineForm
                                key="TalkRate"
                                controlId="TalkRate"
                                label={langText.Body.TalkRate}
                                validationState={validationHandle("min_talk_rate")}
                            >
                                <Col sm={12} md={12} className="call-time">
                                    <Col sm={12} md={5} lg={5} className="padding-right-0">
                                        <InlineForm
                                            key="MinTalkRate"
                                            controlId="MinTalkRate"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "min_talk_rate")}
                                            value={getFilterData("min_talk_rate")}
                                            validationState={validationHandle("min_talk_rate")}
                                            placeholder={langText.Body.NoMinLimit}
                                        />  
                                    </Col>  
                                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 swung-2">
                                        <span>{langText.Body.FromTo}</span>
                                    </Col>  
                                    <Col sm={12} md={5} lg={5} className="padding-right-0">
                                        <InlineForm
                                            key="MaxTalkRate"
                                            controlId="MaxTalkRate"
                                            type="number"
                                            onKeyDown={e => onKeyDownIntCheck(e)}
                                            onChange={e => onTimeChange(e, "max_talk_rate")}
                                            value={getFilterData("max_talk_rate")}
                                            validationState={validationHandle("max_talk_rate")}
                                            placeholder={langText.Body.NoMaxLimit}
                                        />
                                    </Col>
                                </Col>
                            </InlineForm>
                        </div>
                        {/* 話速の差 */}
                        <InlineForm
                            key="TalkSpeedDifference"
                            controlId="TalkSpeedDifference"
                            label={langText.Body.TalkSpeedDifference}
                            validationState={validationHandle("min_speech_speed")}
                        >
                            <Col sm={12} md={12} className="call-time padding-bottom-05">
                                <Col sm={12} md={5} lg={5} className="padding-right-0">
                                    <InlineForm
                                        key="MinTalkSpeed"
                                        controlId="MinTalkSpeed"
                                        type="number"
                                        onKeyDown={e => onKeyDownIntCheck(e)}
                                        onChange={e => onTimeChange(e, "min_speech_speed")}
                                        value={getFilterData("min_speech_speed")}
                                        validationState={validationHandle("min_speech_speed")}
                                        placeholder={langText.Body.NoMinLimit}
                                    />  
                                </Col>  
                                <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 swung-2">
                                    <span>{langText.Body.FromTo}</span>
                                </Col>  
                                <Col sm={12} md={5} lg={5} className="padding-right-0">
                                    <InlineForm
                                        key="MaxTalkSpeed"
                                        controlId="MaxTalkSpeed"
                                        type="number"
                                        onKeyDown={e => onKeyDownIntCheck(e)}
                                        onChange={e => onTimeChange(e, "max_speech_speed")}
                                        value={getFilterData("max_speech_speed")}
                                        validationState={validationHandle("max_speech_speed")}
                                        placeholder={langText.Body.NoMaxLimit}
                                    />
                                </Col>
                            </Col>
                        </InlineForm>
                        {/* コメント */}
                        {/* 
                            第2フェーズ対応
                            <InlineForm
                                key="CommentForm"
                                controlId="CommentForm"
                                label={langText.Body.Comment}
                            >
                                <div className="comment">
                                    <InlineForm
                                        key="Comment"
                                        controlId="Comment"
                                        type="text"
                                        value={getFilterData("comment")}
                                        onChange={e => onChange(e, "comment")}
                                        placeholder={langText.SelectOption.Placeholder_Comment_Search}
                                    />
                                </div>
                            </InlineForm>
                        */}
                        {/* キーワード */}
                        {/* 
                            第2フェーズ対応
                        <InlineForm
                            key="KeywordFrom"
                            controlId="KeywordForm"
                            label={langText.Body.Keyword}
                        >
                            <div className="keyword">
                                <Creatable
                                    isMulti
                                    isClearable
                                    value={getFilterData("keyword")}
                                    placeholder={langText.SelectOption.Placeholder_Keyword_Search}
                                    onChange = {e => onChange(e, "keyword")}
                                    noOptionsMessage={() => langText.SelectOption.Placeholder_Keyword_Search}
                                    formatCreateLabel={(inputText) => `${inputText} ${langText.Message.Creating}`}
                                />
                            </div>
                        </InlineForm>
                        <InlineForm
                            key="KeywordCheck"
                            controlId="KeywordCheck"
                        >
                            <Checkbox
                                key="AllKeyword"
                                checked={getFilterData("all_keyword")}
                                onChange={e => onChange(e, "all_keyword")}
                                className="padding-left-15"
                            >
                                {langText.Body.AllInclude}
                            </Checkbox>
                        </InlineForm>
                        */}
                        {/* 集計方法 */}
                        <InlineForm
                            key="SummaryMethod"
                            controlId="SummaryMethod"
                            label={langText.Body.SummaryMethod}
                        >
                            <Col xs={12} md={12} className="padding-left-0 font-size-085 margin-top--05">
                                <Col xs={12} md={4} lg={4}>
                                    <Radio
                                        name="AverageValue"
                                        key="AverageValue"
                                        onChange={e => onChange(e, "summary_method")}
                                        checked={getFilterData("summary_method") === "average" ? true : false}
                                        value="average"
                                    >
                                        {langText.Body.AverageValue}
                                    </Radio>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <Radio
                                        name="MedianValue"
                                        key="MedianValue"
                                        onChange={e => onChange(e, "summary_method")}
                                        checked={getFilterData("summary_method") === "median" ? true : false}
                                        value="median"
                                    >
                                        {langText.Body.MedianValue}
                                    </Radio>
                                </Col>
                            </Col>
                        </InlineForm>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        );

        return itemArr;
    }

    render() {
        return (
            <Row className="menu-row">
                <Col className="title-col" xs={12} sm={12}>
                    <div className="font-weight-bold">
                        {this.props.langText.Body.SummaryCondition}
                    </div>
                    <div className="margin-left-05 margin-top-05">
                        {this.getClearBtn()}
                        {this.getSummaryBtn()}
                    </div>
                    <div>
                        {this.getCompanySelectInfo()}
                        {this.getDepartmentSelectInfo()}
                        <hr className="menu-hr"/>
                    </div>
                </Col>
                <Col className="content-col" xs={12} sm={12}>
                    {this.getConditionInfo()}
                    {this.getDetailConditionInfo()}
                </Col>
            </Row>
        );
    }
}
