import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import DepartmentModal from "./DepartmentModal";
import * as GlobalConst from "../../components/AscConstants";

export default class Department extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            pbxSelect: [],
            companySelect: [],

            // data item
            departmentId: "",
            departmentName: "",
            companyId: "",
            companyName: "",
            prefix: "",
            pbx: "",
            memo: "",
            user_cnt: 0,
            // button_disabled
            buttondisabledArr: {
                "companyId" : [],
                "pbx" : [],
                "pbx_load": []
            },
        };
    }

    getColumnsData() {
        let columnArr = [];

        columnArr.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            filterable: false,
            width: this.props.boardWidth.id,
        });

        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            columnArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm12_companies.company_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")} />
            });
        }

        columnArr.push({
            Header: this.props.langText.Body.DepartmentName,
            accessor: "department_name",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "department_name")} />
        });

        columnArr.push({
            Header: this.props.langText.Body.Prefix,
            accessor: "department_prefix",
            width: this.props.boardWidth.medium,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "department_prefix")} />
        });

        if (!this.props.userInfo.floor_flg
            || (this.props.currentPermission.scope_code === GlobalConst.SCOPE_OBJECT.system.name
                && this.props.systemParameters.SYSTEM_FLOOR_FLG === "N")) {
            columnArr.push({
                Header: this.props.langText.Body.Pbx,
                accessor: "cm92_pbx_infos.pbx_name",
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm92_pbx_infos.pbx_name")} />
            });
        }
        columnArr.push({
            Header: this.props.langText.Body.VoiceLogPassword,
            accessor: "call_record_password",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "call_record_password")} />
        });
        columnArr.push({
            Header: this.props.langText.Body.Memo,
            accessor: "memo",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
        });

        columnArr.push({
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];
                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize="xsmall"
                                onClick={event => this.modalShow('update', data.row._original, event)}
                                className='control-button'
                            >
                                <Glyphicon glyph='pencil'/>
                            </Button>
                        );
                    } else {
                        rowData.push(
                            <Button
                                key={1}
                                bsSize="xsmall"
                                onClick={event => this.modalShow('read', data.row._original, event)}
                                className='control-button'
                            >
                                <Glyphicon glyph='eye-open'/>
                            </Button>
                        );
                    }
                    // 削除ボタンは権限20まで表示
                    if(this.props.currentPermission.delete)
                    {
                        rowData.push(
                            <Button
                                key={2}
                                bsSize="xsmall"
                                onClick={event => this.modalShow('delete', data.row._original, event)}
                                className='control-button'
                            >
                                <Glyphicon glyph='minus'/>
                            </Button>
                        );
                    }

                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            });

        return columnArr;
    }

    // 画面表示タイミング共通処理_変数初期化
    modalshow_commonInit() {
        this.setState({
            user_cnt: 0,
            buttondisabledArr: {
                "companyId" : [],
                "pbx" : [],
                "pbx_load": []
            }
        });
    };
    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    async modalShow(modalType, param, event){
        try {
            event.preventDefault();
            this.modalshow_commonInit();
    
            let user_cnt = 0;
            if(modalType == "delete"){
                user_cnt = await this.getUserCntByDepartment(param.id);
            }
            
            let setData = {
                departmentId: param && param.id
                    ? param.id
                    : "",
                departmentName: param && param.department_name
                    ? param.department_name
                    : "",
                companyId: param && param.cm12_companies && param.cm12_companies.id
                    ? param.cm12_companies.id
                    : "",
                companyName: param && param.cm12_companies && param.cm12_companies.company_name
                    ? param.cm12_companies.company_name
                    : "",
                floor_flg: param && param.cm12_companies && param.cm12_companies.floor_flg
                    ? param.cm12_companies.floor_flg === "Y"
                    : null,
                prefix: param && param.department_prefix
                    ? param.department_prefix
                    : "",
                pbx: param && param.cm92_pbx_infos && param.cm92_pbx_infos.id
                    ? param.cm92_pbx_infos.id
                    : "",
                call_record_password: param && param.call_record_password
                    ? param.call_record_password
                    : "",
                memo: param && param.memo
                    ? param.memo
                    : "",
                audio_record_password: "",
                user_cnt: user_cnt || 0,
                modalType,
                show: true
            };
    
            if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
                setData.companyId = this.props.userInfo.company_id;
                setData.companyName = this.props.userInfo.company_name;
                setData.floor_flg = this.props.userInfo.floor_flg;
            }
    
            this.getCompanySelect();
    
            if (setData.companyId) {
                this.getPbxSelect({companyId: setData.companyId});
            }
    
            this.setState(setData);
            
        } catch (err) {
            alert(this.getErrorString(err.response.data));
            return false;
        }
    };

    onClickHandle = modalType => event => {
        let {
            departmentId,
            companyId,
            departmentName,
            prefix,
            pbx,
            memo,
            audio_record_password,
            floor_flg
        } = this.state;

        switch (modalType) {
            case "insert":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/insert`, {
                        departmentId,
                        companyId,
                        departmentName,
                        prefix,
                        pbx,
                        memo,
                        audio_record_password,
                        floor_flg
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                        this.setState({
                            show: false
                        });
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "update":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/update`, {
                        departmentId,
                        companyId,
                        departmentName,
                        pbx,
                        memo,
                        audio_record_password,
                        floor_flg
                    })
                    .then(result => {
                        if (result.data && result.data[0]) {
                            alert(this.props.langText.Message.DataUpdateSuccess);
                        } else {
                            alert(this.props.langText.Message.DataUpdateError);
                        }
                        this.reactTableRefresh();
                        this.setState({
                            show: false
                        });
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "delete":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/delete`, {
                        departmentId,
                        companyId,
                        departmentName,
                        prefix,
                        pbx,
                        memo,
                        floor_flg
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                        this.setState({
                            show: false
                        });
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            default:
                alert(this.getErrorString({code: 'modal'}));
        }
    };

    onSelectChange (event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        let floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : false;

        if (param === "companyId") {
            this.getPbxSelect({companyId: value});
            this.setState({
                pbx : "",
                floor_flg: floor_flg,
            });
        }
    }

    getCompanySelect = () => {
        this
            .ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(result => {
                this.common_buttondisabledLengthCheck(`companyId`,`push`);
                this.setState({
                    companySelect: result.data
                });
                this.common_buttondisabledLengthCheck(`pbx_load`,`push`);
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };

    getPbxSelect = ({companyId}) => {
        this
            .ascAxios('post', `Common/pbxSelect`, {
                companyId,
                container: this.reactContainerPath
            })
            .then(result => {
                if(this.state.companyId)
                {
                    this.common_buttondisabledLengthCheck(`pbx`,`push`);
                    this.common_buttondisabledLengthCheck(`pbx_load`,`pop`);
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`pbx`,`pop`);
                }
                this.setState({
                    pbxSelect: result.data
                });
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    }

    validationHandle = param => {
        let nowState = null;

        const {
            companyId, 
            departmentName, 
            pbx, 
            prefix,
            audio_record_password,
            floor_flg
        } = this.state;

        switch (param) {
            case "companyId":
            case "departmentName":
            case "pbx":
                nowState = this.state[param]
                    ? null
                    : "error";
                break;

            case "prefix":
                if (this.state[param] && ((floor_flg && this.state[param].length === 2)
                    || (!floor_flg && this.state[param].length === 3))) {
                    nowState = null
                } else {
                    nowState = "error";
                }
                break;

            case "audio_record_password":
                nowState = !this.state[param] || this.state[param].length === 4
                    ? null
                    : "error";
                break;

            case "insert":
                let prefix_check = (prefix 
                    && ((floor_flg && prefix.length === 2)
                    || (!floor_flg && prefix.length === 3)));

                nowState = (
                    companyId && departmentName && (pbx || floor_flg) && prefix_check && (!audio_record_password || audio_record_password.length === 4)
                );
                break;

            case "update":
                let password_check = true;

                if (audio_record_password.length!=0) {
                    password_check = (audio_record_password.length == 4);
                }
            
                nowState = (
                    companyId && departmentName && (pbx || floor_flg) && password_check
                );
                break;

            case "delete":
                nowState = true;
                break;

            default:
        }

        return nowState;
    };

    render() {
        return (
            <div className="Department">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="department-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={ event => this.modalShow("insert", null, event)}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <DepartmentModal
                    floor_flg={this.state.floor_flg}
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    onKeyDownIntCheck = {this.onKeyDownIntCheck}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    getScopeGreaterEqual = {this.getScopeGreaterEqual}
                    validationHandle = {this.validationHandle}
                    sprintf = {this.sprintf}/>
            </div>
        );
    }
}