import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";

export default class TotalCallLineChart extends AscComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            series: [{
                data: []
            }],
            options: {
                chart: {
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    background: '#fff'
                },
                colors: ['#A5DF00'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight',
                    width: 1
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                    tickAmount: 4,
                    labels: {
                        rotate: 0
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    tickAmount: 4
                },
                markers: {
                    size: 3,
                    colors: ['#A5DF00'],
                    hover: {
                        size: 5
                    }
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return `
                            <div class="total-call-tooltip">
                                <div>${w.globals.categoryLabels[dataPointIndex]}</div>
                                <div>${series[seriesIndex][dataPointIndex]}回</div>
                            </div>
                        `;
                    }
                }
            },
            total_billsec: 0,
            daily_billsec: 0,
            total_call_count: 0,
            daily_call_count: 0,
            method: "average"
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.state.total_call_view_level !== this.props.state.total_call_view_level ||
            prevProps.data !== this.props.data    
        ) {
            this.onChartDataChange(this.props.state.total_call_view_level);
        }
    }

    onChartDataChange = (level) => {
        let {
            data,
            langText,
            secToTime,
            hiddenFiltered
        } = this.props;
        let category = [];
        let series_data = [];
        let series_name = level === "count" ? langText.Body.CallCount : langText.Body.TalkingTime;
        let unit = level === "count" ? langText.Body.TimeCount : "";
        let method = hiddenFiltered.find(row => row.id === "summary_method");

        data.buckets.forEach(row => {
            category.push(row.key_as_string);
            if (level === "count") series_data.push(row.total_call_count.value);
            else series_data.push(row.total_billsec.value);
        });

        this.setState({
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: category,
                    tickAmount: 4
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return `
                            <div class="total-call-tooltip">
                                <div>${w.globals.categoryLabels[dataPointIndex]}</div>
                                <div>${unit === langText.Body.TimeCount ? series[seriesIndex][dataPointIndex] : secToTime(series[seriesIndex][dataPointIndex])}${unit}</div>
                            </div>
                        `;
                    }
                }
            },
            series:[{
                ...this.state.series,
                name: series_name,
                data: series_data
            }],
            total_billsec: data.search_total_billsec,
            daily_billsec: data.search_daily_billsec,
            total_call_count: data.search_total_call_count.toLocaleString(),
            daily_call_count: data.search_daily_call_count.toLocaleString(),
            method: method.value
        });
    }



    render() {
        return (
            <div className="total-call-line-chart">
                <Col sm={12} md={12} className="line-chart-data">
                    <Col sm={6} md={6} className="font-weight-bold fa-12 font-color-blue">
                        <span>{
                            this.props.state.total_call_view_level === "count" ?
                            this.state.total_call_count :
                            this.props.secToTime(this.state.total_billsec)
                        }{
                            this.props.state.total_call_view_level === "count" ?
                            this.props.langText.Body.TimeCount :
                            ""
                        }</span>     
                    </Col>
                    <Col sm={6} md={6} className="text-align">
                        <span className="margin-right-05">{
                            this.state.method === "average" ?
                            this.props.langText.Body.DayAvg :
                            this.props.langText.Body.DayMed
                        }</span>
                        <span className="font-color-blue">{
                            this.props.state.total_call_view_level === "count" ?
                            this.state.daily_call_count :
                            this.props.secToTime(this.state.daily_billsec)    
                        }{
                            this.props.state.total_call_view_level === "count" ?
                            this.props.langText.Body.TimeCount :
                            ""
                        }</span>
                    </Col>
                </Col>
                <Chart options={this.state.options} series={this.state.series} type="line" height={350} />
            </div>
        );
    }
}
