import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import SetDataWithSpeechBubble from "../../Elements/AscElements/SetDataWithSpeechBubble";
import * as faIcon from "@fortawesome/free-solid-svg-icons";

export default class DirectionRadarChart extends AscComponent {
    constructor(props) {
        super(props);
    
        this.state = {
            series: [
                {
                    data: [100, 100, 100, 100, 100],
                    color: '#2E9AFE'
                }
            ],
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: ['通話時間', '沈黙回数', 'Talk 比率', '被り回数', '話速の差'],
                    labels: {
                        style: {
                            colors: ['#000', '#000', '#000', '#000', '#000'],
                            fontSize: '13px'
                        }
                    }
                },
                markers: {
                    size: [0],
                },
                yaxis: {
                    show: false,
                    min: 0,
                    max: 200
                },
                legend: {
                    show: false
                },
                tooltip: {
                    y: {
                        title: {
                            formatter: function() {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                },
                fill: {
                    opacity: 0.1
                }
            },
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.data !== this.props.data    
        ) {
            this.onChartDataChange();
        }
    }

    onChartDataChange = () => {
        const {
            data
        } = this.props;

        let default_series = [{
            data: [100, 100, 100, 100, 100],
            color: '#2E9AFE'
        }];
        let dataArr = [];
        let nameArr = [];
        let max = 200;
        let fill = {}

        data.forEach(row => {
            if (max < row.value) max = row.value;
            dataArr.push(row.value);
        });

        if (dataArr.length > 0) {
            default_series[0].color = '#BDBDBD';
            fill = {
                opacity: [0, 0.1]
            }
        }

        this.setState({
            options: {
                ...this.state.options,
                markers: {
                    ...this.state.options.markers,
                    size: [0, 2]
                },
                yaxis: {
                    ...this.state.options.yaxis,
                    max
                },
                fill: {
                    ...this.state.options.fill,
                    ...fill
                }
            },
            series: [
                ...default_series,
                {
                    data: [...dataArr],
                    color: '#2E9AFE'
                }
            ],
        });
    }

    render() {
        return (
            <div className="common-line-chart padding-left-1em">
                <div>
                    <div className="common-line-chart-title">
                        <SetDataWithSpeechBubble
                            displayData = {this.props.title}
                            speechBubbleData = {this.props.description}
                            infoIconDisplayFlag = {true}
                            infoIconType = {faIcon.faInfoCircle}
                            infoIconClassName = "info-icon"
                            infoIconColor = "silver"
                            infoIconSize = "lg"
                            custom="custom"
                        />
                    </div>
                </div>
                <Chart options={this.state.options} series={this.state.series} type="radar" height={this.props.height}/>
            </div>
        );
    }
}
