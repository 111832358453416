import React from "react";
import {Button, Row, Col, FormControl, ButtonGroup, Glyphicon} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment-timezone";
import Select from "react-select";
import { CSVLink } from "react-csv";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as GlobalConst from "../../components/AscConstants";
import CallAnalysisModal from './CallAnalysisModal';
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import {Creatable} from 'react-select';


export default class CallAnalysis extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            // columns: this.getColumnsData(),
            data: [],
            pages: null,
            count: null,
            loading: false,
            filtered: [
                {
                    id: "inout_type",
                    value: "all"
                },
                {
                    id: "check_flag",
                    value: "all"
                },
                {
                    id: "cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg
                    }
                },
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "end_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "start_ji",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                }
            ],
            hidden_filtered: [
                {
                    id: "inout_type",
                    value: "all"
                },
                {
                    id: "check_flag",
                    value: "all"
                },
                {
                    id: "cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg
                    }
                },
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "end_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "start_ji",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                }
            ],
            cm12_id: this.props.userInfo.company_id,
            companySelect: [],
            externalArr: [],
            insert_comment: "",
            comment_edit_flag: [],
            voice_edit_flag: [],

            // modal item
            show: false,
            modalType: null,
            csvdata: [],
            blocking: false,
            disableFlg: false
        };

        this.hourShowList = this.getSelectOption('hour_show_list', this.props.langText.SelectOption);
        this.minuteShowList = this.getSelectOption('minute_show_list', this.props.langText.SelectOption);

        this.table = React.createRef();
        this.csvLink = React.createRef();

        // 会社選択肢セット
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            let {filtered, hidden_filtered} = this.state;
            
            this.getCommonCompanySelect(this.reactContainerPath).then(res => {
                let cm12_id_idx = 0;
                if (res && res.data && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
                    cm12_id_idx = res.data.findIndex(row => {
                        return row.value === this.props.userInfo.company_id
                    });
                }
                filtered[2].value = {
                    value: (res.data && res.data[cm12_id_idx].value) || 0, 
                    label:(res.data && res.data[cm12_id_idx].label) || "", 
                    floor_flg: (res.data && res.data[cm12_id_idx].floor_flg) || ""
                }
                hidden_filtered[2].value = {
                    value: (res.data && res.data[cm12_id_idx].value) || 0, 
                    label:(res.data && res.data[cm12_id_idx].label) || "", 
                    floor_flg: (res.data && res.data[cm12_id_idx].floor_flg) || ""
                }
                this.setState({
                    companySelect: res.data,
                    cm12_id: (res.data && res.data[cm12_id_idx].value) || 0,
                    filtered,
                    hidden_filtered
                })
            }).catch(err => {
                this.showErrorObjectMesssage(err, "DataSelectError");
            });
        }
    }

    async componentDidMount() {
        // 外線リストセット
        if (this.props.isAuthenticated) {
            await this.getExternalList(this.state.cm12_id);
            if (this.state.externalArr.length > 0) this.onSearch(this.state.filtered);
        }
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    getColumnsData() {
        let columns = [];
        //内線番号
        columns.push({
            Header: this.props.langText.Body.ExtensionNumber,
            sortable: false,
            accessor: "extension_number",
        });
        //発着区分
        columns.push({
            Header: this.props.langText.Body.InOutType,
            sortable: false,
            accessor: "inout_type",
            width: this.props.boardWidth.small,
        });
        // //外線番号
        columns.push({
            Header: this.props.langText.Body.ExternalNumber,
            sortable: false,
            accessor: "display_number",
            Cell: data => {
                let returnData = "";
                if(data && data.row && data.row._original) {
                    returnData =
                        data.row._original.display_number +
                        (data.row._original.external_number_memo? "(" +data.row._original.external_number_memo +")": "")
                }
                return returnData;
            }
        });
        //相手先番号
        columns.push({
            Header: this.props.langText.Body.DestUserNumber,
            sortable: false,
            accessor: "dest_tel_no",
        });
        //開始時間
        columns.push({
            Header: this.props.langText.Body.StartTime,
            accessor: "start_datetime",
            width: this.props.boardWidth.xxlarge,
            Cell: data => {
                return data.value ? this.getMomentTime({date: data.value, format: "YYYY/MM/DD HH:mm:ss"}) : "";
            }
        });
        //通話時間
        columns.push( {
            Header: this.props.langText.Body.TalkingTime,
            sortable: false,
            accessor: "billsec",
            width: this.props.boardWidth.smedium,
            Cell: data => {
                return this.secToTime(data.value || 0);
            }
        });
        //キーワードヒット回数
        if(this.state.keyword_flag){
            columns.push({
                Header: this.props.langText.Body.KeywordCount,
                accessor: "keyword_count",
                width: this.props.boardWidth.xsmall
            });
        }
        //確認
        columns.push( {
            Header: this.props.langText.Body.Check,
            sortable: false,
            accessor: "flag_data_json",
            width: this.props.boardWidth.xsmall,
            Cell: data => {
                if (data.value && data.value.check_flag === "Y") {
                    return "〇";
                } else {
                    return "×";
                }
            }
        });
        //ダウンロード
        columns.push( {
            Header: this.props.langText.Body.Download,
            sortable: false,
            accessor: "flag_data_json",
            width: this.props.boardWidth.small,
            Cell: data => {
                if (data.value && data.value.download_flag === "Y") {
                    return "〇";
                } else {
                    return "×";
                }
            }
        });

        columns.push({
            Header: this.props.langText.Body.Control,
            width: this.props.boardWidth.xsmall,
            sortable: false,
            Cell: data => {
                    return(
                        <Row className="text-center">
                            <Button
                                key = {1}
                                bsSize="xsmall"
                                onClick={this.modalShow(data.row._original.id)}
                                className="control-button"
                            >
                                <Glyphicon glyph="eye-open"/>
                            </Button>
                        </Row>
                    )
                }
        })

        return columns;
    }

    getExternalList = async(cm12_id) => {
        try {
            let result = await this.ascAxios('post', `${this.reactContainerPath}/getExternalList`, {cm12_id});
            this.setState({externalArr: result.data}, () => {
                let filtered = this.state.filtered;
                let target_index = filtered.findIndex(row => row.id === "display_number");
                if (target_index !== -1) filtered.splice(target_index, 1);

                if (this.state.externalArr.length > 0) {
                    filtered.push({
                        id: "display_number",
                        value: this.state.externalArr[0]
                    });
    
                    this.setState({
                        disableFlg: false,
                        filtered: filtered,
                        hidden_filtered: filtered
                    });
                } else {
                    clearInterval(this.state.interval);
                    this.setState({
                        searchValidation: true,
                        disableFlg: true,
                        filtered: filtered,
                        hidden_filtered: filtered
                    });
                }

                this.getValidationCheck();
            });
        } catch (err) {
            return this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    getFilterData(param) {
        let my_param_data = this.state.filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    getKeywordData(param) {
        let my_param_data = this.state.hidden_filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    onChange = (event, param) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";
        // セレクトボックス用。event無い場合はvalue空のまま
        if(event)
        {
            value = event.target ? event.target.value : event;
        }
        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value
            });
        }

        if (param === "keyword") {
            const keyword_index = my_filtered.findIndex(row => row.id === param);
            if (keyword_index !== -1) {
                my_filtered[keyword_index].value =
                    event.length === 0
                        ? []
                        : my_filtered[keyword_index].value.map((keyword) => keyword.value);
            }
            this.setState({keywords: event})
        }
        // 会社選択
        if (param === "cm12_id" && event && event.value) {
            this.getExternalList(event.value);
        } else if (param === "cm12_id") {
            // クリアした時
            this.setState({
                externalArr: [],
            })
        } else if (param === "autoUpdateInterval") {
            this.setState({
                autoUpdateInterval: event
            });
        }

        let returnValue = [];
        returnValue = my_filtered;

        this.setState({
            filtered: returnValue
        });

        this.getValidationCheck();
    }

    //会社や集計期間未選択時、検索できないようにする
    getValidationCheck = () =>{
        let message = "";

        if (!this.getFilterData("start_datetime") || !this.getFilterData("end_datetime")){
            message = this.props.langText.Message.CallAnalysis_FindDataError;
            this.setState({searchValidation : true, message});
        } else if(moment.duration(moment(this.getFilterData("end_datetime")).diff(moment(this.getFilterData("start_datetime")))).asDays()<0){
            message = this.props.langText.Message.CallHistory_DayTermError;
            this.setState({searchValidation : true, message});
        } else if (!this.getFilterData("cm12_id")) {
            this.setState({searchValidation : true});
        } else if (!this.getFilterData("display_number")){
            message = this.props.langText.Message.ExtenalNumberSelectedError;
            this.setState({searchValidation: true, message});
        } else if (!this.setTimeCheck()) {
            message = this.props.langText.Message.StartDateTimeCheckError;
            this.setState({searchValidation : true, message});
        } else {
            this.setState({searchValidation: false, message: ""});
        }
    }

    setTimeCheck = () => {
        let flag = true;
        let start_datetime = this.getFilterData("start_datetime"),
            end_datetime = this.getFilterData("end_datetime"),
            start_ji = this.getFilterData("start_ji").value,
            start_hun = this.getFilterData("start_hun").value,
            end_ji = this.getFilterData("end_ji").value,
            end_hun = this.getFilterData("end_hun").value;
        
        if(start_datetime && end_datetime && start_datetime.format("YYYYMMDD") === end_datetime.format("YYYYMMDD")){
            if (start_ji > end_ji) {
                flag = false;
            } else if (start_ji === end_ji && (start_hun > end_hun)) {
                flag = false;
            }
        }
        
        return flag;
    }

    /**
     * OpenSearchへの検索
     * @param {Object} filter
     */
    onSearch = async(filter, download_search_flag) => {
        try {
            const cm12Id = filter.filter(data => data.id === 'cm12_id')[0].value.value;
            // キーワードでソートするとタイムアウトが発生する場合があるため、検索時は開始時間でソートする
            if(!download_search_flag){
                this.table.current.state.sorted[0] = {id: 'start_datetime', desc: false};
            }
            // 外線番号が選択されてないと検索できない
            if (!this.getFilterData("display_number")) throw new Error();
            this.table.current.state.page = 0;
            await this.execFullTextSearch(
                cm12Id,
                filter,
                this.formatForTableDisplay
            );

            // 検索時のデータ保存
            let hidden_filter = [];
            filter.forEach((row, index) => {
                hidden_filter.push(row);
            });

            this.setState({ 
                keyword_flag: !!(this.getFilterData("keyword")),
                hidden_filtered: hidden_filter
            });
        } catch (err) {
            return this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    /**
     * 全文検索結果をグリッド表示形式に整形する
     * @param {Object} fullTextSearchData
     * @return {Object | boolean}
     */
    formatForTableDisplay = (fullTextSearchData) => {
        const tableDisplayData = [];
        fullTextSearchData.forEach(row => {
            const keywordHitCount = row.fields && row.fields.phrase_count;
            tableDisplayData.push (
                {
                    id: row._id,
                    extension_number: row._source.extension_number,
                    extension_number_memo: row._source.extension_number_memo,
                    inout_type: row._source.inout_type,
                    display_number: row._source.display_number,
                    external_number_memo: row._source.external_number_memo,
                    dest_tel_no: row._source.dest_tel_no,
                    start_datetime: row._source.start_datetime,
                    billsec: row._source.billsec,
                    flag_data_json: row._source.flag_data_json,
                    keyword_count: keywordHitCount || 0,
                }
            );
        });

        return tableDisplayData;
    }

    CallAnalysisExcelDownload = async () => {
        try {
            const maxRows = 10000;
            let download_search_flag = true
            // 画面表示用の検索を実行
            await this.onSearch(this.state.filtered, download_search_flag);

            const error = new Error();

            if (this.state.timeout) {
                error.message = "CallAnalysisTimeOut";
                return error;
            }
            if (this.state.data.length <= 0) {
                error.message = "Download_Failed";
                return error;
            }

            if (this.state.count > maxRows) {
                error.message = "TooManyResultsError";
                error.args = [this.state.count, maxRows];
                return error;
            }

            this.setState({downloadWaitTime: 5});
            const downloadFileName =
                this.props.userInfo.cm15_id +
                "_" +
                this.props.langText.Body.VoiceQualityAnalysis +
                "_" +
                moment().utc().add(9, "h").format("YYYYMMDDHHmmss");
            let searchKeyWords = this.state.filtered.filter(data => data.id === "keyword");
            searchKeyWords =
                searchKeyWords.length === 0
                    ? []
                    : searchKeyWords[0].value.map((keyword) => keyword);
            let sort = this.table.current && this.table.current.state && this.table.current.state.sorted[0]
                ? this.table.current.state.sorted[0]
                : null;
            const lambdaParams = {
                conditions: this.state.filtered,
                downloadFileName,
                searchKeyWords,
                userEmail: this.props.userInfo.user_id,
                sort
            };

            //5秒ごとに実行
            this.toggleBlocking();
            const intervalId = setInterval(() => {
                this.createDownloadFile(
                    lambdaParams,
                    intervalId
                );
            }, 5000);
        } catch (e) {
            this.showErrorObjectMesssage(e);
        }
    }

    /**
     * ダウンロードファイル作成
     * @param {Object} lambdaParams
     * @param {Integer} intervalId
     */
    async createDownloadFile (lambdaParams, intervalId) {
        const intervalSecond = 5;
        const maxWaitTime = 330;

        try {
            const res = await this.ascAxios("post", `/Download/voiceQualityAnalysis`, {
                lambdaParams
            });

            switch (res.data.download_status) {
                case "success":
                    clearInterval(intervalId);
                    this.toggleBlocking();
                    const downLoadLink = document.createElement("a");
                    downLoadLink.href = res.data.url;
                    downLoadLink.click();
                    downLoadLink.remove();
                    break;
                case "lock_exist":
                case "converting":
                    // 作成開始または作成中のため処理を行わない
                    break;
                default:
                    //サーバーから結果がエラーの場合
                    throw new Error("CreateFileFailed");
            }

            //タイムアウト処理
            this.setState({downloadWaitTime: this.state.downloadWaitTime + intervalSecond});
            if (this.state.downloadWaitTime > maxWaitTime) {
                throw new Error("CreateFileTimeOut");
            }
        } catch (err) {
            clearInterval(intervalId);
            this.toggleBlocking();
            this.showErrorObjectMesssage(err);
        }
    }
    
    //モーダル開くときにモーダル内の情報取得
    getModalInfo(id) {
        return this.ascAxios('post', `${this.reactContainerPath}/modal`, { id });
    }

    /**
     * ヒットしたキーワード数取得
     * 
     * @param {Array} keywords
     * @param {Object} textData
     * @return {Integer}
     */
    getKeywordCount (keywords, textData) {
        let keywordHitCount = 0;
        // キーワードヒット回数を取得
        if(keywords && keywords[0]){
            keywords[0].forEach((keyword) => {
                let hitCount = 0;
                textData.forEach((text) => {
                    if (text.transcript) {
                        const splitArr = text.transcript.split(keyword);
                        hitCount = splitArr.length - 1;
                    }
                    keywordHitCount += hitCount;
                });
            });
        }

        return keywordHitCount;
    }

/**
     * del_flagがNのコメント情報取得
     * 
     * @param {Array} comments
     * @return {(Array|null)} 
     */
    getCommentData(comments) {
        let existComments = comments.filter(
            (comment) => comment.del_flag === "N"
        );

        if (existComments.length === 0) {
            existComments = null;
        }

        return existComments;
    }

    modalShow = (id) => async event => {
        event.preventDefault();
        try {
            let modalType = "analysis";
            let selected = [];
            let keywordHitCount = 0;
            let keywords = this.state.hidden_filtered.filter(
                (data) => data.id === "keyword"
            );
            keywords = keywords.map(keyword => keyword.value);

            if(id){
                let res = await this.getModalInfo(id);
                if (res.data && res.data.comment_data_json) {
                    res.data.comment_data_json = this.getCommentData(res.data.comment_data_json);
                }
                selected = res.data
                keywordHitCount = this.getKeywordCount(
                    keywords,
                    res.data.text_data_json
                );
            }

            let setData = {
                comment_alert_flag: false,
                comment_alert_message: "",
                insert_comment: "",
                comment_edit_flag: [],
                voice_alert_flag: false,
                voice_alert_message: "",
                voice_edit_flag: [],
                message_box_data: [],
                message_box_index: 0, 
                auto_scroll_flag: false,
                keyword_index_arr: [],
                searching_index: 0,
                element_index: 0,
                show: true,
                modalType,
                selected,
                keywordHitCount,
                comment_null_flag: false,
                voice_text_null_flag: false
            }

            this.setState(setData);
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }


    /**
     * 音声テキスト更新
     * @param {Integer} index
     * @param {String} value
     * @return {Object}
     */
    updateVoiceData = async(index, value) => {
        try {
            if(!value.trim()){
                //入力内容がない場合、アラートが出る
                return alert(this.props.langText.Message.VoiceTextBlankError);
            } 
            let { selected } = this.state;
            let voice_data = [...this.state["selected"]["text_data_json"]];
            voice_data[index] = {
                ...voice_data[index],
                transcript: value||"",
                modified: moment().tz("Asia/Tokyo").format(),
                update_user_name: this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei,
                update_user_email: this.props.userInfo.user_id,
            }
            let result = await this.ascAxios("post", `${this.reactContainerPath}/updateVoiceText`, {id: selected.id, voice_data});
            selected.text_data_json = voice_data;

            this.setState({
                selected,
                voice_alert_flag: true,
                voice_alert_message: this.props.langText.Message.VoiceTextUpdated,
                keyword_index_arr:[]
            });
            return result;
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataUpdateError");
        }
    }

    /**
     * コメント作成
     * @param {String} value
     * @return {Object}
     */
    insertCommentData = async(value) => {
        if(!value.trim()){
            //入力内容がない場合、アラートが出る
            return alert(this.props.langText.Message.CommentInputPlaceholder);
        } else{
            try {
                let { selected } = this.state;
                this.state.selected.comment_data_json = this.state.selected.comment_data_json || "";
                //既存コメントに新コメント追加してコメントカラムアップデート
                let comment_data = [
                    ...this.state["selected"]["comment_data_json"],
                    ...[{
                        data: value,
                        created: moment().tz("Asia/Tokyo").format(),
                        entry_user_name: this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei,
                        entry_user_email: this.props.userInfo.user_id,
                        del_flag: "N"
                    }]
                ];
                let result = await this.ascAxios("post", `${this.reactContainerPath}/updateComment`, {id: selected.id, comment_data, type:"insert"});
                selected.comment_data_json = comment_data;

                this.setState({
                    selected,
                    comment_alert_flag: true,
                    comment_alert_message: this.props.langText.Message.CommentInserted,
                    insert_comment: ""
                });
                return result;
            } catch (err) {
                console.error(err);
                this.showErrorObjectMesssage(err, "DataInsertError");
            }
        }
    }

    /**
     * コメント更新
     * @param {Integer} index
     * @param {String} value
     * @return {Object}
     */
    updateCommentData = async(index, value) => {
        try {
            if(!value.trim()){
                //入力内容がない場合、アラートが出る
                return alert(this.props.langText.Message.CommentInputPlaceholder);
            }
            let { selected } = this.state;
            let comment_data = [...this.state["selected"]["comment_data_json"]];
            comment_data[index] = {
                ...comment_data[index],
                data: value,
                modified: moment().tz("Asia/Tokyo").format(),
                update_user_name: this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei,
                update_user_email: this.props.userInfo.user_id,
                del_flag: "N"
            }
            let result = await this.ascAxios("post", `${this.reactContainerPath}/updateComment`, {id: selected.id, comment_data, type:"update"});
            //更新するコメントの内容を入力したコメントに変えてアップデート
            selected.comment_data_json = comment_data;

            this.setState({
                selected,
                comment_alert_flag: true,
                comment_alert_message: this.props.langText.Message.CommentUpdated
            });
            return result;
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataUpdateError");;
        }
    }

    /**
    * コメント削除
    * @param {Integer} index
    * @return {Object}
    */
    deleteCommentData = async(index) => {
        try {
            let { selected } = this.state;
            let comment_data = [...this.state["selected"]["comment_data_json"]];
            comment_data[index] = {
                ...comment_data[index],
                modified: moment().tz("Asia/Tokyo").format(),
                delete_user_name: this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei,
                delete_user_email: this.props.userInfo.user_id,
                del_flag: "Y"
            }
            let result = await this.ascAxios("post", `${this.reactContainerPath}/updateComment`, {id: selected.id, comment_data, index, type:"delete"});
            //削除されたコメントの内容をアップデート
            selected.comment_data_json = this.getCommentData(comment_data);

            this.setState({
                selected,
                comment_alert_flag: true,
                comment_alert_message: this.props.langText.Message.CommentDeleted
            });
            return result;
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataDeleteError");
        }
    }

    closeModal = () => {
        this.setState({show: false});
    }
    
    onClickHandle = async() => {
        let {
            selected,
            filtered
        } = this.state;
        const cm12_id = filtered.filter(data => data.id === 'cm12_id')[0].value.value;
        let check_flag = (selected.flag_data_json.check_flag === "Y")? "N" : "Y";
        let message = check_flag ==="Y"? this.props.langText.Message.ConfirmMessage: this.props.langText.Message.UnconfirmMessage
        if(window.confirm(message)){
            let check_modified = moment().tz("Asia/Tokyo").format();
            try {
                switch (this.state.modalType) {
                    case 'analysis':
                        let result = await this.ascAxios("post", `${this.reactContainerPath}/updateCheckFlag`, {id: selected.id, check_flag, check_modified})
                        selected.flag_data_json.check_flag = check_flag
                        this.setState(
                            selected
                        );
                        check_flag === "Y"
                        ? alert(this.props.langText.Message.DataCheckSuccess)
                        : alert(this.props.langText.Message.DataUnconfirmedSuccess);

                        //一覧更新
                        this.execFullTextSearch(
                            cm12_id,
                            filtered,
                            this.formatForTableDisplay,
                        );
                        break;
                    default:
                        alert(this.getErrorString({code: 'modal'}));
                }
            } catch (err) {
                console.error(err);
                return this.showErrorObjectMesssage(err, "DataSelectError");
            }
        }
    }

    validationHandle = param => {
        let nowState = null;
        switch(param) {
            case "analysis":
                nowState = this.props.currentPermission.edit && true
                break;
            default:
        }
        return nowState;
    }

    // キーワードでソートする時、キーワードが空欄の場合、テーブルのカラム調整
    keywordColumnHandle = (state) => {
        let sort = [];
        if (state.sorted[0].id === "keyword_count") {
            sort = state.filtered.filter(
                row => row.id === "keyword"
            );

            console.log("length:", sort.length);
            if (sort.length === 0) {
                this.setState({
                    keyword_flag: false
                });
            }
        }
    }

    onIntervalChange = (e) => {
        let {autoUpdateInterval} = this.state;
        let stateInterval;
        if(autoUpdateInterval.value == 1){
            //自動更新無しが選択された場合は自動更新を止める
            this.onSearch(this.state.filtered);
            return clearInterval(this.state.interval);
        }else if(autoUpdateInterval.value != 1 && e){
            //自動更新間隔が選択されたら一回画面更新後、自動更新
            clearInterval(this.state.interval);
            this.onSearch(this.state.filtered);
            stateInterval = setInterval(() => {
                this.onSearch(this.state.filtered);
            }, autoUpdateInterval.value);
            this.setState({interval : stateInterval, disableFlg: true});
        }
    }

    onSelectChange = (event, param) => {
        this.onChange(event, "autoUpdateInterval");
        if(param == "autoUpdateInterval"){
            clearInterval(this.state.interval);
            this.setState({disableFlg: false});
        }
    }

    onPlayHandle = (audio) => {
        try {
            if (audio.played["length"] === 0 && audio.currentTime === 0) {
                this.saveAccessLogs({
                    "resource" : `${this.reactContainerPath}/voiceLogPlay`, 
                    "body" : {
                        "voice_log_url" : audio.currentSrc
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return(
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <div className="CallAnalysis">
                    <Row>
                    {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                            ?
                            <Col xs={12} md={12}>
                                <Col sm={4} md={2} lg={1} className="analysis-label">
                                    <div className="margin-top-05">{this.props.langText.Body.CompanyName}</div>
                                </Col>
                                <Col sm={12} md={12} lg={3}>
                                    <Select
                                        defaultValue = {this.state.companySelect[3]}
                                        value = {this.getFilterData("cm12_id")}
                                        onChange = {
                                            e => this.onChange(e, "cm12_id")
                                        }
                                        options={this.state.companySelect}
                                        placeholder = {!this.state.companySelect[0] ?
                                            <Glyphicon glyph="refresh" className="spinning"/> : this.props.langText.SelectOption.Placeholder}/>
                                </Col>
                            </Col>
                            :
                            null
                        }
                        <Col xs={12} md={12} className="margin-top-03 flex-style">
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.CustomerBoardExtension}</div>
                            </Col>
                            <Col sm={12} md={3} lg={2} className="padding-right-3">
                                <Select
                                    key = "display_number"
                                    value = {this.getFilterData("display_number")}
                                    onChange = {
                                        e => this.onChange(e, "display_number")
                                    }
                                    options = {this.state.externalArr}
                                    placeholder = {this.props.langText.SelectOption.Placeholder_defaultexternal}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.StartDate}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                    <DatePicker
                                        onChange = {e => this.onChange(e, "start_datetime")}
                                        selected = {this.getFilterData("start_datetime")? this.getFilterData("start_datetime"): null}
                                        dateFormat = "YYYY/MM/DD"
                                        className = "form-control"
                                        todayButton = {this.props.langText.Body.Today}
                                        placeholderText = {this.props.langText.Body.StartDate}
                                        maxDate={this.getFilterData("end_datetime")?this.getFilterData("end_datetime"):null}
                                    />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.StartTime}</div>
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "start_ji"
                                    value = {this.getFilterData("start_ji")}
                                    onChange = {
                                        e => this.onChange(e, "start_ji")
                                    }
                                    options={this.hourShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "start_hun"
                                    value = {this.getFilterData("start_hun")}
                                    onChange = {
                                        e => this.onChange(e, "start_hun")
                                    }
                                    options={this.minuteShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col className = "seatview-form margin-left-auto call-analysis-interval" sm={4} md={4} lg={3}>
                                <Col sm={9} md={9} lg={9}>
                                    <Select
                                        defaultValue = {
                                            this.props.langText.SelectOption.autoUpdateIntervalList[0]
                                        }
                                        onChange = {e => this.onSelectChange(e, "autoUpdateInterval")}
                                        options = {this.props.langText.SelectOption.autoUpdateIntervalList}
                                        value = {this.state.autoUpdateInterval}
                                        isDisabled = {(!this.state.filtered.some(row => row.id === "display_number"))}
                                    />
                                </Col>
                                <Col sm={1} md={1} lg={1}>
                                    <Button
                                        className = "auto-update-btn"
                                        bsStyle = "primary"
                                        bsSize = "sm"
                                        onClick = {e => this.onIntervalChange(e)}
                                        disabled = {this.state.disableFlg}
                                    >
                                        <Glyphicon glyph="refresh"/>
                                    </Button>
                                </Col>
                            </Col>
                        </Col>
                        <Col xs={12} md={12} className="margin-top-03 flex-style">
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.DestUserNumber}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2} className="padding-right-3">
                                <FormControl
                                    onChange = {e => this.onChange(e, "dest_tel_no")}
                                    value = {this.getFilterData("dest_tel_no")}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.EndDate}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <DatePicker
                                    onChange = {e => this.onChange(e, "end_datetime")}
                                    selected = {this.getFilterData("end_datetime")? this.getFilterData("end_datetime"): null}
                                    dateFormat = "YYYY/MM/DD"
                                    className = "form-control"
                                    todayButton = {this.props.langText.Body.Today}
                                    placeholderText = {this.props.langText.Body.EndDate}
                                    minDate = {this.getFilterData("start_datetime")?this.getFilterData("start_datetime"):null}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.EndTime}</div>
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "end_ji"
                                    value = {this.getFilterData("end_ji")}
                                    onChange = {
                                        e => this.onChange(e, "end_ji")
                                    }
                                    options={this.hourShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "end_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "end_hun"
                                    value = {this.getFilterData("end_hun")}
                                    onChange = {
                                        e => this.onChange(e, "end_hun")
                                    }
                                    options={this.minuteShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "end_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col className="seatview-form margin-left-auto call-analysis-interval" sm={4} md={4} lg={3}>
                            </Col>
                        </Col>
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05 call-analysis-label">{this.props.langText.Body.InOutType}</div>
                            </Col>
                            <Col sm={12} md={4} lg={3} className="call-analysis-button-group">
                                <ButtonGroup className="analysis-btn">
                                    <Button
                                        value = "all"
                                        onClick = {e => this.onChange(e , "inout_type")}
                                        className = {this.state.filtered.map(row => row.id === "inout_type" && row.value === "all" ? "analysis-btn-focus":"")}
                                    >
                                        {this.props.langText.Body.All}
                                    </Button>
                                    <Button
                                        value = "in"
                                        onClick = {e => this.onChange(e , "inout_type")}
                                        className = {`call-analysis-button ${this.state.filtered.map(row => row.id === "inout_type" && row.value === "in" ? "analysis-btn-focus":"")}`}
                                    >
                                        {this.props.langText.Body.Inbound}
                                    </Button>
                                    <Button
                                        value = "out"
                                        onClick = {e => this.onChange(e , "inout_type")}
                                        className = {`call-analysis-button ${this.state.filtered.map(row => row.id === "inout_type" && row.value === "out" ? "analysis-btn-focus":"")}`}
                                    >
                                        {this.props.langText.Body.Call}
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.Keyword}</div>
                            </Col>
                            <Col sm={12} md={4} lg={5}>
                                <Creatable
                                    isMulti
                                    isClearable
                                    value={this.state.keywords}
                                    placeholder={this.props.langText.Message.Validation_string_input}
                                    onChange = {e => this.onChange(e, "keyword")}
                                    noOptionsMessage={() => this.props.langText.Message.Validation_string_input}
                                    formatCreateLabel={(inputText) => `${inputText} ${this.props.langText.Message.Creating}`}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.Check}</div>
                            </Col>
                            <Col sm={12} md={4} lg={3} className="call-analysis-button-group">
                                <ButtonGroup className="analysis-btn">
                                    <Button
                                        value = "all"
                                        onClick = {e => this.onChange(e , "check_flag")}
                                        className = {this.state.filtered.map(row => row.id === "check_flag" && row.value === "all" ? "analysis-btn-focus":"")} 
                                    >
                                        {this.props.langText.Body.All}
                                    </Button>
                                    <Button
                                        value = "true"
                                        onClick = {e => this.onChange(e , "check_flag")}
                                        className = {this.state.filtered.map(row => row.id === "check_flag" && row.value === "true" ? "analysis-btn-focus":"")} 
                                    >
                                        {this.props.langText.Body.Confirmed}
                                    </Button>
                                    <Button
                                        value = "false"
                                        onClick = {e => this.onChange(e , "check_flag")}
                                        className = {this.state.filtered.map(row => row.id === "check_flag" && row.value === "false" ? "analysis-btn-focus":"")} 
                                    >
                                        {this.props.langText.Body.Unconfirmed}
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.Comment}</div>
                            </Col>
                            <Col sm={12} md={4} lg={5}>
                                <FormControl
                                    onChange = {e => this.onChange(e, "comment")}
                                    value = {this.getFilterData("comment")}
                                />
                            </Col>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-05 flex-style">
                            <Col sm={1} md={1} lg={1} className="margin-top-01">
                                <Button
                                    id="customer-insert"
                                    className="searchBtn"
                                    bsStyle='primary'
                                    bsSize='sm'
                                    onClick={() => this.onSearch(this.state.filtered)}
                                    disabled = {this.state.loading || this.state.searchValidation}
                                >
                                    {this.props.langText.Body.Search}
                                </Button>
                            </Col>
                            <Col sm={12} md={4} lg={4}>
                                <label className="margin-top-05 search-message">{this.state.message}</label>
                            </Col>
                            <Col sm={12} md={4} lg={4} className="text-right margin-left-auto">
                                <Button
                                    id="callanalysis-download"
                                    className='table-button'
                                    bsStyle='default'
                                    bsSize='sm'
                                    onClick={this.CallAnalysisExcelDownload}
                                    disabled = {this.state.loading || this.state.searchValidation}
                                >
                                    <SetDataWithSpeechBubble
                                        displayData={this.props.langText.Body.CallAnalysisDownload}
                                        speechBubbleData = {this.props.langText.Message.MaxDownloadDataCount}
                                        infoIconDisplayFlag = {true}
                                        infoIconType = {faIcon.faInfoCircle}
                                        infoIconClassName = "info-icon margin-left-05"
                                        infoIconColor = "white"
                                        infoIconSize = "lg"/>
                                </Button>
                                <CSVLink
                                    data={this.state.csvdata}
                                    className="hidden"
                                    ref={this.csvLink}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-05">
                            <CommonTable
                                tableState = {this.state}
                                propSetState = {this.propSetState}
                                talbeRef={this.table}
                                style={{height: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)? this.props.tableHeight-180: this.props.tableHeight-140}}
                                manual="manual"
                                columns = {this.getColumnsData()}
                                data={this.state.data}
                                pages={this.state.pages}
                                loading={this.state.loading}
                                defaultSorted={[{id: "start_datetime", desc: false}]}
                                onFetchData={(state) => {
                                    if (!this.getFilterData("display_number")) return;
                                    let cm12Id = this.state.filtered.filter(
                                        data => data.id === "cm12_id"
                                    );
                                    if (
                                        cm12Id &&
                                        cm12Id[0].value &&
                                        cm12Id[0].value.value
                                    ) {
                                        cm12Id = cm12Id[0].value.value;
                                    } else {
                                        cm12Id = this.state.cm12_id;
                                    }
                                    this.execFullTextSearch(
                                        cm12Id,
                                        this.state.filtered,
                                        this.formatForTableDisplay,
                                        state
                                    );
                                    this.keywordColumnHandle(state);
                                }}
                                filtered={this.state.filtered}
                                hidden_filtered={this.state.hidden_filtered}
                                previousText={this.props.langText.Table.PreviousText}
                                nextText={this.props.langText.Table.NextText}
                                loadingText={this.props.langText.Table.LoadingText}
                                noDataText={this.props.langText.Table.NoDataText}
                                rowsText={this.props.langText.Table.RowsText}/>
                        </Col>
                    </Row>
                    <CallAnalysisModal
                        userInfo = {this.props.userInfo}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}
                        boardWidth = {this.props.boardWidth}
                        copyData = {this.copyData}
                        insertCommentData = {this.insertCommentData}
                        updateCommentData = {this.updateCommentData}
                        deleteCommentData = {this.deleteCommentData}
                        updateVoiceData = {this.updateVoiceData}
                        getFilterData = {this.getFilterData}
                        getKeywordData = {this.getKeywordData}
                        currentPermission = {this.props.currentPermission}
                        customBtn1Func = {this.onClickHandle}
                        customBtn2Func = {this.closeModal}
                        onPlayHandle = {this.onPlayHandle}
                    />
                </div>
            </BlockUi>
        );
    }
}
