import React, {Component} from "react"
import { Panel, Button, Glyphicon } from "react-bootstrap"
import { ViewCallHistoryElement, ViewVoiceTextElement, ViewCommentElement, ViewAudioControlElement} from "../Elements/AscElements/CallAnalysisElements";
import ButtonSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";
import AscModal from "../Elements/Modal/Modal";
import "../Elements/FromGroup/form_select.css";

export default class CallAnalysisModal extends Component {
    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}
                customBtn1Func = {this.props.customBtn1Func}
                customBtn2Func = {this.props.customBtn2Func}
                customBtn1Msg = {(
                    this.props.state.selected
                    && this.props.state.selected.flag_data_json
                    && this.props.state.selected.flag_data_json.check_flag === "Y")
                    ? this.props.langText.Body.UnConfirmed
                    : this.props.langText.Body.Check
                }
                customBtn2Msg = {this.props.langText.Body.Close}
                customBtn1Class = {(
                    this.props.state.selected
                    && this.props.state.selected.flag_data_json
                    && this.props.state.selected.flag_data_json.check_flag === "Y")
                    ? "unconfirmed-execute"
                    : "confirmed-execute"
                }
                customBtn2Class = "close-execute"
                customBtn1Disabled = {this.props.validationHandle}
            >
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.CallHistoryName}
                        </Panel.Title>
                    </Panel.Heading>
                    <ViewCallHistoryElement
                        state={this.props.state}
                        langText={this.props.langText}
                        boardWidth={this.props.boardWidth}
                    />
                </Panel>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.VoiceText}
                            {this.props.state && this.props.state.selected && this.props.state.selected.text_data_json.length !==0 && 
                            //音声テキストがあれば全体音声テキストコピーボタン・自動スクロールボタン表示
                            <>
                            <span className="pannel-btn-right">
                                <ButtonSpeechBubble
                                    className="control-button"
                                    bsSize="xsmall"
                                    onClick={e => {
                                        let player = document.getElementById("audio-text-player");
                                        let panel = document.getElementById("voice-text-panel");
                                        this.props.propSetState({
                                            auto_scroll_flag: !this.props.state.auto_scroll_flag,
                                            message_box_data: [],
                                            message_box_index: 0,
                                        });
                                        player.currentTime = 0;
                                        panel.scrollTo(0,0)
                                    }}
                                    DisplayIcon="resize-vertical"
                                    speechBubble={this.props.langText.Body.AutoScroll}
                                />
                                <ButtonSpeechBubble
                                    className="control-button"
                                    bsSize="xsmall"
                                    onClick={e => this.props.copyData("voice-all", this.props.state.selected.text_data_json)}
                                    disabled = {this.props.state.voice_edit_flag.some(row=> row)}
                                    DisplayIcon="copy"
                                    speechBubble={this.props.langText.Body.Copy}
                                />
                            </span>
                            </>
                            }
                        </Panel.Title>
                    </Panel.Heading>
                    <ViewVoiceTextElement
                        state={this.props.state}
                        langText={this.props.langText}
                        userInfo={this.props.userInfo}
                        copyData={this.props.copyData}
                        propSetState={this.props.propSetState}
                        updateVoiceData={this.props.updateVoiceData}
                        getFilterData={this.props.getFilterData}
                        getKeywordData={this.props.getKeywordData}
                        currentPermission={this.props.currentPermission}
                    />
                    <Panel.Footer className="analysis-audio-control-footer">
                        <ViewAudioControlElement
                            state={this.props.state}
                            langText={this.props.langText}
                            userInfo={this.props.userInfo}
                            copyData={this.props.copyData}
                            propSetState={this.props.propSetState}
                            updateVoiceData={this.props.updateVoiceData}
                            getFilterData={this.props.getFilterData}
                            onPlayHandle={this.props.onPlayHandle}
                            getKeywordData={this.props.getKeywordData}
                            currentPermission={this.props.currentPermission}
                        />
                    </Panel.Footer>
                </Panel>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.Comment}
                            {this.props.state && this.props.state.selected && this.props.state.selected.comment_data_json &&
                                //コメントがあれば全体コメントコピーボタン表示\
                                <span className="pannel-btn-right">
                                    <ButtonSpeechBubble
                                        className="control-button"
                                        bsSize="xsmall"
                                        onClick={e => this.props.copyData("comment-all", this.props.state.selected.comment_data_json)}
                                        disabled = {this.props.state.comment_edit_flag.some(row=> row)}
                                        DisplayIcon="copy"
                                        speechBubble={this.props.langText.Body.Copy}
                                    />
                                </span>
                            }
                        </Panel.Title>
                    </Panel.Heading>
                    <ViewCommentElement
                        state={this.props.state}
                        langText={this.props.langText}
                        userInfo={this.props.userInfo}
                        copyData={this.props.copyData}
                        propSetState={this.props.propSetState}
                        insertCommentData={this.props.insertCommentData}
                        updateCommentData={this.props.updateCommentData}
                        deleteCommentData={this.props.deleteCommentData}
                        currentPermission={this.props.currentPermission}
                    />
                </Panel>
            </AscModal>
        );
    }
}
