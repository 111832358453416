import React from 'react'
import { Button, Col, Glyphicon, Row, FormControl} from 'react-bootstrap'
import Favi from "../Elements/Favi/Favi"
import AscComponent from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import ExtensionModal from './ExtensionNumberModal'
import * as GlobalConst from "../../components/AscConstants";
import DatePicker from "react-datepicker";

export default class ExtensionNumber extends AscComponent {
    constructor(props) {
        super(props)
        this.state = {
            // table item
            columns:            this.getColumnsData(),
            data:               [],
            pages:              null,
            loading:            false,
            filtered: [],

            // modal item
            show:               false,
            modalType:          null,

            // select item
            companySelect:      [],
            departmentSelect:   [],
            floorSelect:        [],
            extensionNumber:    '',
            externalSettingTypeRadio: this.getSelectOption('external_setting_type', this.props.langText.SelectOption),
            inboundTypeRadio:   this.getSelectOption('inbound_type', this.props.langText.SelectOption),
            inboundGroupSelect: [],
            inboundTypeSelect: this.getSelectOption('inbound_all_type', this.props.langText.SelectOption),
            extensionNumSelect: [],
            externalNumSelect:  [],
            monitoringFlgRadio: this.getSelectOption('monitoring_flg', this.props.langText.SelectOption),
            softphoneCodecTypeSelect: this.getSelectOption('softphone_codec_type', this.props.langText.SelectOption),
            extensionIdSelect: [],

            // data item
            outbound_prefix_flag: this.getExternalFlag(),
            extension_id:       '',
            company_id:         '',
            company_name:       '',
            department_id:      '',
            department_name:    '',
            floor_id:           '',
            floor_name:         '',
            sequence_number:    '',
            extension_number:   '',
            insert_count:       '',
            inbound_type:       '',
            inbound_group_id:   [],
            inbound_group_name: [],
            extension_select_id: [],
            external_number:    '',
            codec_type:         '',
            memo: '',
            monitoring_flg: '',
            deleteinboundgroupname: '',
            password: '',
            passwordRe: '',
            newPassword: '',
            newPasswordRe: '',
            passwordResetFlag: false,
            extension_ids: [],
            delete_inbound_type: '',

            // button_disabled
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "external_number" : [],
                "inbound_group_id" : [],
                "floor_id" : [],
                "department_load" : [],
                "external_load" : [],
                "inbound_load" : [],
                "floor_load" : []
            },
            omniphone_login_data: {},
            omni_phone_version: ''
        }
        this.floor_load_flag = false;
        this.inbound_load_flag = false;
        this.exrernal_load_flag = false;
    }

    getExternalFlag() {
        return (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) || this.props.userInfo.outbound_prefix_flag);
    }

    getColumnsData() {
        let columnData = [];
        let monitoringFlgSelect = this.getSelectOption('monitoring_flg_list', this.props.langText.SelectOption);

        columnData.push({
            Header: this.props.langText.Body.Id,
            accessor: 'id',
            filterable: false,
            width: this.props.boardWidth.sId,
        });
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            //oem権限以上の場合、会社名を表示
            columnData.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: 'cm13_departments.cm12_companies.company_name',
                Filter: () => <FormControl 
                    placeholder={this.props.langText.Body.ForwardMatch} 
                    onChange={e => this.onFilterChange(e.target.value, "cm13_departments.cm12_companies.company_name")} 
                />
            });
        }

        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)){
            //会社権限以上の場合、拠点名を表示
            columnData.push({
                Header: this.props.langText.Body.DepartmentName,
                accessor: 'cm13_departments.department_name',
                width: this.props.boardWidth.smedium,
                Filter: () => <FormControl 
                    placeholder={this.props.langText.Body.ForwardMatch} 
                    onChange={e => this.onFilterChange(e.target.value, "cm13_departments.department_name")} 
                />
            });
        }

        if (
            this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system) ||
            (this.props.userInfo.floor_flg &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
        ) {
            //システム権限または拠点管理権限のフロアありの場合、フロア名を表示
            columnData.push({
                Header: this.props.langText.Body.FloorName,
                accessor: "cm13_departments.floor_name",
                width: this.props.boardWidth.smedium,
                Filter: () => (
                    <FormControl
                        placeholder={this.props.langText.Body.ForwardMatch}
                        onChange={e =>
                            this.onFilterChange(e.target.value, "cm13_departments.floor_name")
                        }
                    />
                ),
            });
        }

        columnData.push({
            Header: this.props.langText.Body.ExtensionNumber,
            accessor: 'extension_number',
            width: this.props.boardWidth.CallNumber,
            Cell: data => {
                return (
                    <div>
                        <Favi
                            className='fa-fw fa-icon'
                            icon={data.row._original.inbound_type}/>
                        {data.row._original.extension_number}
                    </div>
                )
            },
            Filter:() =><FormControl 
                placeholder={this.props.langText.Body.PartialMatch} 
                onChange={e => this.onFilterChange(e.target.value, "extension_number")} 
            />
        });

        if (this.getExternalFlag()) {
            columnData.push({
                Header: this.props.langText.Body.DefaultExternalNum,
                accessor: 'cm61_external_numbers.display_number',
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl 
                    placeholder={this.props.langText.Body.ForwardMatch} 
                    onChange={e => this.onFilterChange(e.target.value, "cm61_external_numbers.display_number")} 
                />
            });
        }

        columnData.push({
            Header: this.props.langText.Body.InboundGroupName,
            accessor: 'ct28_assign_inbound_group_infos.cm28_inbound_group_infos.inbound_group_name',
            sortable: false,
            Cell: data => {
                let elemArr = [];
                data.row._original.ct28_assign_inbound_group_infos.cm28_inbound_group_infos.forEach((row, key) => {
                    elemArr.push(
                        <p key={key}>
                            {row.inbound_group_name}
                        </p>
                    );
                });

                return (elemArr);
            },
            Filter: () => <FormControl 
                placeholder={this.props.langText.Body.ForwardMatch} 
                onChange={e => this.onFilterChange(e.target.value, "ct28_assign_inbound_group_infos.cm28_inbound_group_infos.inbound_group_name")}
            />
        });

        columnData.push({
            Header: this.props.langText.Body.ExtensionName,
            accessor: 'memo',
            Filter: () => <FormControl 
                placeholder={this.props.langText.Body.ForwardMatch} 
                onChange={e => this.onFilterChange(e.target.value, "memo")} 
            />
        });

        columnData.push({
            Header: this.props.langText.Body.MonitoringFlg,
            accessor: 'monitoring_flg',
            width: this.props.boardWidth.xsmall,
            Cell: ({value}) => {
                let findObj = monitoringFlgSelect.find(row => row.value === value);
                return findObj ? findObj.label : "";
            },
            Filter: ({filter}) => {
                return (
                    <select
                        onChange = {e => this.onFilterChange(e.target.selectedOptions[0].value, "monitoring_flg")}
                        value = {filter ? filter.label : ""}
                    >
                        <option key={0} value=""></option>
                        {monitoringFlgSelect.map(row => <option key={row.label} value={row.value}>{row.label}</option>)}
                    </select>
                )
            }
        });

        columnData.push({
            Header: this.props.langText.Body.UpdateUserName,
            accessor: 'update_user_name',
            width: this.props.boardWidth.small,
            Filter: () => <FormControl 
                placeholder={this.props.langText.Body.ForwardMatch} 
                onChange={e => this.onFilterChange(e.target.value, "update_user_name")} 
            />
        });

        columnData.push({
            Header: this.props.langText.Body.Modified,
            width: this.props.boardWidth.modified,
            accessor: "modified",
            Cell: ({value}) => value ? this.getMomentTime({format: this.props.langText.Body.DateFormat, date: value}) : "",
            Filter: () => {
                return (
                    <Col>
                        <DatePicker 
                            className = "extension-date-picker"
                            selected = {this.state.modified} 
                            onChange = {(e) => {
                                this.setState({modified: e});
                                this.onFilterChange(
                                    e 
                                        ? this.getMomentTime({format: this.props.langText.Body.DateFormat, date: e})
                                        : "",
                                    "modified"
                                );
                            }}
                            isClearable
                            dateFormat={this.props.langText.Body.DateFormat}  
                            
                        />
                </Col>
                );
            }
        });

        columnData.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.smedium,
            Cell: data => {
                let rowData = [];

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            onClick={event => this.modalShow(event, "update", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    );
                } else {
                    rowData.push(
                        <Button
                            key={3}
                            bsSize="xsmall"
                            onClick={event => this.modalShow(event, "read", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="eye-open" />
                        </Button>
                    );
                }
                
                if (this.props.currentPermission.create) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={event => this.modalShow(event, "copy", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="copy"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={2}
                            bsSize="xsmall"
                            onClick={event => this.modalShow(event, "delete", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="minus" />
                        </Button>
                    );
                }

                return (
                    <Row className='text-center'>
                        {rowData}
                    </Row>
                )
            }
        });

        return columnData;
    }

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    // show modal when button clicked
    async modalShow (event, modalType, param) {
        try{
            event.preventDefault()

            this.floor_load_flag = false;
            this.inbound_load_flag = false;
            this.exrernal_load_flag = false;

            if (param && param.id) {
                let modal_info = await this.getModalInfo(param.id);
                param = modal_info.data ? modal_info.data[0] : null;
            }

            let setData = {
                extension_id: param && param.id
                    ? param.id
                    : '',
                company_id: param && param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.id
                    ? param.cm13_departments.cm12_companies.id
                    : '',
                company_name: param && param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.company_name
                    ? param.cm13_departments.cm12_companies.company_name
                    : '',
                department_id: param && param.cm13_departments && param.cm13_departments.id
                    ? param.cm13_departments.id
                    : '',
                department_name: param && param.cm13_departments && param.cm13_departments.department_name
                    ? param.cm13_departments.department_name
                    : '',
                floor_id: param && param.cm13_departments && param.cm13_departments.id ?
                    param.cm13_departments.id :
                    '',
                floor_name: param && param.cm13_departments && param.cm13_departments.floor_name
                    ? param.cm13_departments.floor_name
                    : '',
                floor_flg: param && param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.floor_flg
                    ? param.cm13_departments.cm12_companies.floor_flg === "Y"
                    : null,
                sequence_number: param && param.sequence_number
                    ? param.sequence_number
                    : '',
                extension_number: param && param.extension_number
                    ? param.extension_number
                    : '',
                insert_count: param && param.insert_count
                    ? param.insert_count
                    : '',
                inbound_type: param && param.inbound_type
                    ? param.inbound_type
                    : this.state.inboundTypeRadio[0].value,
                setting_type: this.state.externalSettingTypeRadio[0].value,
                inboung_type_name: param && param.inbound_type
                    ? this.state.inboundTypeRadio.find(select => select && select.value === param.inbound_type).label
                    : '',
                inbound_group_id: param && param.ct28_assign_inbound_group_infos && param.ct28_assign_inbound_group_infos.cm28_inbound_group_infos
                    ? param.ct28_assign_inbound_group_infos.cm28_inbound_group_infos.map(row => row.id)
                    : [],
                inbound_group_name: param && param.ct28_assign_inbound_group_infos && param.ct28_assign_inbound_group_infos.cm28_inbound_group_infos
                    ? param.ct28_assign_inbound_group_infos.cm28_inbound_group_infos.map(row => row.inbound_group_name)
                    : [],
                codec_type: param && param.am01_sip_dynamic_infos && param.am01_sip_dynamic_infos.allow
                    ? param.am01_sip_dynamic_infos.allow
                    : 'opus,ulaw',
                memo: param && param.memo
                    ? param.memo
                    : '',
                description: param && param.description
                    ? param.description
                    : '',
                external_number: param && param.cm61_external_numbers && param.cm61_external_numbers.id
                    ? param.cm61_external_numbers.id
                    : '',
                external_number_name: param && param.cm61_external_numbers && param && param.cm61_external_numbers.display_number
                    ? param.cm61_external_numbers.display_number
                    : '',
                extension_select_id: [],
                extensionNumSelect: [],
                externalNumSelect: [],
                monitoring_flg: param && param.monitoring_flg
                    ? param.monitoring_flg
                    : this.state.monitoringFlgRadio[1].value,
                deleteinboundgroupname : '',
                omniphone_logout_btn_display_flag : false,
                prefixDuplicateFlag: false,
                omni_phone_version: param && param.omni_phone_version
                    ? param.omni_phone_version
                    : '',
                password: '',
                passwordRe: '',
                newPassword: '',
                newPasswordRe: '',
                passwordResetFlag: false
            }
            if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
                // 会社管理者以下の場合、会社名を設定する（新規作成）
                setData.company_id = this.props.userInfo.company_id
                setData.company_name = this.props.userInfo.company_name
                setData.floor_flg = this.props.userInfo.floor_flg
            }

            if (!param && this.props.currentPermission.scope_code === GlobalConst.SCOPE_OBJECT.department.name) {
                // 拠点管理者の場合はフロア選択肢を設定する（新規）
                setData.department_id = this.props.userInfo.department_id;
                setData.department_name = this.props.userInfo.department_name;

                this.departmentSelectChanged(
                    {
                        company_id: setData.company_id,
                        department_prefix: this.props.userInfo.department_prefix,
                        department_id: setData.department_id,
                        floor_flg: setData.floor_flg,
                    },
                    ""
                );
            }

            if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
                // フロア管理者以下の場合、拠点名とフロア名を設定する（新規作成）
                setData.department_id = this.props.userInfo.department_id
                setData.department_name = this.props.userInfo.department_name
                setData.floor_id = this.props.userInfo.department_id
                setData.floor_name = this.props.userInfo.floor_name
            }

            if (modalType === "copy" && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) {
                //内線番号は重複不可のため初期表示は空白にする
                setData.sequence_number = "";

                this.departmentSelectChanged(
                    {
                        company_id: setData.company_id,
                        department_prefix: param.cm13_departments.department_prefix,
                        department_id: setData.department_id,
                        floor_flg: setData.floor_flg,
                    },
                    ""
                );

                if(setData.floor_flg){
                    //選択肢と設定値のvalueをあわせるため、拠点情報を再取得
                    const parentDepartment = await this.getParentDepartment(
                        param.cm13_departments.cm12_companies.id,
                        param.cm13_departments.department_prefix
                    );
                    setData.department_id = parentDepartment.data.id;
                    setData.department_name = parentDepartment.data.department_name;
                }
            }

            if (setData.inbound_type == "android" && modalType === 'delete') {
                setData.omniphone_logout_btn_display_flag = true;
            }

            if (modalType === "multi_delete") {
                setData.delete_inbound_type = this.state.inboundTypeSelect[3].value;
                setData.extension_ids = [];
                let body = null;

                if (setData.floor_flg && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
                    body = {floor_id: setData.floor_id, type: setData.delete_inbound_type}
                } else if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)) {
                    body = {department_id: setData.department_id, type: setData.delete_inbound_type}
                }

                if (body) {
                    await this.getExtensionIdSelect(body);
                } else {
                    setData.extensionIdSelect = [];
                }
            }

            this.setState(setData);

            this.getDefaultSelectData(setData)

            this.getExtensionNum(setData, 'selected')
            if (setData.floor_flg && modalType === "copy"){
                //複製の場合はフロアに紐づく情報を取得する
                this.getExternalNum({department_id: setData.floor_id, floor_flg: setData.floor_flg}, 'selected');
                this.getInboundGroup({department_id: setData.floor_id, inbound_type: setData.inbound_type} , 'selected');
            } else {
                this.getExternalNum({department_id: setData.department_id, floor_flg: setData.floor_flg}, 'selected');
                this.getInboundGroup(setData , 'selected');
            }

            // deleteまたはread時は文字列結合
            if(modalType === 'delete' || modalType === 'read')
            {
                setData.deleteinboundgroupname = this.getinboundGroupName(setData.deleteinboundgroupname)
                await this.omniphoneLoginStatusSearch(setData.extension_number);
            }

            this.setState({show: true, modalType})
        }catch(err){
            this.showErrorObjectMesssage(err);
            this.reactTableRefresh();
        }
    }



    /**
     * 親拠点情報を取得する
     * @param {string} company_id 
     * @param {string} department_prefix 
     */
    getParentDepartment = async(company_id, department_prefix) => {
        try {
            const parentDepartment = await this.ascAxios("post", `Common/getParentDepartment`, {
                company_id: company_id,
                department_prefix: department_prefix.slice(0, 2),
            });
            return parentDepartment;
        } catch (err) {
            throw new Error(err);
        }
    }

    /**
     * モーダル表示時に会社名をselectBoxに選択肢をセット
     * @param {object}
     */
    getDefaultSelectData = ({company_id, floor_flg}) => {
        this.ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.companySelectChanged({company_id, floor_flg})

                this.setState({
                    companySelect: res.data
                })
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
    }


    async onSelectChange (event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        let department_prefix = (event && event.department_prefix) ? event.department_prefix : "";
        let floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : "";
        let department_id = "";

        switch (param) {

            case 'company_id':
                let mySate = {
                    department_id: '',
                    external_number: '',
                    extensionIdSelect: [],
                    extension_ids: [],
                    outbound_prefix_flag: false,
                    floor_flg: floor_flg
                };
                let cObj = this.state.companySelect.find(row => row.value === value);

                if (cObj && cObj.outbound_prefix_flag) {
                    mySate.outbound_prefix_flag = true;
                }
                department_id = value ? this.state.department_id : "";

                this.setState(mySate);
                this.companySelectChanged({company_id: value, floor_flg});
                this.getInboundGroup({
                    department_id: '',
                    inbound_type:  this.state.inbound_type
                }, 'changed');
                this.getExtensionNum({department_id: ''}, 'changed');
                this.departmentSelectChanged({department_id: department_id, department_prefix, company_id: value, floor_flg}, 'changed');
                this.getExternalNum({department_id, floor_flg}, 'changed');
                break;
            case 'department_id':
                this.floor_load_flag = true;
                this.inbound_load_flag = true;
                this.departmentSelectChanged({department_id: value, department_prefix, company_id: this.state.company_id, floor_flg: this.state.floor_flg}, 'changed');
                if (value && !this.state.floor_flg && this.state.modalType === "multi_delete") await this.getExtensionIdSelect({department_id: value, type: this.state.delete_inbound_type});
                break;
            case 'floor_id':
                let inbound_type = this.state.inbound_type;
                department_id = value;//拠点またはフロアの情報
                this.setState({extensionIdSelect: [], extension_ids: []});

                if (value && this.state.modalType === "multi_delete") await this.getExtensionIdSelect({floor_id: value, type: this.state.delete_inbound_type});
                this.getInboundGroup({department_id, inbound_type}, 'changed');
                this.getExtensionNum({department_id}, 'changed');
                if (!floor_flg) {
                    //フロアなしで、拠点またはフロアが変更された場合
                    this.exrernal_load_flag = true;
                } else if (!department_prefix) {
                    //拠点が変更された場合
                    this.exrernal_load_flag = true;
                }
                this.getExternalNum({department_id, floor_flg}, 'changed');
                break;
            default:
                break;
        }
    }

    async onRadioChange (event, param) {
        super.onRadioChange(event, param);
        let value = (event && event.target && event.target.value) ? event.target.value : "";

        if (param === "setting_type") {
            this.getExtensionNum({department_id: this.state.department_id}, 'changed');
        } else if (param === "inbound_type") {
            let department_id = this.state.floor_id || this.state.department_id,
                inbound_type = value;

            this.getInboundGroup({department_id, inbound_type}, 'changed');
            this.getExtensionNum({department_id}, 'changed');
            this.setState({
                password: "",
                passwordRe: ""
            });
        }

        if (
            param === "delete_inbound_type" &&
            ((this.state.floor_flg && this.state.floor_id) || (!this.state.floor_flg && this.state.department_id))
        ) {
            await this.getExtensionIdSelect({department_id: this.state.department_id, floor_id: this.state.floor_id, type: value});
        }

    }

    onCheckBoxChange = (event, param) => {
        super.onCheckBoxChange(event, param);
        let value = (event && event.target && event.target.value) ? event.target.value : "";

        if (param === "passwordResetFlag") {
            this.setState({
                newPassword: "",
                newPasswordRe: ""
            });
        }
    }


    /**
     * 拠点の選択肢をセットする
     * @param {object}
     */
    companySelectChanged = ({company_id, floor_flg}) => {
        if(company_id)
        {
            this.common_buttondisabledLengthCheck(`department_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`department_load`,`pop`);
        }
        this.ascAxios('post', `Common/departmentSelect`, {
            container: this.reactContainerPath,
            company_id,
            floor_flg
        }).then(res => {
            if(this.state.company_id)
            {
                this.common_buttondisabledLengthCheck(`department_id`,`push`);
                this.common_buttondisabledLengthCheck(`department_load`,`pop`);
            }
            else
            {
                this.common_buttondisabledLengthCheck(`department_id`,`pop`);
                this.common_buttondisabledLengthCheck(`external_number`,`pop`);
            }

            this.setState({
                departmentSelect: res.data,
            })
        })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
    }

    /**
     * フロアの選択肢をセットする
     * @param {object} 
     * @param {string} action 
     */
    departmentSelectChanged = ({company_id, floor_flg, department_id, department_prefix}, action) => {
        if (floor_flg) {
            if(this.floor_load_flag === true && department_id && company_id)
            {
                //ローディングアイコンを表示
                this.common_buttondisabledLengthCheck(`floor_load`,`push`);
            }
            else
            {
                this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
            }
            //フロア選択肢を取得
            this.ascAxios('post', `Common/floorSelect`, {
                container: this.reactContainerPath,
                company_id,
                department_prefix
            })
            .then(res => {
                if(department_id)
                {
                    //フロアをセットしてローディングアイコンを非表示
                    this.common_buttondisabledLengthCheck(`floor_id`,`push`);
                    this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                    this.floor_load_flag = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`pop`);
                }
                this.setState({
                    floorSelect: res.data
                })

                if (action === 'changed') {
                    this.setState({
                        floor_id: "",
                    })
                }
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            })
        }
        this.setState({extensionIdSelect: [], extension_ids: []});
    }

    getInboundGroup = ({department_id, inbound_type}, action) => {
        if(this.inbound_load_flag === true && department_id)
        {
            this.common_buttondisabledLengthCheck(`inbound_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`inbound_load`,`pop`);
        }
        this.ascAxios("post", `Common/inboundGroupSelect`, {
            container: this.reactContainerPath,
            department_id,
            inbound_type,
        })
            .then(res => {
                if (this.state.department_id && this.state.company_id) {
                    this.common_buttondisabledLengthCheck(`inbound_group_id`, `push`);
                    this.common_buttondisabledLengthCheck(`inbound_load`, `pop`);
                    this.inbound_load_flag = false;
                } else {
                    this.common_buttondisabledLengthCheck(`inbound_group_id`, `pop`);
                }
                this.setState({
                    inboundGroupSelect: res.data,
                });

                if (action === "changed" && inbound_type === "softphone") {
                    this.setState({
                        inbound_group_id: [],
                        codec_type: this.state.softphoneCodecTypeSelect[0].value,
                    });
                } else if (action === "changed" && (inbound_type === "hardphone" || inbound_type === "android")) {
                    this.setState({
                        inbound_group_id: [],
                        codec_type: 'opus,ulaw',
                    });
                } else {
                    return;
                }
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    }

    getExtensionNum = ({department_id}, action) => {
        this.ascAxios('post', `${this.reactContainerPath}/extensionNumSelect`, {department_id})
            .then(res => {
                this.setState({
                    extensionNumSelect: res.data.cm51_extension_numbers,
                })

                if (action === 'changed') {
                    this.setState({
                        extension_select_id: [],
                    })
                }
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
    }

    getExternalNum = ({department_id, floor_flg}, action) => {
        if(this.exrernal_load_flag === true  && department_id)
        {
            this.common_buttondisabledLengthCheck(`external_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`external_load`,`pop`);
        }
        this.ascAxios('post', `${this.reactContainerPath}/externalNumSelect`, { department_id })
            .then(res => {
                //this.state.floor_idはフロアの入力確認
                if(floor_flg && department_id && this.state.floor_id)
                {
                    this.common_buttondisabledLengthCheck(`external_number`,`push`);
                    this.exrernal_load_flag = false;
                }
                else if (!floor_flg && department_id)
                {
                    this.common_buttondisabledLengthCheck(`external_number`,`push`);
                    this.exrernal_load_flag = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`external_number`,`pop`);
                }
                this.setState({
                    externalNumSelect: res.data.cm61_external_numbers,
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
            .finally(() => {
                this.common_buttondisabledLengthCheck(`external_load`,`pop`);
                if (action === 'changed') {
                    this.setState({
                        external_number: '',
                    })
                }
            });
    }

    getModalInfo(id) {
        return this.ascAxios('post', `${this.reactContainerPath}/modal`, { id });
    }

    getinboundGroupName = () => {
        let getid = [...this.state.inbound_group_id];
        let getarr = [...this.state.inboundGroupSelect];
        let returnstr = "";
        for(let i = 0; i < getarr.length; i++)
        {
            if(getid.indexOf(getarr[i].value) != -1)
            {
                // 初回のみ,無し
                if(returnstr === "")
                {
                    returnstr = getarr[i].label
                }
                else
                {
                    returnstr = returnstr + "," + getarr[i].label;
                }
            }
        }
        return returnstr;
    }

    onClickHandle =  modalType => event => {
        try {
            let {
                extension_id,
                company_id,
                department_id,
                floor_flg,
                floor_id,
                sequence_number,
                extension_number,
                insert_count,
                inbound_type,
                inbound_group_id,
                external_number,
                codec_type,
                memo,
                description,
                monitoring_flg,
                omniphone_login_data,
                password,
                newPassword,
                extension_ids,
                extensionIdSelect
            } = this.state;

            switch (modalType) {
                case "insert":
                case "copy":
                    this.blockUI();
                    this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                        company_id,
                        department_id: floor_flg ? floor_id : department_id,
                        inbound_type,
                        inbound_group_id,
                        external_number,
                        codec_type,
                        memo,
                        description,
                        monitoring_flg,
                        sequenceNumberList: sequence_number ? [sequence_number] : [],
                        extension_password: password ? password : "aa123456" ,
                    })
                        .then(res => {
                            //改行禁止。メッセージ表示に影響するため
                            alert(
                                `${this.props.langText.Message.DataInsertSuccess}\r\n ${this.props.langText.Body.ExtensionNumber}: ${res.data.sequenceNumberList[0]}`
                            );
                            this.reactTableRefresh();
                            this.setState({
                                show: false,
                            });
                        })
                        .catch(err => {
                            this.showErrorObjectMesssage(err);
                        });
                    break;

                case "insertBatch":
                    this.blockUI();
                    //メッセージを整形して表示する
                    //例：["001","003","004","005","020"] -> 001,003-005,020
                    const formatPrefix = (extension_numbers) => {
                        const message = [];
                        extension_numbers.forEach(number => {
                            if (message.length === 0) {
                                //最初の要素を追加
                                message.push(number);
                            } else {
                                if (number - message[message.length - 1] > 1) {
                                    //単数を追加
                                    message.push(",");
                                    message.push(number);
                                } else if (message[message.length - 2] === "-") {
                                    //連番の最後を更新する
                                    message[message.length - 1] = number;
                                } else if (number - message[message.length - 1] === 1) {
                                    //連番を設定する
                                    message.push("-");
                                    message.push(number);
                                }
                            }
                        });
                        //メッセージ表示に影響するため改行禁止。
                        return message.join("");
                    };

                    this.ascAxios("post", `${this.reactContainerPath}/getInsertBatchSequenceNumber`, {
                        department_id: floor_id || department_id,
                        sequence_number,
                        insert_count,
                    })
                        .then(res => {
                            const sequenceNumberList = res.data.sequenceNumberList;
                            if (
                                window.confirm(
                                    `${this.props.langText.Message.Extension_Confirm}\r\n${this.props.langText.Body.ExtensionNumber}: ${formatPrefix(res.data.sequenceNumberList)}`
                                )
                            ) {
                                this.blockUI();
                                this.ascAxios("post", `${this.reactContainerPath}/insertBatch`, {
                                    company_id,
                                    department_id: floor_id || department_id,
                                    insert_count,
                                    inbound_type,
                                    inbound_group_id,
                                    external_number,
                                    codec_type,
                                    memo,
                                    description,
                                    monitoring_flg,
                                    sequenceNumberList,
                                    extension_password: password ? password : "aa123456"
                                })
                                    .then(res => {
                                        //改行禁止。メッセージ表示に影響するため
                                        alert(
                                            `${this.props.langText.Message.DataInsertSuccess}\r\n ${this.props.langText.Body.ExtensionNumber}: ${formatPrefix(res.data.sequenceNumberList)}`
                                        );
                                        this.reactTableRefresh();
                                        this.setState({
                                            show: false,
                                        });
                                    })
                                    .catch(err => {
                                        this.showErrorObjectMesssage(err)
                                    });
                            }
                        })
                        .catch(err => {
                            this.showErrorObjectMesssage(err)
                        });
                    break;

                case "update":
                    this.blockUI();
                    this.ascAxios("post", `${this.reactContainerPath}/update`, {
                        extension_id,
                        company_id,
                        department_id,
                        sequence_number,
                        extension_number,
                        inbound_type,
                        inbound_group_id,
                        external_number,
                        codec_type,
                        memo,
                        description,
                        monitoring_flg,
                        newPassword
                    })
                        .then(res => {
                            alert(this.props.langText.Message.DataUpdateSuccess);
                            this.reactTableRefresh();
                        })
                        .catch(err => {
                            alert(this.getErrorString(err.response.data));
                        });
                    this.setState({
                        show: false,
                    });
                    break;

                case "delete":
                    this.blockUI();
                    this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                        extension_id,
                        department_id,
                        omniphone_login_data
                    })
                        .then(res => {
                            alert(this.props.langText.Message.DataDeleteSuccess);
                            this.reactTableRefresh();
                        })
                        .catch(err => {
                            alert(this.getErrorString(err.response.data));
                        });
                    this.setState({
                        show: false,
                    });
                    break;
                default:
                    alert(this.getErrorString({ code: "modal" }));
            }
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    validationHandle = param => {
        let nowState = null;

        let {
            company_id,
            department_id,
            floor_id,
            inbound_type,
            insert_count,
            floor_flg,
            prefixDuplicateFlag,
            passwordResetFlag,
            password,
            passwordRe,
            extension_ids
        } = this.state;

        let exp = /^([1-9]\d*)$/;

        switch(param) {
            case 'company_id':
            case 'department_id':
            case 'floor_id':
            case 'inbound_type':
            case 'setting_type':
                nowState = this.state[param]
                    ? null
                    : 'error'
                break;

            case 'insert_count':
                nowState = (
                    this.state[param]
                    && this.state[param] <= 200
                    && exp.test(this.state[param]))
                    ? null
                    : 'error'
                break;

            case "insert":
            case "copy":
                nowState =
                    company_id &&
                    department_id &&
                    inbound_type &&
                    !prefixDuplicateFlag && //false = プレフィックスの重複なし
                    ((password || passwordRe) ? this.passwordValidationHandle(param, this.state) : true);
                if (floor_flg) {
                    nowState = (nowState && floor_id);
                }
                break;

            case 'insertBatch':
                nowState = (
                       company_id
                    && department_id
                    && inbound_type
                    && insert_count
                    && insert_count <= 200
                    && exp.test(insert_count))
                    && !prefixDuplicateFlag //false = プレフィックスの重複なし
                    && ((password || passwordRe) ? this.passwordValidationHandle(param, this.state) : true);

                if (floor_flg) {
                    nowState = (nowState && floor_id);
                }

                break;

            case "update":
                nowState = (
                    company_id &&
                    department_id &&
                    inbound_type);

                if (passwordResetFlag) {
                    nowState = (
                        nowState &&
                        this.passwordValidationHandle(param, this.state)
                    );
                }
                break;

            case "delete":
                nowState = (
                    company_id &&
                    department_id &&
                    inbound_type);
                break;
            case "sequence_number":
                if (prefixDuplicateFlag) {
                    nowState = 'error';
                }
                break;
            case "multi_delete":
                nowState = (
                    company_id &&
                    department_id &&
                    extension_ids.length > 0
                );

                if (floor_flg) {
                    nowState = (nowState && floor_id);
                }
                break;
            default:
                break;
        }
        return nowState;
    }

    // dynamoDBからuserステータス確認
    omniphoneLoginStatusSearch = async (extension_number) => {
        try {
            const result = await this.ascAxios('post', `${this.reactContainerPath}/omniphoneLoginStatusSearch`, { 
                extension_number 
            });

            let result_data = {};

            if (result.data !== "" && result.data !== null) {
                result_data = result.data;
            }

            this.setState({
                omniphone_login_data: result_data
            });

            return result_data;
        } catch (err) {
            alert(this.getErrorString(err.response.data));
        }   
    }

    // dynamoDBのuserステータス削除
    omniphoneLoginStatusDelete = (omniphone_login_data) => {
        if (window.confirm(this.props.langText.Message.Omniphone_Login_Status_delete)) {    
            this.ascAxios('post', `${this.reactContainerPath}/omniphoneLoginStatusDelete`, { 
                omniphone_login_data 
            })
            .then(res => {
                alert(this.props.langText.Message.Omniphone_Login_Status_delete_result);
                this.setState({
                    omniphone_login_data: {},
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
        }
   
    }

    onTextChange_Limit = (event, stateName, charLimit) => {
        const value = event && event.target && event.target.value ? event.target.value : "";
        switch (stateName) {
            case "sequence_number":
                if (value.length <= charLimit) {
                    //半角数値のみ入力を許可する
                    this.propSetState({ [stateName]: value.replace(/[^0-9]+/i, "") });
                }
                break;
            default:
                super.onTextChange_Limit(event, stateName, charLimit);
                break;
        }
    }

    /**
     * プレフィックスの重複チェック
     */
    checkPrefixDuplicate = () => {
        if (this.state.sequence_number === "") {
            //未入力の場合はチェックしない
            this.propSetState({prefixDuplicateFlag: false});
            return false;
        }

        this.ascAxios('post', `${this.reactContainerPath}/checkPrefixDuplicate`, {
            cm13_id: this.state.floor_id || this.state.department_id,
            sequence_number: ("000" + this.state.sequence_number).slice(-3)
        })
        .then(res => {
            let prefixDuplicateFlag = false;
            if (res.data.length > 0 && res.data[0].count > 0) {
                prefixDuplicateFlag = true;
            }
            this.propSetState({prefixDuplicateFlag});
        })
        .catch(err => {
            alert(this.getErrorString(err.response.data))
        })
    }

    /**
     * 一括削除時内線番号項目設定
     */
    getExtensionIdSelect = async ({ department_id, floor_id, type }) => {
        try {
            const result = await this.ascAxios('post', `${this.reactContainerPath}/getExtensionIdSelect`, {
                cm13_id: floor_id || department_id,
                type
            });

            let extension_number_setup = [];
            let extensionNumberSelect = result.data.map(row => {
                let labelData = <div>
                                    {row.label}
                                    <Favi
                                        className="pull-right fa-fw fa-icon fa-icon-static"
                                        icon={row.type}
                                    />
                                </div>;

                this.state.extension_ids.map(extension_data => {
                    if (row.value === Number(extension_data)) {
                        extension_number_setup.push(extension_data);
                    }
                });
                return {
                    search: row.label,
                    label: labelData,
                    value: row.value,
                    extension_number: row.extension_number,
                    type: row.type
                }
            });

            this.setState({extensionIdSelect: extensionNumberSelect, extension_ids: extension_number_setup});
        } catch (err) {
            alert(this.getErrorString(err.response.data))
        }
    }

    render() {
        return (
            <div className="ExtensionNumber">
                <Row>
                    <Col xs={12} md={12}>
                        {this.props.currentPermission.create && (
                            <>
                                <Button
                                    id="extension-insert"
                                    className="table-button"
                                    bsStyle="default"
                                    bsSize="sm"
                                    onClick={event => this.modalShow(event, "insert")}
                                >
                                    {this.props.langText.Body.InsertTitle}
                                </Button>
                                <Button
                                    id="extension-insertBatch"
                                    className="table-button"
                                    bsStyle="default"
                                    bsSize="sm"
                                    onClick={event => this.modalShow(event, "insertBatch")}
                                >
                                    {this.props.langText.Body.InsertBatchTitle}
                                </Button>
                            </>
                        )}
                        {
                            this.props.currentPermission.delete && (
                                <Button
                                    id="extension-multi_delete"
                                    className="table-button"
                                    bsStyle="default"
                                    bsSize="sm"
                                    onClick={event => this.modalShow(event, "multi_delete")}
                                >
                                    {this.props.langText.Body.BatchDelete}
                                </Button>
                            )
                        }
                    </Col>
                    <Col xs={12} md={12}>
                        <CommonTable
                            className = "extension-number-table"
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{ id: "extension_number", desc: true }]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>

                <ExtensionModal
                    state={this.state}
                    propSetState={this.propSetState}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                    onClick={this.onClickHandle}
                    onTextChange_Limit={this.onTextChange_Limit}
                    onIntChange={this.onIntChange}
                    onKeyDownIntCheck={this.onKeyDownIntCheck}
                    onSelectChange={this.onSelectChange}
                    onMultiSelectChange={this.onMultiSelectChange}
                    onCheckBoxChange={this.onCheckBoxChange}
                    onRadioChange={this.onRadioChange}
                    validationHandle={this.validationHandle}
                    getinboundGroupName={this.getinboundGroupName}
                    getScopeGreaterEqual={this.getScopeGreaterEqual}
                    omniphoneLoginStatusDelete = {this.omniphoneLoginStatusDelete}
                    checkPrefixDuplicate = {this.checkPrefixDuplicate}
                    passwordValidationHandle={this.passwordValidationHandle}
                    omniphoneLoginStatusSearch={this.omniphoneLoginStatusSearch}
                    reactTableRefresh={this.reactTableRefresh}
                    reactContainerPath={this.reactContainerPath}
                />
            </div>
        );
    }
}
