import React from "react"
import {Panel} from "react-bootstrap"
import AscComponent from '../../components/AscComponent'
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css"

export default class UserChangeSelfPasswordModal extends AscComponent {

    getUserChangeSelfPasswordItem = () => {
        let {
            state,
            mailAddress,
            firstName,
            lastName,
            langText,
            charaLimit,
            passwordValidationHandle
        } = this.props;

        let itemArr = [];

        itemArr.push(
            <InlineForm.static
                key = "mailAddress"
                controlId = "mailAddress"
                label = {langText.Body.EmailAddress}>
                {mailAddress}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm.static
                key = "userName"
                controlId = "userName"
                label = {langText.Body.UserName}
            >
                {firstName + lastName}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm
                key = "oldPassword"
                controlId = "oldPassword"
                label = {langText.Body.PasswordOld}
                type = "password"
                value = {state.oldPassword}
                onChange = {e => this.props.onTextChange_Limit(e, "oldPassword",charaLimit.User_oldPassword)}
                validationState = {passwordValidationHandle("oldPassword", state)}
                placeholder = {langText.Message.Password_Validation}
                autoComplete="new-password"/>
        );

        itemArr.push(
            <InlineForm
                key = "newPassword"
                controlId = "newPassword"
                label = {langText.Body.PasswordNew}
                type = "password"
                value = {state.newPassword}
                onChange = {e => this.props.onTextChange_Limit(e, "newPassword",charaLimit.User_newPassword)}
                validationState = {passwordValidationHandle("newPassword", state)}
                placeholder = {langText.Message.Password_Validation}
                autoComplete="new-password"/>
        );

        itemArr.push(
            <InlineForm
                key = "newPasswordRe"
                controlId = "newPasswordRe"
                label = {langText.Body.PasswordNewRe}
                type = "password"
                value = {state.newPasswordRe}
                onChange = {e => this.props.onTextChange_Limit(e, "newPasswordRe",charaLimit.User_newPasswordRe)}
                validationState = {passwordValidationHandle("newPasswordRe", state)}
                placeholder = {langText.Message.Password_Validation}
                autoComplete="new-password"/>
        );
        if((state.newPassword !== state.newPasswordRe) && (state.newPassword.length > 0 && state.newPasswordRe.length > 0)){
            itemArr.push(
                <p key = "passwordMismatch" className="text-danger">
                    {langText.Body.PasswordMismatch}
                </p>
            );
        }

        return itemArr;
    }

    validationHandle = param => {
        let validationFlag = true;

        let {
            state,
            passwordValidationHandle
        } = this.props;

        switch (param) {
            case 'updateSelfPassword':
                validationFlag = passwordValidationHandle(param, state);
                break;

            default:
                validationFlag = true;
                break;
        }
        return validationFlag;
    }

    onClickHandle = modalType => async event => {
        let {
            state,
            userId,
            mailAddress,
            firstName,
            lastName
        } = this.props;

        let {
            oldPassword,
            newPassword
        } = state;

        try {
            this.blockUI();
            await this.ascAxios('post', 'Common/updateSelfPassword', {
                    userId,
                    mailAddress,
                    firstName,
                    lastName,
                    oldPassword,
                    newPassword
                })
            alert(this.props.langText.Message.User_ChangeSelfPasswordSuccess);
            this.props.propSetState({ show: false });
        } catch(err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.validationHandle}
                onClick = {this.onClickHandle}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ChangePasswordTitle}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getUserChangeSelfPasswordItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}